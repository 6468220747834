import { BackTop, Button } from 'antd';
import { ChevronUp } from 'react-feather';

const BackToTop = () => {
  return (
    <BackTop>
      <Button type="primary" shape="circle" size="large" style={{ width: 40, height: 40 }}>
        <ChevronUp size={20} style={{ display: 'flex' }} />
      </Button>
    </BackTop>
  );
};

export default BackToTop;
