import React, { useState, useEffect, useCallback, forwardRef } from 'react';
import { Link, useNavigate, useLocation, createSearchParams } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';

import { Row, Col, Radio, Input, Select, Tag, Spin, Modal, Badge, Button, Popover, AutoComplete, Drawer, Space, Typography, Affix, Cascader, Divider, List, Avatar, Skeleton, Tooltip, Dropdown, Menu, message } from 'antd';
import {
  Menu as MenuIcon,
  Grid as GridIcon,
  Camera as CameraIcon,
  PlusCircle as PlusCircleIcon,
  Search as SearchIcon,
  Mic as MicIcon,
  Heart as HeartIcon,
  AlignLeft as AlignLeftIcon,
  ShoppingBag as ShoppingBagIcon,
  User as UserIcon,
  ChevronLeft as ChevronLeftIcon,
  ChevronDown as ChevronDownIcon,
  Phone as PhoneIcon,
  Hash as HashIcon,
  X as XIcon,
  Briefcase as BriefcaseIcon,
  ArrowRight,
} from 'react-feather';

import { Container, MenuBar, LanguageDropdown, LoginModal, UserMenu, Category, ProfileCardCircle } from '../../components';
import { CDN_URL, top_menu, bar_menu, profile_menu, store_profile_url, sell_currency } from '../../helpers';
import { colors, spaces, fonts, sizes, media } from '../../style';
import { isMobile } from '../../utils';
import { Api, Locale } from '../../services';

import { setLoginModal } from '../../redux/reducers/userSlice';
import { setDrawer, setCatalog, setSearch } from '../../redux/reducers/appSlice';

import logo from '../../assets/images/logo.svg';

const { Search } = Input;
const { Text, Title, Paragraph } = Typography;

const Header = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();

  const { user, basket, wishlist } = useSelector((state) => state);
  const { utils } = useSelector((state) => state.app);

  const [loading, setLoading] = useState(false);
  const [loginPopoverVisible, setLoginPopoverVisible] = useState(false);
  const [searchDropdown, setSearchDropdown] = useState(false);
  const [searchValue, setSearchValue] = useState();
  const [searchTargetValue, setSearchTargetValue] = useState('products');
  const [productSearchData, setProductSearchData] = useState([]);
  const [userSearchData, setUserSearchData] = useState([]);

  const [goBack, setGoBack] = useState(false);

  const onSearch = (value, type = '/products') => {
    setSearchDropdown(false);

    setTimeout(() => {
      dispatch(setSearch({ visible: false }));
    }, 300);

    navigate(
      `${type}?${createSearchParams({
        title: value != undefined ? value : '',
      })}`,
      { replace: true },
    );
  };

  const liveSearch = (value) => {
    setLoading(true);
    Api.build()
      .getSearch({ title: value })
      .then((response) => {
        let response_data_products = [],
          response_data_users = [];

        response_data_users = response.data?.users?.map((item) => {
          return {
            value: item.title,
            label: (
              <Link
                to={`/profiles/view/${item.uuid}`}
                onClick={() => {
                  setSearchDropdown(false);
                  setTimeout(() => {
                    dispatch(setSearch({ visible: false }));
                  }, 300);
                }}
              >
                <Space>
                  <img
                    style={{
                      objectFit: 'cover',
                      borderRadius: '50rem',
                      height: 30,
                      aspectRatio: '1 / 1',
                    }}
                    src={CDN_URL + item.img}
                  />
                  <Text strong>{item.trans?.title}</Text>
                </Space>
              </Link>
            ),
          };
        });

        response_data_products = response.data?.products?.map((item) => {
          return {
            value: item.title,
            label: (
              <Link
                to={`/products/details/${item.id}`}
                onClick={() => {
                  setSearchDropdown(false);
                  setTimeout(() => {
                    dispatch(setSearch({ visible: false }));
                  }, 300);
                }}
              >
                <Space>
                  <img
                    style={{
                      objectFit: 'cover',
                      borderRadius: '50rem',
                      height: 30,
                      aspectRatio: '1 / 1',
                    }}
                    src={CDN_URL + item.img}
                  />
                  <Text strong>{item.trans?.title}</Text>
                </Space>
              </Link>
            ),
          };
        });

        setUserSearchData(response_data_users);
        setProductSearchData(response_data_products);
      })
      .finally(() => {
        setTimeout(() => {
          setLoading(false);
        }, 800);
      })
      .catch((err) => console.log(err));
  };

  const createProductAndRedirect = useCallback(() => {
    Api.build()
      .createUserProduct()
      .then((response) => {
        navigate(`/user/products/edit/${response.data?.product?.id}`, {
          replace: true,
        });
      })
      .catch((err) => console.log(err));
  });

  useEffect(() => {
    setGoBack(location.pathname != '/');
  }, [location]);

  return (
    <>
      {!isMobile() ? (
        <>
          <Affix offsetTop={0} style={{ position: 'relative', zIndex: 1001 }}>
            <Container style={{ backgroundColor: colors.white }}>
              <Row
                align={'middle'}
                gutter={[spaces.xl, spaces.xl]}
                style={{
                  marginBottom: spaces.md,
                  marginTop: spaces.md,
                }}
              >
                <Col xl={4} xxl={3}>
                  <Badge
                    count="BETA"
                    status="success"
                    style={{
                      fontSize: 10,
                      height: 12,
                      lineHeight: '12px',
                      opacity: 0.8,
                      padding: '0 5px',
                      top: -4,
                      right: 15,
                    }}
                  >
                    <Link to={`/`}>
                      <img src={logo} height="36" />
                    </Link>
                  </Badge>
                </Col>
                <Col xl={14} xxl={15}>
                  <Row align={'middle'} gutter={[spaces.sm, spaces.sm]}>
                    <Col xl={7}>
                      <Button
                        onClick={() =>
                          dispatch(
                            setCatalog({
                              visible: !utils?.catalog?.visible,
                            }),
                          )
                        }
                        className="uppercase system-font"
                        size="large"
                        shape="round"
                        style={{
                          fontSize: 12
                        }}
                      >
                        {!utils?.catalog?.visible ? <MenuIcon size={16} /> : <XIcon size={16} />}
                        <Text size>
                          {Locale['Catalog']}
                        </Text>
                      </Button>
                    </Col>
                    <Col xl={17}>
                      <AutoComplete
                        className="header-auto-complete-search"
                        backfill={true}
                        value={searchValue}
                        open={searchDropdown}
                        onSelect={(value) => setSearchDropdown(false)}
                        onFocus={() => setSearchDropdown(true)}
                        onBlur={() => setSearchDropdown(false)}
                        onSearch={(value) => {
                          liveSearch(value);
                          setSearchValue(value);
                        }}
                        options={[
                          {
                            label: (
                              <>
                                <Space
                                  style={{
                                    justifyContent: 'space-between',
                                  }}
                                >
                                  <Title
                                    className="system-font"
                                    level={5}
                                    style={{
                                      marginBottom: 0,
                                    }}
                                  >
                                    {Locale['shops']}
                                  </Title>
                                  <Button
                                    size={'small'}
                                    // type={'link'}
                                    shape="circle"
                                    style={{ width: 34 }}
                                    onClick={() => {
                                      onSearch(searchValue, '/profiles');
                                    }}
                                  >
                                    <ArrowRight size={16} />
                                    {/* {Locale['in detail']} */}
                                  </Button>
                                </Space>
                              </>
                            ),
                            options: userSearchData,
                          },
                          {
                            label: (
                              <>
                                <Space
                                  style={{
                                    display: 'flex',
                                    justifyContent: 'space-between',
                                  }}
                                >
                                  <Title
                                    className="system-font"
                                    level={5}
                                    style={{
                                      marginBottom: 0,
                                    }}
                                  >
                                    {Locale['statements']}
                                  </Title>
                                  <Button
                                    size={'small'}
                                    // type={'link'}
                                    shape="circle"
                                    style={{ width: 34 }}
                                    onClick={() => {
                                      onSearch(searchValue, '/products');
                                    }}
                                  >
                                    {/* {Locale['in detail']} */}
                                    <ArrowRight size={16} />
                                  </Button>
                                </Space>
                              </>
                            ),
                            options: productSearchData,
                          },
                        ]}
                      >
                        <Input
                          className="header-search"
                          style={{
                            borderRadius: 50,
                          }}
                          loading={loading}
                          placeholder={Locale['Enter a search word']}
                          enterButton={<SearchIcon size={22} />}
                          size="large"
                          // prefix={
                          //   <>
                          //     <Radio.Group defaultValue="products" buttonStyle="outline" size="small" onChange={({ target }) => setSearchTargetValue(target.value)}>
                          //       <Radio.Button style={{ borderRadius: 50, fontSize: 12 }} value="products">
                          //         {Locale['statements']}
                          //       </Radio.Button>
                          //       <Radio.Button style={{ borderRadius: 50, fontSize: 12 }} value="profiles">
                          //         {Locale['shops']}
                          //       </Radio.Button>
                          //     </Radio.Group>
                          //   </>
                          // }
                          suffix={
                            <Space align="center" split={<Divider type="vertical" />} direction="horizontal">
                              <Tooltip title={Locale['Temporarily unavailable']}>
                                <MicIcon size={16} style={{ display: 'flex' }} />
                              </Tooltip>
                              <Spin
                                spinning={loading}
                                style={{
                                  background: 'white',
                                }}
                              >
                                <SearchIcon
                                  size={18}
                                  style={{ display: 'flex' }}
                                  onClick={() => {
                                    onSearch(searchValue, searchTargetValue);
                                    dispatch(setSearch({ visible: false }));
                                    setTimeout(() => {
                                      setSearchDropdown(false);
                                    }, 200);
                                  }}
                                />
                              </Spin>
                            </Space>
                          }
                          onSearch={(value) => {
                            onSearch(value, 'products');
                            dispatch(setSearch({ visible: false }));
                          }}
                        />
                      </AutoComplete>
                    </Col>
                  </Row>
                </Col>
                <Col xl={6} xxl={6}>
                  <Row gutter={[spaces.sm, 0]} align={'middle'} justify="end">
                    <Col>
                      <Link to={'/user/wishlist'}>
                        <Tooltip title={Locale['wishes']}>
                          <Button
                            type="link"
                            shape="circle"
                            // size={'large'}
                            style={{
                              flexDirection: 'column',
                            }}
                          >
                            <Badge count={wishlist?.wishlist_items?.length} size={'small'}>
                              <HeartIcon size={22} />
                            </Badge>
                            {/* <Paragraph
                            style={{
                              fontSize: fonts.xxs,
                            }}
                            strong
                          >
                            {Locale['wishes']}
                          </Paragraph> */}
                          </Button>
                        </Tooltip>
                      </Link>
                    </Col>
                    <Col>
                      <Link to={'/basket'}>
                        <Tooltip title={Locale['basket']}>
                          <Button
                            type="link"
                            shape="circle"
                          // size={'large'}
                          // style={{
                          //   flexDirection: 'column',
                          // }}
                          >
                            <Badge count={basket?.data?.reduce((acc, item) => acc + item.qty, 0)} size={'small'}>
                              <ShoppingBagIcon size={22} />
                            </Badge>
                          </Button>
                        </Tooltip>
                      </Link>
                    </Col>
                    <Col>
                      {user?.token ? (
                        <>
                          <Popover
                            placement={'bottomRight'}
                            title={null}
                            content={
                              <>
                                <Space size={spaces.md} direction={'vertical'}>
                                  <Button onClick={() => navigate(store_profile_url)}>
                                    {Locale['Happy Coins']}{' '}
                                    <Text
                                      style={{
                                        color: colors.secondary,
                                      }}
                                      strong
                                    >
                                      {user?.info?.store_coins?.coins} {sell_currency('coin')}
                                    </Text>
                                  </Button>
                                  <Divider
                                    style={{
                                      margin: 0,
                                    }}
                                  />
                                  <Space size={spaces.xs} direction={'vertical'}>
                                    <Link to={'/user'}>{Locale['my profile']}</Link>
                                    <Link to={'/user/orders'}> {Locale['Online orders']} </Link>
                                    <Link to={'/user/products'}> {Locale['statements']}</Link>
                                  </Space>
                                </Space>
                              </>
                            }
                            trigger={'hover'}
                          >
                            <Link to={'/user'}>
                              <Button
                                // type="link"
                                // shape="circle"
                                size={'large'}
                                // style={{
                                //   flexDirection: 'column',
                                // }}
                                shape="round"
                              >
                                <Badge
                                  count={user?.info?.store_coins?.coins}
                                  size={'small'}
                                  style={{
                                    backgroundColor: colors.secondary,
                                    padding: 0,
                                  }}
                                >
                                  {user?.loading ? (
                                    <Skeleton.Avatar active={true} size={spaces.lg} shape={'circle'} />
                                  ) : (
                                    <Avatar
                                      // size={spaces.lg}
                                      size={28}
                                    // src={CDN_URL + user?.info?.img}
                                    >
                                      {user?.info?.trans?.name?.charAt(0) || ''}
                                    </Avatar>
                                  )}
                                </Badge>
                                <Paragraph
                                  style={{
                                    fontSize: fonts.xxs,
                                  }}
                                  strong={true}
                                >
                                  {user?.loading ? '...' : user?.info?.trans?.name}
                                </Paragraph>
                              </Button>
                            </Link>
                          </Popover>
                        </>
                      ) : (
                        <>
                          <Popover
                            placement="bottomRight"
                            content={
                              <>
                                <Space
                                  direction="vertical"
                                  size={spaces.md}
                                  style={{
                                    width: 250,
                                  }}
                                >
                                  <Text
                                    style={{
                                      fontWeight: 500,
                                    }}
                                  >
                                    {Locale['Track your orders and enjoy personal discounts and points.']} <br />
                                    {Locale['After logging in, you will be able to create a legal entity account.']}
                                  </Text>
                                  <Space
                                    direction="vertical"
                                    size={spaces.sm}
                                    style={{
                                      display: 'flex',
                                    }}
                                  >
                                    <Button
                                      type="primary"
                                      size="large"
                                      block={true}
                                      onClick={() => {
                                        dispatch(
                                          setLoginModal({
                                            visible: true,
                                          }),
                                        );
                                        setLoginPopoverVisible(false);
                                      }}
                                    >
                                      {Locale['Authorization']}
                                    </Button>
                                    <Button
                                      type="link"
                                      block={true}
                                      onClick={() => {
                                        dispatch(
                                          setLoginModal({
                                            visible: true,
                                          }),
                                        );
                                        setLoginPopoverVisible(false);
                                      }}
                                    >
                                      {Locale['Registration']}
                                    </Button>
                                  </Space>
                                </Space>
                              </>
                            }
                            trigger="hover"
                            open={loginPopoverVisible}
                            onOpenChange={() => setLoginPopoverVisible(!loginPopoverVisible)}
                          >
                            <Button
                              type="default"
                              shape="round"
                              size={'large'}
                              onClick={() =>
                                dispatch(
                                  setLoginModal({
                                    visible: true,
                                  }),
                                )
                              }
                            >
                              <Badge dot={false}>
                                <UserIcon size={18} />
                              </Badge>
                              <Paragraph
                                className="system-font"
                                style={{
                                  fontSize: fonts.xs,
                                }}
                                strong={true}
                              >
                                {Locale['Open']}
                              </Paragraph>
                            </Button>
                          </Popover>
                        </>
                      )}
                    </Col>
                    <Col>
                      <Tooltip title={Locale['Online Shop']}>
                        <Button
                          onClick={() => {
                            if (user?.token) {
                              navigate('/user?modal=OpenShop');
                            } else {
                              dispatch(
                                setLoginModal({
                                  visible: true,
                                }),
                              );
                            }
                          }}
                          type="link"
                          shape="round"
                          size={'large'}
                          style={{
                            background: '#ff6d034d',
                            borderColor: colors.secondary,
                            width: 45,
                          }}
                        >
                          <Badge dot={false} size={'small'}>
                            <BriefcaseIcon size={18} color={colors.secondary} />
                          </Badge>
                          {/* <Paragraph
                            className="system-font"
                            style={{
                              fontSize: fonts.xs,
                            }}
                            strong={true}
                          >
                            {Locale['SignUp']}
                          </Paragraph> */}
                        </Button>
                      </Tooltip>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </Container>
          </Affix>
        </>
      ) : (
        <>
          <Affix offsetTop={0}>
            <Container xs={22} spaces={spaces.md} style={{ backgroundColor: colors.white }}>
              <Space
                style={{
                  display: 'flex',
                  justifyContent: 'space-between',
                }}
              >
                {goBack ? (
                  <Button type="link" style={{ padding: 0 }} onClick={() => navigate(-1)}>
                    <ChevronLeftIcon size={18} />
                    {Locale['back']}
                  </Button>
                ) : (
                  <Link to={`/`}>
                    <img src={logo} height="30" />
                  </Link>
                )}
                <Space size={spaces.sm}>
                  {/* <LanguageDropdown /> */}

                  {user?.token &&
                    (user?.info.type == 'Company' ? (
                      <Button onClick={() => createProductAndRedirect()} type={'dashed'} size={'small'} shape={'round'}>
                        <CameraIcon size={15} />
                        {Locale['Add']}
                      </Button>
                    ) : (
                      <Button onClick={() => navigate(`/profiles/view/${user?.info?.uuid}?modal=OpenShop`)} type={'dashed'} size={'small'} shape={'default'}>
                        <BriefcaseIcon size={15} />
                        {Locale['Open a shop']}
                      </Button>
                    ))}

                  <Button onClick={() => dispatch(setCatalog({ visible: true }))} type={'default'} size={'small'} shape={'default'}>
                    <GridIcon size={16} />
                  </Button>
                </Space>
              </Space>
            </Container>
          </Affix>
          <Drawer title={Locale['menu']} placement="right" extra={<LanguageDropdown />} contentWrapperStyle={{ width: '70%' }} onClose={() => dispatch(setDrawer({ visible: false }))} open={utils?.drawer?.visible}>
            {user?.token ? (
              <div onClick={() => dispatch(setDrawer({ visible: false }))}>
                <UserMenu />
              </div>
            ) : (
              <Button
                type="primary"
                size="large"
                block={true}
                onClick={() => {
                  dispatch(setLoginModal({ visible: true }));
                }}
              >
                {Locale['Authorization']}
              </Button>
            )}
          </Drawer>
          <MenuBar />
        </>
      )}
      <LoginModal />
      <Modal open={utils?.search?.modal?.visible} onCancel={() => dispatch(setSearch({ visible: false }))} footer={null} closeIcon={<></>} bodyStyle={{ padding: spaces.md }} width={350}>
        <AutoComplete
          className="header-auto-complete-search"
          backfill={true}
          value={searchValue}
          open={searchDropdown}
          onSelect={(value) => setSearchDropdown(false)}
          onFocus={() => setSearchDropdown(true)}
          onBlur={() => setSearchDropdown(false)}
          onSearch={(value) => {
            liveSearch(value);
            setSearchValue(value);
          }}
          options={[
            {
              label: (
                <>
                  <Space
                    style={{
                      justifyContent: 'space-between',
                    }}
                  >
                    <Title
                      level={5}
                      style={{
                        marginBottom: 0,
                      }}
                    >
                      {Locale['shops']}
                    </Title>
                    <Button
                      size={'small'}
                      type={'link'}
                      style={{ color: colors.primary }}
                      onClick={() => {
                        onSearch(searchValue, '/profiles');
                      }}
                    >
                      {Locale['in detail']}
                    </Button>
                  </Space>
                </>
              ),
              options: userSearchData,
            },
            {
              label: (
                <>
                  <Space
                    style={{
                      display: 'flex',
                      justifyContent: 'space-between',
                    }}
                  >
                    <Title
                      level={5}
                      style={{
                        marginBottom: 0,
                      }}
                    >
                      {Locale['statements']}
                    </Title>
                    <Button
                      size={'small'}
                      type={'link'}
                      style={{ color: colors.primary }}
                      onClick={() => {
                        onSearch(searchValue, '/products');
                      }}
                    >
                      {Locale['in detail']}
                    </Button>
                  </Space>
                </>
              ),
              options: productSearchData,
            },
          ]}
        >
          <Search
            className="header-search"
            style={{
              fontWidget: 'bold',
            }}
            loading={loading}
            placeholder={Locale['Enter a search word']}
            enterButton={<SearchIcon size={22} />}
            size="large"
            suffix={
              <Tooltip title={Locale['Temporarily unavailable']}>
                <MicIcon size={16} />
              </Tooltip>
            }
            onSearch={(value) => {
              onSearch(value, 'products');
            }}
          />
        </AutoComplete>
      </Modal>
    </>
  );
};

export default Header;
