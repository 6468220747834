import React, { useCallback, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';

import { Api } from '#app/services';
import { setToken, setInfo } from '../../redux/reducers/userSlice';
import { set as setTotalWishlist } from '../../redux/reducers/wishlistSlice';

const api = new Api();

const useUser = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [userData, setUserData] = useState([]);
  const [userLoading, setUserLoading] = useState(true);

  const getUserData = () => {
    console.log(12);
    api
      .getUser()
      .then((response) => {
        // dispatch(setInfo({ info: response.data?.user }));
        dispatch(setTotalWishlist({ total: response.data?.user?.wishlists_count }));

        setUserData(response.data);
        setUserLoading(false);
      })
      .catch((err) => console.log(err));
  };

  useEffect(() => {
    getUserData();
  }, []);

  const userLogout = () => {
    dispatch(setToken({ token: null }));
  };

  return {
    userData,
    userLoading,
    userLogout,
  };
};

export default useUser;
