import { useState, useEffect, useCallback } from 'react';
import { useParams, Link, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import { Row, Col, Rate, Avatar, Tooltip, Space, Typography, Button, Image, Input, Modal, Tag, Upload, Popover, Badge, message } from 'antd';
import {
  MessageSquare as MessageSquareIcon,
  Copy as CopyIcon,
  Phone as PhoneIcon,
  Rss as RssIcon,
  Check as CheckIcon,
  Upload as UploadIcon,
  Image as ImageIcon,
  CheckCircle as CheckCircleIcon,
  UserCheck as UserCheckIcon,
  UserX as UserXIcon,
  PlusCircle as PlusCircleIcon,
  Clock as ClockIcon,
  Briefcase as BriefcaseIcon,
} from 'react-feather';
import ImgCrop from 'antd-img-crop';

import { Api, Locale } from '../../services';
import { CDN_URL, API_URL } from '../../helpers';
import { colors, spaces, borders, sizes } from '../../style';
import { Container, UserCompanyEditModal } from '../../components';
import { isMobile, getBase64 } from '../../utils';

import profile_cover from '../../assets/images/profile-cover.jpg';

const { Title, Text, Paragraph } = Typography;
const { TextArea } = Input;

const api = new Api();

const ProfileHeader = ({ data }) => {
  const navigate = useNavigate();

  const { user } = useSelector((state) => state);

  const [imgUrl, setImgUrl] = useState();
  const [imgCoverUrl, setImgCoverUrl] = useState();
  const [imgCoverPopover, setImgCoverPopover] = useState(false);
  const [imgLoading, setImgLoading] = useState(false);

  const [messageModal, setMessageModal] = useState(false);
  const [isFollower, setIsFollower] = useState(false);

  useEffect(() => {
    setImgUrl(data.img);
    setImgCoverUrl(data.img_cover);

    const isProfileFolower = data?.followers?.some((element) => {
      if (element.uuid === user?.info?.uuid) {
        return true;
      }
      return false;
    });

    setIsFollower(isProfileFolower);
  }, [data]);

  const createProductAndRedirect = useCallback(() => {
    api
      .createUserProduct()
      .then((response) => {
        navigate(`/user/products/edit/${response.data?.product?.id}`, { replace: true });
      })
      .catch((err) => console.log(err));
  });

  const unFollow = () => {
    api
      .removeUserFollow(data.id)
      .then((response) => {
        message.success(response.data?.message);
        setIsFollower(false);
      })
      .catch((err) => console.log(err));
  };

  const follow = () => {
    api
      .addUserFollow(data.id)
      .then((response) => {
        message.success(response.data?.message);
        setIsFollower(true);
      })
      .catch((err) => console.log(err));
  };

  const isProfileOwner = () => {
    return data?.uuid == user?.info?.uuid;
  };

  const UserFollowerGroup = () => (
    <Avatar.Group
      maxCount={3}
      maxPopoverTrigger="click"
      size="large"
      maxStyle={{
        color: '#FFFFFF',
        backgroundColor: colors.primary,
        cursor: 'pointer',
      }}
    >
      {data?.followers?.map((item, key) => (
        <Tooltip key={key} title={item.trans?.public?.name} placement="top">
          <Avatar onClick={() => navigate(`/profiles/view/${item?.uuid}`, { replace: true })} src={CDN_URL + item?.img} style={{ cursor: 'pointer' }} />
        </Tooltip>
      ))}
    </Avatar.Group>
  );

  const ProfileButtons = () => (
    <>
      <Space size={isMobile() ? spaces.sm : spaces.md}>
        <Tooltip title={data?.trans?.company_working_hours}>
          <Button size={isMobile() ? 'small' : 'large'}>
            <ClockIcon size={16} />
            {!isMobile() && Locale['Working Hours']}
          </Button>
        </Tooltip>
        <a href={`tel:${data?.public?.phone}`}>
          <Button type={'secondary'} size={isMobile() ? 'small' : 'large'}>
            <PhoneIcon size={16} />
            {!isMobile() && <Text strong>{data?.public?.phone}</Text>}
          </Button>
        </a>
        {data?.short_url != undefined && (
          <Paragraph
            style={{ marginBottom: 0 }}
            copyable={{
              text: data?.short_url,
              icon: [
                <Button type="secondary" size={isMobile() ? 'small' : 'large'}>
                  <CopyIcon style={{ width: 18, height: 18 }} />
                </Button>,
                <Button type="secondary" size={isMobile() ? 'small' : 'large'}>
                  <CheckIcon color={colors.success} style={{ width: 18, height: 18 }} />
                </Button>,
              ],
              tooltips: [Locale['Copy url'], Locale['Url copied']],
            }}
          />
        )}
        {/* <Button type="secondary" size={'large'} onClick={() => setMessageModal(true)}>
          <MessageSquareIcon className='mr-2' style={{ width: 18, height: 18 }} />
          {Locale['message']}
        </Button>*/}
        {!isProfileOwner() ? (
          isFollower ? (
            <Button onClick={() => unFollow()} size={isMobile() ? 'small' : 'large'} danger>
              <UserXIcon size={16} />
              {!isMobile() && Locale['Unsubscribe']}
            </Button>
          ) : (
            <Button onClick={() => follow()} type={'primary'} size={isMobile() ? 'small' : 'large'}>
              <UserCheckIcon size={16} />
              {!isMobile() && Locale['Subscription']}
            </Button>
          )
        ) : (
          <>
            {/* <Button onClick={() => navigate('/user?modal=OpenShop')} type="primary" size={isMobile() ? 'small' : 'large'}>
              <BriefcaseIcon size={sizes.sm} />
              {!isMobile() && (data?.type == 'Company' ? Locale['my company'] : Locale['Open a shop'])}
            </Button> */}
            <UserCompanyEditModal button_size={isMobile() ? 'small' : 'large'} />

            <Button onClick={() => createProductAndRedirect()} type="success" size={isMobile() ? 'small' : 'large'}>
              <PlusCircleIcon size={20} />
              {!isMobile() && Locale['Add a statement']}
            </Button>
          </>
        )}
      </Space>
    </>
  );

  const CoverUploadContent = useCallback(
    () =>
      isProfileOwner() && (
        <ImgCrop
          modalTitle={
            <Text style={{ display: 'flex', alignItems: 'center' }}>
              <ImageIcon size={18} style={{ marginRight: spaces.xs }} />
              {Locale['Upload a cover photo']}
            </Text>
          }
          modalOk={Locale['save']}
          modalCancel={Locale['Cancellation']}
          shape={'rect'}
          aspect={4 / 1}
          grid
          rotate
        >
          <Upload
            name="img_cover"
            // listType="picture-card"
            // className="profile-avatar"
            showUploadList={false}
            headers={{
              Accept: 'application/json',
              Authorization: `Bearer ${user?.token}`,
              withCredentials: true,
            }}
            onChange={(info) => {
              getBase64(info.file.originFileObj, (url) => {
                setImgLoading(false);
                setImgCoverUrl(info?.fileList[0].response?.img_cover);
                setImgCoverPopover(false);
              });
            }}
            action={`${API_URL}/user/update/cover`}
          >
            <Button type={'default'}>
              <ImageIcon size={18} style={{ marginRight: spaces.xs }} />
              {!isMobile() && Locale['Upload a cover photo']}
            </Button>
          </Upload>
        </ImgCrop>
      ),
  );

  return (
    <>
      {isMobile() ? (
        <>
          <div
            style={{
              position: 'absolute',
              top: 5,
              right: 5,
            }}
          >
            {/* <CoverUploadContent /> */}
            {isProfileOwner() && (
              <ImgCrop
                modalTitle={
                  <Text style={{ display: 'flex', alignItems: 'center' }}>
                    <ImageIcon size={18} style={{ marginRight: spaces.xs }} />
                    {Locale['Upload a cover photo']}
                  </Text>
                }
                modalOk={Locale['save']}
                modalCancel={Locale['Cancellation']}
                shape={'rect'}
                aspect={3 / 1}
                grid
                rotate
              >
                <Upload
                  name="img_cover"
                  // listType="picture-card"
                  // className="profile-avatar"
                  showUploadList={false}
                  headers={{
                    Accept: 'application/json',
                    Authorization: `Bearer ${user?.token}`,
                    withCredentials: true,
                  }}
                  onChange={(info) => {
                    getBase64(info.file.originFileObj, (url) => {
                      setImgLoading(false);
                      setImgCoverUrl(info?.fileList[0].response?.img_cover);
                      setImgCoverPopover(false);
                    });
                  }}
                  action={`${API_URL}/user/update/cover`}
                >
                  <Button type={'default'}>
                    <ImageIcon size={18} style={{ marginRight: spaces.xs }} />
                    {!isMobile() && Locale['Upload a cover photo']}
                  </Button>
                </Upload>
              </ImgCrop>
            )}
          </div>
          <img width={'100%'} style={{ objectFit: 'cover', width: '100%', height: isMobile() ? 160 : 420 }} src={imgCoverUrl ? CDN_URL + imgCoverUrl : profile_cover} />
        </>
      ) : (
        <Row
          justify={'center'}
          style={{
            background: 'linear-gradient(0deg, rgba(255,255,255,0) 0%, #9d4af062 100%)',
          }}
        >
          <Col xs={24} sm={24} md={24} lg={23} xl={23} xxl={18}>
            <Popover
              content={
                isProfileOwner() && (
                  <ImgCrop
                    modalTitle={
                      <Text style={{ display: 'flex', alignItems: 'center' }}>
                        <ImageIcon size={18} style={{ marginRight: spaces.xs }} />
                        {Locale['Upload a cover photo']}
                      </Text>
                    }
                    modalOk={Locale['save']}
                    modalCancel={Locale['Cancellation']}
                    shape={'rect'}
                    aspect={4 / 2}
                    grid
                    rotate
                  >
                    <Upload
                      name="img_cover"
                      // listType="picture-card"
                      // className="profile-avatar"
                      showUploadList={false}
                      headers={{
                        Accept: 'application/json',
                        Authorization: `Bearer ${user?.token}`,
                        withCredentials: true,
                      }}
                      onChange={(info) => {
                        getBase64(info.file.originFileObj, (url) => {
                          setImgLoading(false);
                          setImgCoverUrl(info?.fileList[0].response?.img_cover);
                          setImgCoverPopover(false);
                        });
                      }}
                      action={`${API_URL}/user/update/cover`}
                    >
                      <Button type={'default'}>
                        <ImageIcon size={18} style={{ marginRight: spaces.xs }} />
                        {!isMobile() && Locale['Upload a cover photo']}
                      </Button>
                    </Upload>
                  </ImgCrop>
                )
              }
              trigger="hover"
              open={imgCoverPopover}
              onOpenChange={() => setImgCoverPopover(!imgCoverPopover)}
              placement="bottom"
            >
              <Image width={'100%'} style={{ objectFit: 'cover', width: '100%', height: isMobile() ? '100%' : 420 }} src={imgCoverUrl ? CDN_URL + imgCoverUrl : profile_cover} />
              {/* <img width={'100%'} style={{ objectFit: 'cover', width: '100%', height: isMobile() ? '100%' : 420 }} src={imgCoverUrl ? CDN_URL + imgCoverUrl : profile_cover} /> */}
              {/* <img src={imgCoverUrl ? CDN_URL + imgCoverUrl : profile_cover} style={{ objectFit: 'cover', width: '100%', height: isMobile() ? 150 : 300 }} /> */}
            </Popover>
          </Col>
        </Row>
      )}

      <Container xs={22}>
        <Space direction="vertical" size={spaces.xxl} style={{ width: '100%' }}>
          <Row gutter={[spaces.md, spaces.md]} justify={isMobile() ? 'center1' : 'space-between'} align={'middle'}>
            <Col style={isMobile() && { display: 'flex', justifyContent: 'left' }} flex={isMobile() ? 'auto' : 'none'}>
              <Space size={isMobile() ? spaces.lg : spaces.lg}>
                <Popover
                  content={
                    isProfileOwner() && (
                      <ImgCrop
                        modalTitle={
                          <Text style={{ display: 'flex', alignItems: 'center' }}>
                            <ImageIcon size={18} style={{ marginRight: spaces.xs }} />
                            {Locale['Upload a profile photo']}
                          </Text>
                        }
                        modalOk={Locale['save']}
                        modalCancel={Locale['Cancellation']}
                        grid
                        shape={'round'}
                        // aspect={1 / 1}
                        rotate
                      >
                        <Upload
                          name="img"
                          // listType="picture-card"
                          // className="profile-avatar"
                          showUploadList={false}
                          headers={{
                            Accept: 'application/json',
                            Authorization: `Bearer ${user?.token}`,
                          }}
                          onChange={(info) => {
                            getBase64(info.file.originFileObj, (url) => {
                              setImgLoading(false);
                              setImgUrl(info?.fileList[0].response?.img);
                            });
                          }}
                          action={`${API_URL}/user/update/avatar`}
                        >
                          <Button type={'default'}>
                            <ImageIcon size={18} style={{ marginRight: spaces.xs }} /> {Locale['Upload a profile photo']}
                          </Button>
                        </Upload>
                      </ImgCrop>
                    )
                  }
                  title=""
                  trigger="hover"
                  placement="bottom"
                >
                  <Badge
                    // title={Locale['Not verified']}
                    offset={[-10, 70]}
                    count={isMobile() ? <CheckCircleIcon style={{ background: data?.verified == 'True' ? colors.primary : colors.gray, padding: 4, borderRadius: 50 }} color={colors.white} size={26} /> : 0}
                  >
                    <Avatar size={isMobile() ? 120 : 200} style={{ marginTop: isMobile() ? -spaces.lg : -spaces.xxxl }} icon={<img src={CDN_URL + imgUrl} style={{ objectFit: 'cover', width: '100%', height: '100%' }} />} />
                  </Badge>
                </Popover>
                {isMobile() && (
                  <>
                    <Space size={spaces.xs} direction={'vertical'}>
                      <Space size={0} direction={'vertical'}>
                        <Title level={5} style={{ marginBottom: 0, marginTop: spaces.xs }}>
                          {data?.trans?.public?.name}
                        </Title>
                        <Text type={'secondary'} strong>
                          {data?.followers_count} {Locale['Subscriber']}
                        </Text>
                      </Space>
                      {isMobile() && <ProfileButtons />}
                    </Space>
                    {/* {isMobile() && <ProfileButtons />} */}
                  </>
                )}
              </Space>
            </Col>
            {!isMobile() && (
              <Col flex="auto">
                <Space direction={isMobile() ? 'vertical' : 'horizontal'} style={{ width: '100%', alignItems: isMobile() ? 'center' : 'start', justifyContent: 'space-between' }}>
                  <Space direction="vertical" size={spaces.xs} style={{ width: '100%', alignItems: isMobile() ? 'center' : 'start', textAlign: isMobile() ? 'center' : 'start' }}>
                    <Space direction={isMobile() ? 'vertical' : 'horizontal'} size={spaces.xs}>
                      <Title level={4} style={{ marginBottom: 0, marginRight: spaces.sm }}>
                        {data?.trans?.public?.name}
                      </Title>
                      {data?.verified == 'True' ? (
                        <Tag icon={<CheckIcon size={14} />} color={colors.primary} style={isMobile() && { width: '100%' }}>
                          {Locale['verified']}
                        </Tag>
                      ) : (
                        <Tag icon={<CheckIcon size={14} />} color="default">
                          {Locale['Not verified']}
                        </Tag>
                      )}
                      {/* {isMobile() && (
                      <>
                        <Divider style={{ margin: spaces.xs }} />
                        <Space size={spaces.md}>
                          <UserFollowerGroup />
                          <Space direction={'horizontal'}>
                            <Title level={4} style={{ marginBottom: 0 }} >{data?.followers_count}</Title>
                            <Text>{Locale['Follower']}</Text>
                          </Space>
                          <Space size={0} direction={'vertical'}>
                            <Title level={4} style={{ marginBottom: 0 }} >{data?.following_count}</Title>
                            <Text>{Locale['Following']}</Text>
                          </Space>
                        </Space>
                        <Divider style={{ margin: spaces.xs }} />
                      </>
                    )} */}
                    </Space>
                    {/* <Space>
                    <Text>{data?.rate} {Locale['- from 5']}</Text>
                    <Rate disabled value={data?.rate} defaultValue={5} />
                  </Space> */}
                    {!isMobile() && (
                      <Space size={spaces.sm} direction={'vertical'}>
                        <Text type={'secondary'} strong>
                          {data?.followers_count} {Locale['Subscriber']}
                        </Text>
                        <UserFollowerGroup />
                      </Space>
                    )}
                  </Space>
                  {!isMobile() && <ProfileButtons />}
                </Space>
              </Col>
            )}
          </Row>
        </Space>
      </Container>

      <Modal
        title={Locale['Write me a message']}
        open={messageModal}
        onOk={() => console.log('onOk')}
        onCancel={() => setMessageModal(false)}
        footer={[
          <Button key="submit" type="primary" onClick={() => setMessageModal(false)}>
            {Locale['send']}
          </Button>,
        ]}
      >
        <TextArea showCount maxLength={100} allowClear={true} />
      </Modal>
    </>
  );
};

export default ProfileHeader;
