import React, { useCallback, useState } from 'react';
import { Image, Modal } from 'antd';
import { Swiper, SwiperSlide } from 'swiper/react';
import { FreeMode, Navigation, Pagination, Thumbs } from 'swiper';

import { CDN_URL } from '../../helpers';
import { isMobile } from '../../utils';
import { colors, spaces, borders } from '../../style';
import { PlayIcon } from '../../assets';

const ThumbsGallery = ({ data, img, video }) => {
  const [preview, setPreview] = useState(false);
  const [thumbsSwiper, setThumbsSwiper] = useState(null);

  return (
    <>
      <Swiper
        style={{ height: isMobile() ? 250 : 400, marginBottom: spaces.xs, border: '1px solid #cfd4d9', backgroundColor: colors.white, borderRadius: borders.md }}
        className={'thumbs-gallery'}
        loop={true}
        spaceBetween={6}
        navigation={true}
        thumbs={{ swiper: thumbsSwiper }}
        modules={[FreeMode, Navigation, Thumbs]}
      >
        {video && (
          <SwiperSlide key={-2}>
            <iframe
              width="100%"
              height="100%"
              src={`https://www.youtube.com/embed/${video}`}
              title="YouTube video player"
              frameborder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowfullscreen
            ></iframe>
          </SwiperSlide>
        )}
        {img != undefined && (
          <SwiperSlide key={-1}>
            <img
              onClick={() => {
                setPreview(true);
              }}
              height={'100%'}
              width={'100%'}
              style={{ objectFit: 'contain', verticalAlign: 'middle' }}
              src={CDN_URL + img}
            />
            {/* <Image width={'100%'} height={'100%'} style={{ objectFit: 'contain', verticalAlign: 'middle' }} src={CDN_URL + img} /> */}
          </SwiperSlide>
        )}
        {data?.map((item, key) => (
          <SwiperSlide key={key}>
            <img
              onClick={() => {
                setPreview(true);
              }}
              height={'100%'}
              width={'100%'}
              style={{ objectFit: 'contain', verticalAlign: 'middle' }}
              src={CDN_URL + item.img}
            />
            {/* <Image width={'100%'} height={'100%'} style={{ objectFit: 'contain', verticalAlign: 'middle' }} src={CDN_URL + item.img} /> */}
          </SwiperSlide>
        ))}
      </Swiper>
      <div style={{ padding: spaces.md }}>
        <Swiper
          onSwiper={setThumbsSwiper}
          loop={false}
          // navigation={true}
          spaceBetween={spaces.sm}
          slidesPerView={6}
          freeMode={true}
          watchSlidesProgress={true}
          modules={[FreeMode, Navigation, Thumbs]}
        >
          {video && (
            <SwiperSlide key={-2}>
              <img height={50} width={'100%'} style={{ objectFit: 'contain', border: '1px solid #cfd4d9', backgroundColor: colors.white, borderRadius: borders.sm, cursor: 'pointer' }} src={PlayIcon} />
            </SwiperSlide>
          )}
          {img != undefined && (
            <SwiperSlide key={-1}>
              <img height={50} width={'100%'} style={{ objectFit: 'contain', border: '1px solid #cfd4d9', backgroundColor: colors.white, borderRadius: borders.sm, cursor: 'pointer' }} src={CDN_URL + img} />
            </SwiperSlide>
          )}
          {data?.map((item, key) => (
            <SwiperSlide key={key}>
              <img height={50} width={'100%'} style={{ objectFit: 'contain', border: '1px solid #cfd4d9', backgroundColor: colors.white, borderRadius: borders.sm, cursor: 'pointer' }} src={CDN_URL + item.img} />
            </SwiperSlide>
          ))}
        </Swiper>
      </div>
      {/* <SwiperSliderTumbs /> */}
      <Modal closable={!isMobile() && true} open={preview} title={null} onOk={() => {}} onCancel={() => setPreview(false)} footer={null} wrapClassName={'swiper-gallery-modal'} width={'100%'}>
        <Swiper style={{ height: isMobile() ? '100%' : '70vh' }} className={'thumbs-gallery'} loop={true} spaceBetween={6} pagination={{ enabled: isMobile() }} navigation={!isMobile() && true} modules={[FreeMode, Navigation, Pagination]}>
          {img != undefined && (
            <SwiperSlide key={-5}>
              <img height={'100%'} width={'100%'} style={{ objectFit: 'contain', verticalAlign: 'middle' }} src={CDN_URL + img} />
            </SwiperSlide>
          )}
          {data?.map((item, key) => (
            <SwiperSlide key={key * 10}>
              <img height={'100%'} width={'100%'} style={{ objectFit: 'contain', verticalAlign: 'middle' }} src={CDN_URL + item.img} />
            </SwiperSlide>
          ))}
        </Swiper>
      </Modal>
    </>
  );
};

export default ThumbsGallery;
