import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import { Row, Col, Space, Avatar, Typography, Divider, Form, Input, InputNumber, PageHeader, Card, Button, Upload, message } from 'antd';
import { Navigation as NavigationIcon, PhoneCall as PhoneCallIcon, Home as HomeIcon, Upload as UploadIcon } from 'react-feather';

import { Api, Locale } from '../../services';
import { API_URL, CDN_URL } from '../../helpers';
import { Container, UserMenu, UserHeader } from '../../components';
import { colors, spaces, borders, shadows } from '../../style';
import { isMobile } from '../../utils';

import { setInfo, setImg as setUserImg } from '../../redux/reducers/userSlice';

const { TextArea } = Input;

const UserEdit = () => {
  const api = new Api();

  const dispatch = useDispatch();

  const [form] = Form.useForm();
  const [formValues, setFormValues] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    api
      .getUser()
      .then((response) => {
        const user = response.data?.user;

        const user_values = {
          img: user.img,
          img_cover: user.img_cover,
          email: user.email,
          phone: user.phone,
          // contact_email: user.contact_email,
          // contact_phone: user.contact_phone,
          contact_website: user.contact_website,
          contact_facebook: user.contact_facebook,
          contact_instagram: user.contact_instagram,
          contact_twitter: user.contact_twitter,
          contact_other: user.contact_other,
          // map: user.map,
          zip: user.zip,
          name: user.trans?.name,
          surname: user.trans?.surname,
          description: user.trans?.description,
          country: user.trans?.country,
          city: user.trans?.city,
          region: user.trans?.region,
          address: user.trans?.address,
        };

        form.setFieldsValue(user_values);
        setFormValues(user_values);
        setLoading(false);
      })
      .catch((err) => console.log(err));
  }, []);

  const onFinish = (values) => {
    setLoading(true);

    api
      .updateUser(formValues)
      .then((response) => {
        const response_data = response.data;
        message.success(response_data.message);

        dispatch(
          setInfo({
            info: response_data.user,
          }),
        );
      })
      .catch((err) => {
        message.error(err?.response?.data?.message);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const onValuesChange = (_, values) => {
    setFormValues((prevState) => ({ ...prevState, ...values }));
  };

  const onFinishFailed = (errorInfo) => {
    console.log('Failed:', errorInfo);
  };

  return (
    <>
      <Container xs={22} spaces={spaces.xl} style={{ backgroundColor: colors.light }}>
        <Row gutter={[spaces.lg, spaces.lg]}>
          <Col xs={24} md={24} lg={7} xl={7} xxl={6}>
            <Space direction="vertical" size={spaces.lg} style={{ display: 'flex', backgroundColor: colors.white, boxShadow: shadows.sm, padding: spaces.lg, borderRadius: borders.sm }}>
              {isMobile() ? <UserHeader /> : <UserMenu />}
            </Space>
          </Col>
          <Col xs={24} md={24} lg={17} xl={17} xxl={18}>
            <Card bordered={false}>
              <Form layout={'vertical'} form={form} onFinish={onFinish} onFinishFailed={onFinishFailed} onValuesChange={onValuesChange}>
                <Row gutter={[spaces.md, 0]}>
                  <Col xs={24} lg={12}>
                    <Form.Item label={Locale['name']} name="name" rules={[{ required: true, message: Locale['Please provide a name!'] }]}>
                      <Input size={'large'} showCount maxLength={20} />
                    </Form.Item>
                  </Col>
                  <Col xs={24} lg={12}>
                    <Form.Item label={Locale['surname']} name="surname" rules={[{ required: true, message: Locale['Please enter your last name!'] }]}>
                      <Input size={'large'} showCount maxLength={20} />
                    </Form.Item>
                  </Col>
                  <Col xs={24} lg={12}>
                    <Form.Item label={Locale['Email']} type={'email'} name="email" rules={[{ required: false, message: Locale['Please enter your e-mail address!'] }]}>
                      <Input size={'large'} showCount maxLength={75} disabled />
                    </Form.Item>
                  </Col>
                  <Col xs={24} lg={12}>
                    <Form.Item label={Locale['Your phone number']} name="phone" rules={[{ required: false, message: Locale['Please enter your phone!'] }]}>
                      <Input size={'large'} showCount maxLength={20} disabled />
                    </Form.Item>
                  </Col>
                  {/* <Col xs={24} lg={12}>
                    <Form.Item label={Locale['E-mail for contact']} type={'email'} name="contact_email" rules={[{ required: false, message: Locale['Please enter your e-mail address!'] }]}>
                      <Input size={'large'} showCount maxLength={75} />
                    </Form.Item>
                  </Col>
                  <Col xs={24} lg={12}>
                    <Form.Item label={Locale['Phone for contact']} name="contact_phone">
                      <Input size={'large'} showCount maxLength={20} rules={[{ required: false, message: Locale['Please enter your phone!'] }]} />
                    </Form.Item>
                  </Col> */}
                  <Col xs={24} lg={24}>
                    <Form.Item label={Locale['address']} name="address">
                      <TextArea showCount maxLength={500} />
                    </Form.Item>
                  </Col>
                  <Col xs={24} lg={24}>
                    <Form.Item label={Locale['About Company']} name="description">
                      <TextArea showCount maxLength={500} style={{ height: 120 }} />
                    </Form.Item>
                  </Col>
                  <Col xs={24}>
                    <Button type="primary" size={'large'} htmlType="submit">
                      {Locale['save']}
                    </Button>
                  </Col>
                </Row>
              </Form>
            </Card>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default UserEdit;
