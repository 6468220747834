import React, { useEffect, useState } from 'react';
import { Button, Modal, Typography, Space } from 'antd';
import { Download as DownloadIcon } from 'react-feather';

import { spaces } from '../style';

import { Locale } from '../services';

const { Text } = Typography;

const PWA = () => {
  const [supportsPWA, setSupportsPWA] = useState(false);
  const [promptInstall, setPromptInstall] = useState(null);
  const [isModalVisible, setIsModalVisible] = useState(false);

  useEffect(() => {
    const handler = (e) => {
      e.preventDefault();
      setSupportsPWA(true);
      setPromptInstall(e);
    };

    window.addEventListener('beforeinstallprompt', handler);

    return () => window.removeEventListener('transitionend', handler);
  }, []);

  useEffect(() => {
    setTimeout(() => {
      setIsModalVisible(true);
    }, 3000);
  }, []);

  const install = (evt) => {
    evt.preventDefault();

    if (!promptInstall) {
      return;
    }
    promptInstall.prompt();
  };

  if (!supportsPWA) {
    return null;
  }

  return (
    <Modal title={Locale['Want to download the app?']} open={isModalVisible} onCancel={() => setIsModalVisible(false)} footer={null}>
      <Space size={spaces.md} direction={'vertical'} style={{ display: 'flex', alignItems: 'center' }}>
        <Text>{Locale['The application will allow you to simplify shopping!']}</Text>
        <Button onClick={install} type="primary">
          <DownloadIcon size={18} style={{ marginRight: spaces.sm }} />
          {Locale['Download the application']}
        </Button>
      </Space>
    </Modal>
  );
};

export default PWA;
