import { React, useEffect, useContext } from 'react';
import { BrowserRouter as Router, Routes, Route, Navigate, Outlet } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import { getAppData } from '../redux/reducers/appSlice';
import { setToken, setLoginModal } from '../redux/reducers/userSlice';
import { ScrollToTop, PWA, isMobile } from '../utils';
import { InitLocale } from '../services';
import { Header, Catalog, Footer, BackToTop } from '../components';

import {
  Home,
  Faq,
  Page,
  Contact,
  Products,
  ProductsPremium,
  ProductView,
  Profiles,
  ProfileView,
  Basket,
  User,
  UserEdit,
  UserProducts,
  UserProductEdit,
  UserOrders,
  UserSales,
  UserCoins,
  Wishlist,
  Checkout,
  CheckoutResult,
  NotFoundPage,
} from '../pages';

function App() {
  const dispatch = useDispatch();

  const { token } = useSelector((state) => state.user);

  useEffect(() => {
    InitLocale();
    dispatch(getAppData());
  }, [dispatch]);

  const PrivateWrapper = () => {
    if (!token) {
      dispatch(setToken({ token: null }));
      dispatch(setLoginModal({ visible: true }));
      return <Navigate to="/" />;
    }

    return <Outlet />;
  };

  return (
    <Router>
      {isMobile() && <PWA />}
      <ScrollToTop />
      <Header />

      <div style={{ position: 'relative' }}>
        <Routes>
          <Route exact path="/" element={<Home />} />
          <Route path="faq" element={<Faq />} />
          <Route path="page/view/:id" element={<Page />} />
          <Route path="contact" element={<Contact />} />
          <Route path="products" element={<Products />} />
          <Route path="products/premium" element={<ProductsPremium />} />
          <Route path="products/category/:id" element={<Products />} />
          <Route path="products/details/:id" element={<ProductView />} />
          <Route path="products/details/:id/:slug" element={<ProductView />} />
          <Route path="profiles" element={<Profiles />} />
          <Route path="profiles/view/:uuid" element={<ProfileView />} />
          <Route path="basket" element={<Basket />} />

          <Route element={<PrivateWrapper />}>
            <Route path="user" element={<User />} />
            <Route path="user-edit" element={<UserEdit />} />
            <Route path="user/products" element={<UserProducts />} />
            <Route path="user/products/edit/:id" element={<UserProductEdit />} />
            <Route path="user/orders" element={<UserOrders />} />
            <Route path="user/sales" element={<UserSales />} />
            <Route path="user/coins" element={<UserCoins />} />
            <Route path="user/wishlist" element={<Wishlist />} />
            <Route path="checkout/products" element={<Checkout type={'Product'} />} />
            <Route path="checkout/gifts" element={<Checkout type={'Gift'} />} />
            <Route path="checkout/result" element={<CheckoutResult />} />
          </Route>

          <Route path="*" element={<NotFoundPage />} />
        </Routes>
        <Catalog />
      </div>

      <Footer />
      {!isMobile() && <BackToTop />}
    </Router>
  );
}

export default App;
