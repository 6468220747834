import { EcommerceWebPage, DeliveryAddress, HappyGift, EcommerceCreditCard } from '../assets';

export const services_data = [
  {
    title: 'მოძებნე განცხადება',
    description: 'შეამოწმე პოპულარული ბრენდები, მაღაზიები და აღმოაჩინე სასურველი ნივთი',
    img: EcommerceWebPage,
  },
  {
    title: 'შეიძინე ონლაინ',
    description: 'იშოპინგე ონლაინ,  ნებისმიერ დროს ნებისმიერი ადგილიდან',
    img: EcommerceCreditCard,
  },
  {
    title: 'სწრაფი მიწოდება',
    description: 'თქვენი შენაძენის სწრაფად მოტანა ჩვენთვის პრიორიტეტულია',
    img: DeliveryAddress,
  },
  {
    title: 'უამრავი საჩუქარი',
    description: 'ნებისმიერ შენაძენზე დააგროვე ქოინები და გადაცვლით სასურველ ნივთში',
    img: HappyGift,
  },
];
