import React from 'react';
import { Link, useNavigate, useLocation, createSearchParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { Row, Col, Typography, Space, List, Divider, Button, Form, Input } from 'antd';
import { Facebook, Youtube, Instagram, Send as SendIcon } from 'react-feather';

import { Container } from '../../components';
import { colors, spaces, sizes, fonts } from '../../style';
import { isMobile } from '../../utils';

import { bar_menu, top_menu } from '../../helpers';

import { Locale } from '../../services';

const { Text, Title } = Typography;

const Footer = () => {
  const { settings } = useSelector((state) => state.app.data);

  return (
    <>
      <Space
        direction="vertical"
        style={{
          background: colors.light,
          display: 'flex',
          marginTop: spaces.xxxl,
        }}
      >
        <div
          style={{
            background: colors.primary,
            paddingTop: spaces.md,
            paddingBottom: spaces.md,
            marginBottom: spaces.md,
          }}
        >
          <Container xs={22} style={{ width: '100%' }}>
            <Row>
              <Col xs={15} md={8}>
                <Form>
                  <Input
                    size={'large'}
                    name={'email'}
                    placeholder={Locale['Enter your email address']}
                    type={'email'}
                    addonAfter={
                      <Button size={'small'} type={'link'} htmlType={'submit'}>
                        <SendIcon size={18} color={colors.gray} />
                      </Button>
                    }
                  />
                </Form>
              </Col>
              <Col xs={0} md={8}></Col>
              <Col xs={9} md={8} style={{ display: 'flex', justifyContent: 'flex-end' }}>
                <Space size={sizes.xs}>
                  <a href={settings?.facebook} target="_blank">
                    <Button type={'ghost'} shape="circle">
                      <Facebook size={16} color={colors.white} />
                    </Button>
                  </a>
                  <a href={settings?.instagram} target="_blank">
                    <Button type={'ghost'} shape="circle">
                      <Instagram size={16} color={colors.white} />
                    </Button>
                  </a>
                  <a href={settings?.youtube} target="_blank">
                    <Button type={'ghost'} shape="circle">
                      <Youtube size={16} color={colors.white} />
                    </Button>
                  </a>
                </Space>
              </Col>
            </Row>
          </Container>
        </div>

        <Container xs={22}>
          <Row>
            <Col xs={24} md={8} style={{ marginBottom: spaces.lg }}>
              <Title level={5}>{Locale['navigation']}</Title>
              <List
                grid={{
                  gutter: spaces.md,
                }}
                dataSource={bar_menu()}
                renderItem={(item) => (
                  <List.Item style={{ marginBottom: 0 }}>
                    <Link to={item.url} style={{ color: colors.gray }}>
                      {item.title}
                    </Link>
                  </List.Item>
                )}
              />
            </Col>
            <Col xs={24} md={8}>
              <Title level={5}>{Locale['do you need help']}</Title>
              <List
                grid={{
                  gutter: spaces.md,
                }}
                dataSource={top_menu()}
                renderItem={(item) => (
                  <List.Item style={{ marginBottom: 0 }}>
                    <Link to={item.url} style={{ color: colors.gray }}>
                      {item.title}
                    </Link>
                  </List.Item>
                )}
              />
            </Col>
          </Row>
        </Container>

        <Container xs={22} style={{ marginBottom: isMobile() ? sizes.xxxl : spaces.md }}>
          <Divider />
          <Row justify="center">
            <Col>
              <Text style={{ color: colors.gray }}>{Locale['Copyright 2022 happyshop.ge. All rights reserved.']}</Text>
            </Col>
          </Row>
        </Container>
      </Space>
    </>
  );
};

export default Footer;
