import React from 'react';
import { Link } from 'react-router-dom';
import { Card, Badge, Typography } from 'antd';

import { ProductTypeEnum } from '../../enums/ProductTypeEnum';
import { GradeEnum } from '../../enums/GradeEnum';
import { CDN_URL } from '../../helpers';
import { Locale } from '../../services';
import { colors, spaces, borders, sizes } from '../../style';

const { Meta } = Card;
const { Text } = Typography;

const ProfileCardCircle = ({ item }) => {
  const BadgeRibbon = (props) =>
    item?.grade == GradeEnum.Standart.key ? (
      props.children
    ) : (
      <Badge.Ribbon text={GradeEnum[item.grade]?.title} color={GradeEnum[item.grade]?.color}>
        {props.children}
      </Badge.Ribbon>
    );

  const BadgeRibbonVerified = (props) =>
    item.verified == 'True' ? (
      <Badge.Ribbon text={Locale['verified']} color={colors.primary}>
        {props.children}
      </Badge.Ribbon>
    ) : (
      props.children
    );

  return (
    <>
      <Link to={`/profiles/view/${item.uuid}`}>
        <BadgeRibbonVerified>
          <Card
            style={{ background: 'transparent' }}
            bodyStyle={{ paddingTop: 12, paddingRight: 0, paddingLeft: 0, paddingBottom: 0, textAlign: 'center' }}
            bordered={false}
            cover={<img style={{
              objectFit: 'cover',
              // borderRadius: '50rem',
              borderRadius: borders.md,
              border: '1px solid #d9d9d9',
              height: '100%', aspectRatio: '1 / 1'
            }}
              height={212}
              src={CDN_URL + item.img} />}
          >
            <Meta
              title={item.trans?.public?.name}
              description={
                <Text style={{ maxWidth: '100%' }} ellipsis={4}>
                  {item.trans.description_min}
                </Text>
              }
              style={{ marginBottom: 0, justifyContent: 'center' }}
            />
          </Card>
        </BadgeRibbonVerified>
      </Link>
    </>
  );
};

export default ProfileCardCircle;
