import { Button as ButtonDefault, Space } from 'antd';

export const Button = (props) => {
  return (
    <ButtonDefault
      onClick={props.onClick}
      type={props.type}
      shape={props.shape}
      size={props.size}
      block={props.block}
      danger={props.danger}
      style={{
        ...props.style,
        gap: 5,
      }}
    >
      {props.children}
    </ButtonDefault>
  );
};

Button.defaultProps = {
  onClick: '',
  type: 'default',
  shape: 'default',
  size: 'default',
  block: false,
  danger: false,
  style: {},
};

export default Button;
