import React, { useCallback, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';

import { message } from 'antd';

import { Api } from '#app/services';

import { addItem as AddItemToWishlist } from '../../redux/reducers/wishlistSlice';

const api = new Api();

const useWishlist = (id) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { wishlist } = useSelector((state) => state);
  const [inWishlist, setInWishlist] = useState(false);

  const addWishlist = () => {
    api
      .addUserWishlist(id)
      .then((response) => {
        dispatch(AddItemToWishlist({ id }));
        wishlist?.wishlist_items?.includes(id) ? setInWishlist(false) : setInWishlist(true);
        message.success(response.data?.message);
      })
      .catch((err) => console.log(err));
  };

  useEffect(() => {
    if (wishlist?.wishlist_items?.includes(id)) {
      setInWishlist(true);
    }
  }, []);

  return {
    inWishlist,
    setInWishlist,
    addWishlist,
  };
};

export default useWishlist;
