import React from 'react';
import { Link } from 'react-router-dom';
import { Card, Badge } from 'antd';

import { GradeEnum } from '../../enums/GradeEnum';
import { CDN_URL } from '../../helpers';
import { Locale } from '../../services';
import { colors, spaces, borders, sizes } from '../../style';

const { Meta } = Card;

const ProfileCard = ({ item }) => {
  const BadgeRibbon = (props) =>
    item.grade == GradeEnum.Standart.key ? (
      props.children
    ) : (
      <Badge.Ribbon text={GradeEnum[item.grade]?.title} color={GradeEnum[item.grade]?.color}>
        {props.children}
      </Badge.Ribbon>
    );

  const BadgeRibbonVerified = (props) =>
    item.verified == 'True' ? (
      props.children
    ) : (
      <Badge.Ribbon text={Locale['verified']} color={colors.primary}>
        {props.children}
      </Badge.Ribbon>
    );

  return (
    <>
      <Link to={`/profiles/view/${item.uuid}`}>
        <BadgeRibbonVerified>
          <Card style={{ background: 'transparent' }} bodyStyle={{ paddingTop: 12, paddingRight: 0, paddingLeft: 0, paddingBottom: 0 }} bordered={false} cover={<img style={{ objectFit: 'cover' }} height={212} src={CDN_URL + item.img} />}>
            <Meta title={item.trans?.title} description={item.price + ' ₾'} style={{ marginBottom: 0 }} />
          </Card>
        </BadgeRibbonVerified>
      </Link>
    </>
  );
};

export default ProfileCard;
