import React from 'react';
import { Link as ReactLink } from 'react-router-dom';
import { Tooltip, List, Card, Typography, Space } from 'antd';

import { Locale } from '../../services';
import { CDN_URL } from '../../helpers';

const { Text, Link } = Typography;

const ProductCardGroup = ({ item }) => {
  return (
    <>
      <Card style={{ background: '#f8f9fb' }} bodyStyle={{ paddingTop: 18, paddingRight: 24, paddingLeft: 24, paddingBottom: 18 }} bordered={false}>
        <Space direction="vertical" size={12} style={{ display: 'flex' }}>
          <Text strong={true}>{item.trans?.title}</Text>
          <List
            grid={{
              gutter: [8, 8],
              xs: 2,
              sm: 2,
              md: 2,
              lg: 2,
              xl: 2,
              xxl: 2,
            }}
            dataSource={item.products}
            renderItem={(product) => (
              <List.Item style={{ marginBottom: 0 }}>
                <ReactLink to={`/products/details/${product.id}`}>
                  <Tooltip placement="top" title={product.trans?.title}>
                    <img style={{ objectFit: 'cover' }} height={142} width={'100%'} src={CDN_URL + product?.img} />
                  </Tooltip>
                </ReactLink>
              </List.Item>
            )}
          />
          <div style={{ textAlign: 'end' }}>
            <ReactLink style={{ textAlign: 'end' }} to={`/products?category=${item.id}`}>
              <Link italic={true} style={{ fontSize: 12 }}>
                {Locale['See more']}
              </Link>
            </ReactLink>
          </div>
        </Space>
      </Card>
    </>
  );
};

export default ProductCardGroup;
