import { Menu } from 'antd';
import { Link } from 'react-router-dom';
import {
  Home as HomeIcon,
  Menu as MenuIcon,
  Search as SearchIcon,
  Heart as HeartIcon,
  ShoppingBag as ShoppingBagIcon,
  User as UserIcon,
  Facebook,
  Youtube,
  Instagram,
  Gift as GiftIcon,
} from 'react-feather';
import { Locale, GetLocale } from '../services';

export const top_menu = () => [
  {
    url: '/contact',
    title: Locale['Contact'],
  },
  {
    url: '/page/view/1',
    title: Locale['Terms and Conditions'],
  },
  {
    url: '/faq',
    title: Locale['Help'],
  },
];

export const primary_menu = () => [
  {
    url: '/products?type=Gift',
    title: Locale['gifts'],
    color: '#ff6d03',
    icon: <GiftIcon size={18} />,
  },
  {
    url: '/profiles',
    title: Locale['shops'],
    color: '',
    icon: '',
  },
  {
    url: '/products/premium',
    title: Locale['Popular'],
    color: '',
    icon: '',
  },
];

export const bar_menu = () => [
  {
    url: '/',
    title: Locale['main'],
    icon: <HomeIcon size={22} />,
  },
  // {
  //   url: '/',
  //   title: 'ძიება',
  //   icon: <SearchIcon size={22} />
  // },
  {
    url: '/user/wishlist',
    title: Locale['wishes'],
    icon: <HeartIcon size={22} />,
  },
  {
    url: '/basket',
    title: Locale['basket'],
    icon: <ShoppingBagIcon size={22} />,
  },
  {
    url: '/user',
    title: Locale['profile'],
    icon: <UserIcon size={22} />,
  },
  // {
  //   url: '/',
  //   title: 'მენიუ',
  //   icon: <MenuIcon size={22} />
  // },
];

export const profile_menu = () => (
  <Menu
    items={[
      {
        key: '1',
        label: <Link to={'/user'}>ჩემი პროფილი</Link>,
      },
      {
        key: '2',
        label: <Link to={'/user/orders'}>ონლაინ შეკვეთები</Link>,
      },
      {
        key: '3',
        label: <Link to={'/user/products'}>განცხადებები</Link>,
      },
      // {
      //   key: '2',
      //   type: 'divider',
      // },
    ]}
  />
);

export const social_media = () => [
  {
    url: '/',
    title: 'Facebook',
    icon: <Facebook size={16} />,
  },
  {
    url: '/',
    title: 'Instagram',
    icon: <Instagram size={16} />,
  },
  {
    url: '/',
    title: 'Youtube',
    icon: <Youtube size={16} />,
  },
];
