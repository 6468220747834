import React, { createContext } from 'react';
import { hydrateRoot, createRoot } from 'react-dom/client';
import { HelmetProvider } from 'react-helmet-async';
// import { getWindow, getDocument } from 'ssr-window';

import Core from './core/Core';

import reportWebVitals from './reportWebVitals';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';

// const window = getWindow();
// const document = getDocument();

const CoreElement = (
  <HelmetProvider>
    <Core />
  </HelmetProvider>
);

if (typeof window !== 'undefined') {
  createRoot(document.getElementById('root')).render(CoreElement);
} else {
  hydrateRoot(document.getElementById('root'), CoreElement);
}

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorkerRegistration.register();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
