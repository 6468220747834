import { useState, useEffect, useCallback } from 'react';
import { useParams, useNavigate, Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { Helmet } from 'react-helmet-async';
import { Row, Col, Rate, Progress, Space, Typography, Divider, Badge, List, Empty, Spin, Avatar, Tag, Affix, Comment, Form, Input, InputNumber, Tooltip, Statistic, Card, message } from 'antd';
import {
  ShoppingBag as ShoppingBagIcon,
  Truck as TruckIcon,
  Star as StarIcon,
  RefreshCcw as RefreshCcwIcon,
  Navigation as NavigationIcon,
  PhoneCall as PhoneCallIcon,
  ArrowUpRight as ArrowUpRightIcon,
  Heart as HeartIcon,
  Share2 as Share2Icon,
  User as AvatarIcon,
  CheckSquare as CheckSquareIcon,
} from 'react-feather';

import { useWishlist, useBasket } from '../../hooks';
import { Api, setCookie, getCookie, Locale } from '../../services';
import { CDN_URL, CURRENCY, sell_currency, ProductPriceHelper } from '../../helpers';
import { colors, spaces, borders, sizes, fonts } from '../../style';
import { Container, Banner, Slider, ThumbsGallery, Button, Icon } from '../../components';
import { ProductTypeEnum } from '../../enums/ProductTypeEnum';
import { GradeEnum } from '../../enums/GradeEnum';
import { isMobile, share } from '../../utils';

const { Title, Text, Paragraph } = Typography;
const { TextArea } = Input;
// const { Countdown } = Statistic;

const api = new Api();
// const deadline = Date.now() + 700 * 60 * 60 * 24 * 2 + 1000 * 30; // Moment is also OK

const ProductView = () => {
  const navigate = useNavigate();

  const { id } = useParams();
  const { app, user } = useSelector((state) => state);

  const { inWishlist, addWishlist } = useWishlist(id);
  const basket = useBasket(id);

  const [reviewForm] = Form.useForm();

  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [banners, setBanners] = useState([]);

  const [review, setReview] = useState({
    rate: 5,
    review: '',
  });
  const [reviews, setReviews] = useState([]);

  useEffect(() => {
    setLoading(true);
    api
      .getProductDetails(id)
      .then((response) => {
        let response_data = response.data;
        setData(response_data);
        setReviews(response_data?.product?.reviews);
        setLoading(false);
      })
      .catch((err) => console.log(err));
  }, [id]);

  useEffect(() => {
    setBanners(app.data?.banners);
  }, []);

  const addReview = async () => {
    setLoading(true);
    api
      .addProductReviews(id, review)
      .then((response) => {
        setReviews(response.data?.reviews?.data);
      })
      .catch((err) => message.error(err?.response?.data?.message));

    setLoading(false);
  };

  const SideBarCard = () => (
    <Card bordered={true}>
      <Space direction="vertical" size={spaces.md} style={{ display: 'flex' }}>
        <Space direction="vertical" size={0}>
          <Text type="secondary">{Locale['the price']}</Text>
          <Title level={3} type={'default'} style={{ marginBottom: 0 }}>
            <ProductPriceHelper values={data?.product} />
            {/* <Space>
            <Text delete={data.product?.price_sale != '0.00' ? true : false}>{data.product?.price + ' ' + sell_currency(data?.product?.type)}</Text>
            {data.product?.price_sale != '0.00' && <Text type="danger">{data.product?.price_sale + ' ' + CURRENCY}</Text>}
          </Space> */}
          </Title>
        </Space>
        {data.product?.user?.payment === 'True' && (
          <>
            <Icon size={sizes.sx}>
              <TruckIcon size={16} />
              <Text>{Locale['Shipping cost']}</Text>
              <Text strong>{app?.data.constants?.delivery_fee + ' ' + CURRENCY}</Text>
            </Icon>
            <Icon size={sizes.sx}>
              <CheckSquareIcon size={16} />
              <Text>
                {Locale['In stock']} <Text strong>{data.product?.stock}</Text> {Locale['unit']}
              </Text>
            </Icon>
            {data.product?.stock > 0 ? (
              data.product?.type === ProductTypeEnum.Gift.key ? (
                <Link to={`/checkout/gifts?company_id=${data.product?.user_id}&product_ids=${data.product?.id}&type=${ProductTypeEnum.Gift.key}`}>
                  <Button type="primary" size="large" block={true}>
                    <ShoppingBagIcon size={18} style={{ display: 'flex' }} />
                    {Locale['buy now']}
                  </Button>
                </Link>
              ) : (
                <Button
                  onClick={() => {
                    basket.add(1, data.product?.user_id);
                    setTimeout(() => {
                      navigate(`/checkout/products?company_id=${data.product?.user_id}&product_ids=${data.product?.id}`);
                    }, 500);
                  }}
                  type="primary"
                  size="large"
                  block={true}
                >
                  <ShoppingBagIcon size={18} style={{ display: 'flex' }} />
                  {Locale['buy now']}
                </Button>
              )
            ) : (
              <>
                <Button danger size="large" block={true}>
                  მარაგში არ არის
                </Button>
              </>
            )}
          </>
        )}
      </Space>
    </Card>
  );

  const SideBar = () => (
    <div style={{ position: 'sticky', top: 100 }}>
      {data.product?.grade == GradeEnum.Standart.key ? (
        <SideBarCard />
      ) : (
        <Badge.Ribbon text={GradeEnum[data.product?.grade]?.title} color={GradeEnum[data.product?.grade]?.color}>
          <SideBarCard />
        </Badge.Ribbon>
      )}
    </div>
  );

  const UserCard = () => (
    <Card bordered={true}>
      <Space direction={isMobile() ? 'vertical' : 'horizontal'} split={<Divider type={isMobile() ? 'horizontal' : 'vertical'} style={{ margin: spaces.md }} />} style={{ justifyContent: 'space-between' }}>
        <Space>
          <Avatar size={sizes.xl} src={CDN_URL + data.product?.user?.img} />
          <div>
            <Title level={5} style={{ marginBottom: 0 }}>
              {data.product?.user?.trans?.full_name}
            </Title>
            <Link to={`/profiles/view/${data.product?.user?.uuid}`} style={{ display: 'flex', alignItems: 'center', color: colors.gray }}>
              {Locale['Public profile']}
              <ArrowUpRightIcon size={18} style={{ marginLeft: spaces.xs }} />
            </Link>
          </div>
        </Space>
        <Space size={spaces.sm}>
          <a href={`tel:${data.product?.user?.public?.phone}`}>
            <Button shape="round" size={'defailt'}>
              <PhoneCallIcon size={16} />
              {data.product?.user?.public?.phone}
            </Button>
          </a>
          {/* <Link to={''}>
            <Button shape="round" size={'defailt'}>
              <NavigationIcon size={16} />
              {data.product?.user?.trans?.city ?? 'თბილისი'}
            </Button>
          </Link> */}
        </Space>
      </Space>
    </Card>
  );

  return (
    <>
      <Helmet>
        <title>{`${data.product?.trans?.title}`} | Happy Shop</title>
        <meta name="description" content={data.product?.trans?.description} />
        <meta property="og:title" content={data.product?.trans?.title} />
        <meta property="og:description" content={data.product?.trans?.description} />
        <meta property="og:image" content={CDN_URL + data.product?.img} />
      </Helmet>

      <Spin tip={Locale['loading']} spinning={loading}>
        <Container xs={22} spaces={spaces.xl} style={{ backgroundColor: colors.light }}>
          <Space direction="vertical" size={spaces.xxl} style={{ display: 'flex' }}>
            <Row gutter={[spaces.xxl, 0]}>
              <Col xs={24} md={24} lg={17} xl={17} xxl={18}>
                <Space direction="vertical" size={32} style={{ display: 'flex' }}>
                  <Row gutter={[40, 0]}>
                    <Col xs={24} md={24} lg={10} xl={10} xxl={10}>
                      <div className="product-gallery">
                        <ThumbsGallery
                          data={data.product?.galleries}
                          // img={data.product?.img}
                          video={data.product?.video}
                        />
                      </div>
                    </Col>
                    <Col xs={24} md={24} lg={14} xl={14} xxl={14}>
                      <Space size={spaces.md} direction="vertical" style={{ display: 'flex' }}>
                        <Space style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                          <Space size={spaces.xs} split={<Divider type="vertical" />} style={{ display: 'flex' }}>
                            {data.product?.condition == 'New' ? (
                              <Tag color="success" style={{ display: 'flex', alignItems: 'center' }}>
                                <StarIcon size={12} style={{ marginRight: spaces.xs }} />
                                {Locale['new']}
                              </Tag>
                            ) : (
                              <Tag color="default" style={{ display: 'flex', alignItems: 'center' }}>
                                <RefreshCcwIcon size={12} style={{ marginRight: spaces.xs }} />

                                {Locale['secondary']}
                              </Tag>
                            )}
                            <Text type="secondary">
                              {data.product?.views} {Locale['View']}
                            </Text>
                            {!isMobile() && (
                              <>
                                <Text type="secondary">{data.product?.date}</Text>
                                <Text type="secondary">
                                  #{Math.floor(Math.random() * 1001)}-{data.product?.id}
                                </Text>
                              </>
                            )}
                          </Space>
                          <Space>
                            <Button onClick={() => share(data.product?.trans?.title, window.location.href)} type={'link'} shape="circle" size={'medium'} style={{ flexDirection: 'column' }}>
                              <Share2Icon size={18} />
                            </Button>
                            <Button onClick={addWishlist} type={inWishlist ? 'primary' : 'link'} shape="circle" size={'medium'} style={{ flexDirection: 'column' }}>
                              <Tooltip title={Locale['Add to wish list']}>
                                <HeartIcon size={18} />
                              </Tooltip>
                            </Button>
                            {data.product?.user?.payment == 'True' && data.product?.type !== ProductTypeEnum.Gift.key && (
                              <Button onClick={() => (basket.hasItem ? basket.remove() : basket.add(1, data.product?.user_id))} type={basket.hasItem ? 'primary' : 'link'} shape="circle" size={'medium'} style={{ flexDirection: 'column' }}>
                                <Tooltip title={Locale['add to cart']}>
                                  <ShoppingBagIcon size={18} />
                                </Tooltip>
                              </Button>
                            )}
                          </Space>
                        </Space>
                        <Space size={0} direction={'vertical'}>
                          <Title level={3} style={{ marginBottom: 0 }}>
                            {data.product?.trans?.title}
                          </Title>
                          <Rate disabled value={data.product?.rate} />
                        </Space>
                        {!!data.product?.attributes?.length && (
                          <Card>
                            <List
                              size="small"
                              locale={{
                                emptyText: <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description={Locale['No data found']} />,
                              }}
                              dataSource={data.product?.attributes}
                              renderItem={(item, key) => (
                                <List.Item style={{ backgroundColor: key % 2 ? '' : colors.light }}>
                                  <Row style={{ width: '100%' }}>
                                    <Col span={12}>
                                      <Text type="secondary">{item.trans?.title}:</Text>
                                    </Col>
                                    <Col span={12}>
                                      <Text>{item.pivot?.value}</Text>
                                    </Col>
                                  </Row>
                                </List.Item>
                              )}
                            />
                          </Card>
                        )}

                        {isMobile() ? (
                          <>
                            <div style={{ marginTop: spaces.md, marginBottom: spaces.md }}>
                              <SideBar />
                            </div>
                            <UserCard />
                          </>
                        ) : (
                          <UserCard />
                        )}
                        {/* {data.product?.trans?.description_min && (
                          <div>
                            <Title level={5}>{Locale['Brief description of the product']}</Title>
                            <Paragraph ellipsis={{ rows: 2, expandable: true, symbol: Locale['in detail'] }} type="secondary">
                              {data.product?.trans?.description_min}
                            </Paragraph>
                          </div>
                        )} */}
                      </Space>
                    </Col>
                  </Row>
                  {(data.product?.trans?.description || !!data.product?.attributes?.length) && (
                    <Card style={{ backgroundColor: colors.white }} bordered={false}>
                      <Space size={spaces.md} direction="vertical">
                        {data.product?.trans?.description && (
                          <>
                            <Title level={5} style={{ marginBottom: 0 }}>
                              {Locale['Product details']}
                            </Title>
                            <div dangerouslySetInnerHTML={{ __html: data.product?.trans?.description }} />
                          </>
                        )}
                        {!!data.product?.attributes?.length && (
                          <>
                            <Divider />
                            <Title level={5} style={{ marginBottom: 0 }}>
                              {Locale['Product features']}
                            </Title>
                            <List
                              size="small"
                              locale={{
                                emptyText: <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description={Locale['No data found']} />,
                              }}
                              dataSource={data.product?.attributes}
                              renderItem={(item, key) => (
                                <List.Item style={{ backgroundColor: key % 2 ? '' : colors.light }}>
                                  <Row style={{ width: '100%' }}>
                                    <Col span={12}>
                                      <Text type="secondary">{item.trans?.title}:</Text>
                                    </Col>
                                    <Col span={12}>
                                      <Text>{item.pivot?.value}</Text>
                                    </Col>
                                  </Row>
                                </List.Item>
                              )}
                            />
                          </>
                        )}
                      </Space>
                    </Card>
                  )}
                  {!isMobile() && banners?.Product_First?.length && <Banner banner_data={banners?.Product_First[Math.floor(Math.random() * banners?.Product_First.length)]} />}
                </Space>
              </Col>
              {!isMobile() && (
                <Col xs={24} md={24} lg={7} xl={7} xxl={6}>
                  <SideBar />
                </Col>
              )}
            </Row>
          </Space>
        </Container>

        <Container xs={22} spaces={spaces.xl} style={{ marginTop: spaces.sm }}>
          <Row gutter={[0, spaces.xxl]}>
            <Col span={24}>
              <Space direction="vertical" size={spaces.lg} style={{ display: 'flex' }}>
                <Title level={4}>{Locale['similar products']}</Title>
                <Slider data={data.related_products} />
              </Space>
            </Col>
            <Col span={24}>
              <Space direction="vertical" size={spaces.lg} style={{ display: 'flex' }}>
                <Title level={4}>{Locale['Recommended for myself']}</Title>
                <Slider data={data.recomended_products} />
              </Space>
            </Col>
          </Row>
        </Container>

        <Container xs={22} spaces={spaces.xl} style={{ marginTop: spaces.sm }}>
          <Space direction="vertical" size={8} style={{ display: 'flex' }}>
            <Title level={4}>{Locale['Customer reviews']}</Title>
            <Row>
              <Col xs={24} md={24} lg={17} xl={17} xxl={18}>
                <Form form={reviewForm} onFinish={(values) => addReview(values)}>
                  <Comment
                    avatar={<Avatar src={''} alt="Author" />}
                    content={
                      <>
                        <Form.Item name={'rate'} rules={[{ required: true }]}>
                          <Rate defaultValue={5} onChange={(value) => setReview((prevState) => ({ ...prevState, rate: value }))} />
                        </Form.Item>
                        <Form.Item name={'review'} rules={[{ required: true }]}>
                          <TextArea rows={4} onChange={(value) => setReview((prevState) => ({ ...prevState, review: value.target?.value }))} />
                        </Form.Item>
                        <Button loading={loading} onClick={() => addReview()} type="primary">
                          {Locale['Add a rating']}
                        </Button>
                      </>
                    }
                  />
                </Form>
                <List
                  header={
                    <Text strong={true}>
                      {data.product?.reviews?.length} {Locale['Rate']}
                    </Text>
                  }
                  itemLayout="horizontal"
                  dataSource={reviews}
                  renderItem={(item) => (
                    <li>
                      <Comment
                        author={
                          <Title level={5} style={{ marginBottom: 0 }}>
                            {item.name}
                          </Title>
                        }
                        avatar={<Avatar style={{ backgroundColor: colors.primary }}>{item?.name?.charAt(0) || ''}</Avatar>}
                        content={<Text type="secondary">{item.review}</Text>}
                        datetime={<Rate disabled value={item.rate} />}
                      />
                    </li>
                  )}
                />
              </Col>

              <Col xs={24} md={24} lg={7} xl={7} xxl={6}></Col>
            </Row>
          </Space>
        </Container>
      </Spin>
    </>
  );
};

export default ProductView;
