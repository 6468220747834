import React, { useState, useEffect } from 'react';
import { Link, createSearchParams } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';

import { Row, Col, Menu, Tree, Space, Drawer, Typography, Button, Dropdown, Tabs, Card, Avatar } from 'antd';
import { Grid as GridIcon, List as ListIcon, Gift as GiftIcon, ChevronRight as ChevronRightIcon, ArrowRight as ArrowRightIcon } from 'react-feather';

import { Container, LanguageDropdown } from '../../components';
import { Api, Locale } from '../../services';
import { CDN_URL } from '../../helpers';
import { setCatalog } from '../../redux/reducers/appSlice';
import { colors, sizes, spaces, fonts, media } from '../../style';
import { isMobile } from '../../utils';

const { Paragraph, Title, Text } = Typography;

const Catalog = () => {
  const dispatch = useDispatch();

  const app = useSelector((state) => state.app);

  return !isMobile() ? (
    <>
      <Drawer
        placement="top"
        height={'70vh'}
        closable={false}
        onClose={() => dispatch(setCatalog({ visible: false }))}
        open={app?.utils?.catalog?.visible}
        getContainer={false}
        className="catalog-drawer"
        bodyStyle={{
          padding: 0,
          paddingTop: spaces.lg,
          paddingBottom: spaces.lg,
        }}
        style={{
          position: 'fixed',
          top: 77,
        }}
      >
        <Container>
          <Space direction={'vertical'} size={sizes.sm}>
            <Title className="uppercase system-font1" level={4}>
              {Locale['Catalog']}
            </Title>
            <Tabs
              defaultActiveKey="1"
              // type="card"
              // onChange={onChange}
              tabPosition={'left'}
              items={app?.data?.category_menu?.map((item, key) => {
                return {
                  label: (
                    <>
                      {/* <Card.Meta
                        style={{
                          padding: '10px 0px',
                        }}
                        avatar={<Avatar size="large" src={CDN_URL + item.img_cover} />}
                        className="system-font"
                        title={item.trans?.title}
                        description={item.trans?.description_min || Locale['category']}
                      /> */}
                      {/* <Card
                        style={{
                          width: 300,
                          marginTop: 16,
                        }}
                        // loading={loading}
                      >
                        <Card.Meta avatar={<Avatar src={CDN_URL + item.img_cover} />} title="Card title" description="This is the description" />
                      </Card> */}
                      <Space key={key}>
                        {/* <img width="20" height="20" src={CDN_URL + item.img} style={{ borderRadius: '50%' }} /> */}
                        <img width="36" height="36" src={CDN_URL + item.img_cover} style={{ objectFit: 'cover', borderRadius: '50%' }} />
                        <Title level={5} className="system-font">
                          {item.trans?.title}
                          {/* ({item.products_count}) */}
                        </Title>
                      </Space>
                    </>
                  ),
                  key: key,
                  children: (
                    <>
                      <Space size={spaces.md} direction={'vertical'}>
                        <Space style={{ justifyContent: 'space-between' }}>
                          <Title level={4}>
                            {item.trans?.title} ({item.products_count})
                          </Title>
                          <Link
                            onClick={() => dispatch(setCatalog({ visible: false }))}
                            to={`/products/?${createSearchParams({
                              category: item.id,
                            })}`}
                          >
                            <Button shape={'circle'} type={'default'} style={{ width: 34 }}>
                              <ChevronRightIcon size={18} />
                            </Button>
                          </Link>
                        </Space>
                        <Row gutter={[spaces.md, spaces.md]}>
                          {item.children?.map((item, key) => (
                            <Col key={key} span={6}>
                              <Space size={spaces.xs} direction={'vertical'}>
                                <Link
                                  onClick={() => dispatch(setCatalog({ visible: false }))}
                                  to={`/products/?${createSearchParams({
                                    category: item.id,
                                  })}`}
                                >
                                  <Button type={'link'} style={{ padding: 0 }}>
                                    <Text strong>
                                      {item?.trans?.title} ({item.products_count})
                                    </Text>
                                    <ArrowRightIcon size={12} />
                                  </Button>
                                </Link>
                                <Space size={spaces.xs} direction={'vertical'} className={'catalog-menu-children'}>
                                  {item.children?.map((item, key) => (
                                    <Link
                                      onClick={() => dispatch(setCatalog({ visible: false }))}
                                      to={`/products/?${createSearchParams({
                                        category: item.id,
                                      })}`}
                                    >
                                      <Text>{item?.trans?.title}</Text>
                                    </Link>
                                  ))}
                                </Space>
                                {item.children?.length > 5 && (
                                  <Link
                                    onClick={() => dispatch(setCatalog({ visible: false }))}
                                    to={`/products/?${createSearchParams({
                                      category: item.id,
                                    })}`}
                                    style={{ color: colors.primary, padding: 0 }}
                                  >
                                    {Locale['See more']}
                                  </Link>
                                )}
                              </Space>
                            </Col>
                          ))}
                        </Row>
                      </Space>
                    </>
                  ),
                };
              })}
            />
          </Space>
        </Container>
      </Drawer>
    </>
  ) : (
    <>
      <Drawer title={Locale['Catalog']} placement={'right'} extra={<LanguageDropdown />} contentWrapperStyle={{ width: '85%' }} onClose={() => dispatch(setCatalog({ visible: false }))} open={app?.utils?.catalog?.visible}>
        <Space direction={'vertical'} size={spaces.md}>
          {app?.data?.category_menu?.map((item, key) => (
            <Link
              key={key}
              onClick={() => dispatch(setCatalog({ visible: false }))}
              to={`/products/?${createSearchParams({
                category: item.id,
              })}`}
            >
              <Space size={spaces.sm}>
                <img width="20" height="20" src={CDN_URL + item.img} />
                <Title level={5} style={{ marginBottom: 0 }}>
                  {item.trans?.title}
                </Title>
              </Space>
            </Link>
          ))}
        </Space>
      </Drawer>
    </>
  );
};

export default Catalog;
