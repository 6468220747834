import { useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { Typography, Space, Avatar, Tooltip, Card, Divider, Row, Col, Tag, Empty } from 'antd';
import { ArrowRight as ArrowRightIcon, ArrowUpRight as ArrowUpRightIcon } from 'react-feather';

import { Api, Locale, getCookie } from '../services';
import { Container, Slider, Button, ProductCardSmall } from '../components';
import { colors, spaces, fonts, borders, sizes } from '../style';
import { CDN_URL, CURRENCY } from '../helpers';
import { isMobile } from '../utils';

const { Title, Text } = Typography;

const api = new Api();

const Basket = () => {
  const navigate = useNavigate();

  const { basket } = useSelector((state) => state);
  const { constants } = useSelector((state) => state.app.data);

  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    api
      .getBasket(basket?.data?.map((item) => item.id)?.join(','))
      .then((response) => {
        setData(response.data);
        setLoading(false);
      })
      .catch((err) => console.log(err));
  }, []);

  const checkout = (key) => {
    let user = data.users[key];
    let product_ids = user.products.map((value, key) => value.id);
    navigate(`/checkout/products?company_id=${user.id}&product_ids=${product_ids.join(',')}`, { replace: true });
  };

  return (
    <>
      <Container spaces={spaces.xl} style={{ backgroundColor: colors.light }}>
        {data.users?.length == 0 ? (
          <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description={Locale['No data found']} />
        ) : (
          <Row gutter={[spaces.md, spaces.md]}>
            {data.users?.map((user, key) => (
              <Col xs={24} sm={6} md={12} lg={8} xl={8}>
                <Card key={key}>
                  <Space>
                    <Avatar size={sizes.xxl} src={CDN_URL + user?.img} />
                    <div>
                      <Title level={5} style={{ marginBottom: 0 }}>
                        {user?.trans?.title}
                      </Title>
                      <Link to={`/profiles/view/${user?.uuid}`} style={{ display: 'flex', alignItems: 'center', color: colors.gray }}>
                        {Locale['Public profile']}
                        <ArrowUpRightIcon size={18} style={{ marginLeft: spaces.xs }} />
                      </Link>
                    </div>
                  </Space>
                  <Divider />
                  <Space size={spaces.md} direction={'vertical'}>
                    <Avatar.Group maxCount={4}>
                      {user?.products?.map((item, key) => (
                        <Avatar key={key} size={isMobile() ? 40 : 80} src={CDN_URL + item?.img} onClick={() => navigate(`/products/details/${item?.id}/${item.slug}`, { replace: true })} />
                      ))}
                    </Avatar.Group>
                    <Button onClick={() => checkout(key)} type={'success'} shape="rounded" block>
                      {Locale['Go to order']}
                      <ArrowRightIcon size={20} />
                    </Button>
                  </Space>
                </Card>
              </Col>
            ))}
          </Row>
        )}
      </Container>
    </>
  );
};

export default Basket;
