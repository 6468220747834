import { useState, useEffect } from 'react';
import { Collapse, Typography, Skeleton, List } from 'antd';

import { Api, Locale } from '../services';
import { Container } from '../components';
import { colors, spaces, fonts, borders } from '../style';

const { Panel } = Collapse;
const { Title, Text } = Typography;

const Faq = () => {
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState([]);

  const api = new Api();

  useEffect(() => {
    api
      .getFaq()
      .then((response) => {
        setData(response.data);
        setLoading(false);
      })
      .catch((err) => console.log(err));
  }, []);

  return (
    <>
      <Container lg={12} xl={12} xxl={12} spaces={spaces.xl}>
        <Skeleton loading={loading}>
          <Collapse accordion={true} bordered={false} style={{ backgroundColor: colors.white }}>
            {data.faqs?.map((item, key) => (
              <Panel header={item.trans?.title} key={key} style={{ border: 0, borderRadius: borders.sm, marginBottom: spaces.sm, backgroundColor: colors.light }}>
                <Text>{item.trans?.description}</Text>
              </Panel>
            ))}
          </Collapse>
        </Skeleton>
      </Container>
    </>
  );
};

export default Faq;
