import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { Row, Col, Select, Radio, Typography, Checkbox, Button, Space, List, Divider } from 'antd';
import { Filter } from 'react-feather';

import { Locale } from '../../services';

import { sizes, spaces } from '../../style';

const { Option } = Select;
const { Title, Text, Link } = Typography;

const options = ['Skirt', 'Blouses & Shirts', 'Women Tops', 'Sweaters', 'Jackets & Coats', 'Meat grinders and choppers', 'Microwave ovens', 'Bottoms', 'Swimsuit'];

const ProductFilter = ({ categories }) => {
  // const [categories, setCategories] = useState([]);

  // useEffect(() => {
  //   setCategories(data.category);
  // }, [])

  const app = useSelector((state) => state.app);

  return (
    <>
      <div className="inner-wrapper-sidebar">
        <Space direction="vertical" size={spaces.md}>
          <Space size={8}>
            <Filter size={18} style={{ display: 'flex' }} />
            <Title level={5} style={{ marginBottom: 0 }}>
              {Locale['filter']}
            </Title>
          </Space>
          <Space direction={'vertical'} size={spaces.md} style={{ display: 'flex' }}>
            <Radio.Group onChange={() => {}} value={1}>
              <Space direction="vertical">
                <Radio value={1}>Option A</Radio>
              </Space>
            </Radio.Group>

            {/* <Space direction="vertical" style={{ display: 'flex', }}>
              <Text>მდებარეობა</Text>
              <Select
                defaultValue="0"
                loading={false}
                style={{ width: '100%' }}
              >
                <Option value="0">ყველა</Option>
                <Option value="1">თბილისი</Option>
              </Select>
            </Space> */}

            {/* {categories && (
              <>
                <List
                  itemLayout="vertical"
                  dataSource={categories && categories}
                  renderItem={(item, key) => (
                    <li key={key}>
                      <Link href={`/products/category/${item.id}`} type="secondary" strong>
                        {item.trans.title}
                      </Link>
                    </li>
                  )}
                />
                <Divider style={{ margin: 0 }} />
              </>
            )} */}

            {/* <Space direction="vertical" style={{ display: 'flex', }}>
              <Text>მდებარეობა</Text>
              <Select
                defaultValue="0"
                loading={false}
                style={{ width: '100%' }}
              >
                <Option value="0">ყველა</Option>
                <Option value="1">თბილისი</Option>
              </Select>
            </Space> */}
          </Space>
        </Space>

        {/* <div className='mb-6'>
          <h5 className='text text-bold mb-2'>კატეგორიები</h5>
          <Checkbox.Group
            options={options}
            defaultValue={['Apple']}
          />
        </div> */}
      </div>
    </>
  );
};

export default ProductFilter;
