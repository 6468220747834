import React from 'react';
import { Link } from 'react-router-dom';
import { Card, Badge, Space, Typography } from 'antd';

import { ProductTypeEnum } from '../../enums/ProductTypeEnum';
import { GradeEnum } from '../../enums/GradeEnum';
import { CDN_URL, CURRENCY, ProductPriceHelper } from '../../helpers';
import { isMobile } from '../../utils';
import { Locale } from '../../services';

const { Meta } = Card;
const { Text } = Typography;

const getMainImg = (item) => {
  return item?.img && item?.img.length > 45 && item.img !== 'null' ? item.img : item?.img_main;
};

const ProductCard = ({ item = [], height = isMobile() ? 160 : 280 }) => {
  const BadgeRibbon = (props) =>
    item?.stock > 0 ? (
      item.grade == GradeEnum.Standart.key || item.type == ProductTypeEnum.Gift.key ? (
        props.children
      ) : (
        <Badge.Ribbon text={GradeEnum[item.grade]?.title} color={GradeEnum[item.grade]?.color}>
          {props.children}
        </Badge.Ribbon>
      )
    ) : (
      <Badge.Ribbon text={Locale['Out of Stock']} color={'red'}>
        {props.children}
      </Badge.Ribbon>
    );

  return (
    <>
      <Link to={`/products/details/${item?.id}/${item.slug}`} data-id={item?.id} data-img={item?.img}>
        <BadgeRibbon>
          <Card style={{ background: 'transparent' }} bodyStyle={{ paddingTop: 12, paddingRight: 0, paddingLeft: 0, paddingBottom: 0 }} bordered={false} cover={<img style={{ objectFit: 'cover' }} height={height} src={CDN_URL + getMainImg(item)} />}>
            <Meta
              title={item?.trans?.title ?? '-----------'}
              description={
                <ProductPriceHelper values={item} />
                // <Space>
                //   <Text delete={item?.price_sale != '0.00' ? true : false}>{item?.price + ' ' + sell_currency(item?.type)}</Text>
                //   {item?.price_sale != '0.00' && <Text type="danger">{item?.price_sale + ' ' + CURRENCY}</Text>}
                // </Space>
              }
              style={{ marginBottom: 0 }}
            />
          </Card>
        </BadgeRibbon>
      </Link>
    </>
  );
};

export default ProductCard;
