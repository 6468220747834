import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useHistory, useParams, useNavigate, useSearchParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import ImgCrop from 'antd-img-crop';
import { Row, Col, Space, Slider, Typography, Tooltip, Divider, Spin, Card, Button, Affix, PageHeader, Form, Input, Radio, Steps, InputNumber, message, notification, Upload, Tag, Checkbox, Select, Cascader, Anchor } from 'antd';
import {
  Navigation as NavigationIcon,
  loading as loadingIcon,
  PlusCircle as PlusCircleIcon,
  MinusCircle as MinusCircleIcon,
  Upload as UploadIcon,
  Truck as TruckIcon,
  Star as StarIcon,
  ChevronLeft as ChevronLeftIcon,
  Plus as PlusIcon,
  Image as ImageIcon,
} from 'react-feather';
import { DndProvider, useDrag, useDrop } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { TouchBackend } from 'react-dnd-touch-backend';
import update from 'immutability-helper';

import { Api, Locale } from '../../services';
import { API_URL, CDN_URL, CURRENCY, sell_currency, ProductPriceHelper } from '../../helpers';
import { GradeEnum } from '../../enums/GradeEnum';
import { ProductTypeEnum } from '../../enums/ProductTypeEnum';
import { Container, UserMenu, UserHeader } from '../../components';
import { colors, spaces, borders, shadows, empty_img } from '../../style';
import { isMobile, TextEditor } from '../../utils';

const { Title, Text } = Typography;
const { Step } = Steps;
const { Meta } = Card;
const { TextArea } = Input;
const { Option } = Select;
const { Link } = Anchor;

const type = 'DragableUploadList';

const getBase64 = (img, callback) => {
  const reader = new FileReader();
  reader.addEventListener('load', () => callback(reader.result));
  reader.readAsDataURL(img);
};

const validate_img_size = (file) => {
  const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png';
  if (!isJpgOrPng) {
    message.error('You can only upload JPG/PNG file!');
  }
  const isLt2M = file.size / 1024 / 1024 < 8;
  if (!isLt2M) {
    message.error('Image must smaller than 8MB!');
  }
  return isJpgOrPng && isLt2M;
};

const DragableUploadListItem = ({ originNode, moveRow, file, fileList }) => {
  const ref = useRef(null);
  const index = fileList.indexOf(file);
  const [{ isOver, dropClassName }, drop] = useDrop({
    accept: type,
    collect: (monitor) => {
      const { index: dragIndex } = monitor.getItem() || {};
      if (dragIndex === index) {
        return {};
      }
      return {
        isOver: monitor.isOver(),
        dropClassName: dragIndex < index ? ' drop-over-downward' : ' drop-over-upward',
      };
    },
    drop: (item) => {
      moveRow(item.index, index);
    },
  });
  const [, drag] = useDrag({
    type,
    item: {
      index,
    },
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
  });
  drop(drag(ref));
  const errorNode = <Tooltip title="Upload Error">{originNode.props.children}</Tooltip>;
  return (
    <div
      ref={ref}
      className={`ant-upload-draggable-list-item ${isOver ? dropClassName : ''}`}
      style={{
        cursor: 'move',
      }}
    >
      {file.status === 'error' ? errorNode : originNode}
    </div>
  );
};

const api = new Api();

const UserProducts = () => {
  const navigate = useNavigate();

  const { id } = useParams();
  const { token } = useSelector((state) => state.user);

  const [searchParams] = useSearchParams();

  const [form] = Form.useForm();
  const [formValues, setFormValues] = useState([]);
  const [product, setProduct] = useState([]);
  const [productType, setProductType] = useState('');

  const [categories, setCategories] = useState([]);
  const [attributes, setAttributes] = useState([]);
  const [gradeValue, setGradeValue] = useState([]);
  const [gradeDay, setGradeDay] = useState(1);
  const [deliveryValue, setDeliveryValue] = useState();

  const [imgUrl, setImgUrl] = useState();
  const [imgList, setImgList] = useState([]);
  const [imgLoading, setImgLoading] = useState(false);

  const [enums, setEnums] = useState([]);
  const [loading, setLoading] = useState(true);

  const [currentStep, setCurrentStep] = useState(0);
  const [currentStepStatus, setCurrentStepStatus] = useState('process');

  const [totalPrice, setTotalPrice] = useState(0);
  const [loadingPriceExpanse, setLoadingPriceExpanse] = useState(true);

  const onChangeStep = async (value) => {
    if (value > currentStep) {
      await form.validateFields();
    }
    setCurrentStep(value);
    setCurrentStepStatus('process');
  };

  useEffect(() => {
    setLoading(true);

    api
      .eidtUserProduct(id)
      .then((response) => {
        setCategories(response.data?.categories);
        setAttributes(response.data?.attributes);

        const product = response.data?.product;

        const product_values = {
          title: product.trans?.title,
          sku: product.sku,
          description: product.trans?.description,
          img: product.img,
          video: product.video,
          stock: product.stock,
          price: product.price,
          price_sale: product.price_sale,
          price_expanse: product.price_expanse,
          price_sale_expanse: product.price_sale_expanse,
          categories: product.categories?.map((item, key) => item.id.toString()),
          attributes: product.attributes?.map((item, key) => ({ id: item.id, value: item.pivot?.value })),
          type: product.type,
          grade: product.grade,
          condition: product.condition,
          // status: product.status,
        };

        form.setFieldsValue(product_values);

        setFormValues(product_values);
        setProduct(product_values);
        setImgUrl(CDN_URL + product.img);
        setGradeValue(GradeEnum[product.grade]);
        setProductType(product_values.type);

        let gallery = [];

        product?.galleries?.forEach((el) => {
          gallery.push({
            uid: el.id,
            name: el.img,
            url: CDN_URL + el.img,
            thumbUrl: CDN_URL + el.img,
            response: {
              id: el.id,
              img: el.img,
            },
            status: 'done',
          });
        });

        setImgList(gallery);
        setLoading(false);
      })
      .catch((err) => console.log(err));

    api
      .getEnums()
      .then((response) => {
        setEnums(response.data);
      })
      .catch((err) => console.log(err));
  }, [id]);

  useEffect(() => {
    let tab_step = parseInt(Object.fromEntries(searchParams).step);
    tab_step && onChangeStep(tab_step);
  }, []);

  const onFinish = (values) => {
    setLoading(true);

    api
      .updateUserProduct(id, formValues)
      .then((response) => {
        if (gradeValue.key != GradeEnum.Standart.key && gradeValue.key != product.grade) {
          api
            .checkoutUserProductGrade({
              grade: gradeValue.key,
              qty: gradeDay,
              product_id: id,
            })
            .then((response) => {
              window.location.href = response.data?.redirect;
            })
            .catch((err) => {
              setLoading(false);
              message.error(err?.response?.data?.message);
            });
          return;
        }

        navigate('/user/products');

        setTimeout(() => {
          message.success(response.data?.message);
        }, 500);

        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
        setCurrentStep(1);
        setCurrentStepStatus('error');
        message.error(err?.response?.data?.message);
        // notification['error']({
        //   message: err?.response?.data?.message,
        //   description: Object.values(err?.response?.data?.errors).map((item) => (`${item} \n`)),
        // });
      });
  };

  const onFinishFailed = (errorInfo) => {
    console.log('Failed:', errorInfo);
  };

  const onValuesChange = (_, values) => {
    setFormValues((prevState) => ({ ...prevState, ...values }));
  };

  const priceExpanse = async (price) => {
    let response = await Api.build().getStorePriceExpanse(price);
    let result = await response.data;
    return result.price_expanse;
  };

  useEffect(() => {
    setLoadingPriceExpanse(true);

    const fetch = async () => {
      let price_expanse = await priceExpanse(formValues.price);
      let price_sale_expanse = await priceExpanse(formValues.price_sale);
      setFormValues((prevState) => ({ ...prevState, price_expanse: price_expanse, price_sale_expanse: price_sale_expanse }));
      setLoadingPriceExpanse(false);
    };

    const timer = setTimeout(() => {
      fetch();
    }, 500);

    return () => clearTimeout(timer);
  }, [formValues.price, formValues.price_sale]);

  const onChangeGrade = (e) => {
    setGradeValue(GradeEnum[e.target.value]);
  };

  const onChangeGradeDay = (val) => {
    val == 0 ? setGradeValue(GradeEnum.Standart) : setGradeValue(GradeEnum.Popular);
    setGradeDay(val);
    // console.log('radio checked', GradeEnum[e.target.value], e.target);
    // setGradeValue(GradeEnum[e.target.value]);
  };

  const onChangeProductType = (e) => {
    setProductType(e.target.value);
  };

  const moveRow = useCallback(
    (dragIndex, hoverIndex) => {
      if (hoverIndex == 0) {
        setImgUrl(imgList[dragIndex]?.url);
        setFormValues((prevState) => ({ ...prevState, img: imgList[dragIndex]?.name }));
      }

      const dragRow = imgList[dragIndex];

      let temp_img_list = update(imgList, {
        $splice: [
          [dragIndex, 1],
          [hoverIndex, 0, dragRow],
        ],
      });

      Api.build()
        .updateUserProductGallerySort(id, { sort: temp_img_list })
        .then((response) => {
          // console.log(response);
        })
        .catch((error) => console.log(error));

      setImgList(temp_img_list);
    },
    [imgList],
  );

  const onChangeImgList = ({ fileList: newFileList }) => {
    setImgList(newFileList);
  };

  useEffect(() => {
    if (imgList[0]?.status == 'done') {
      setImgUrl(imgList[0]?.response?.id ? CDN_URL + imgList[0]?.response?.img : imgList[0]?.url);
      setFormValues((prevState) => ({ ...prevState, img: imgList[0]?.response?.id ? imgList[0]?.response?.img : imgList[0]?.name }));
    }
  }, [imgList]);

  const steps = [
    {
      title: Locale['Step 1'],
      description: Locale['Details'],
      content: (
        <>
          <Space direction={isMobile() && 'vertical'} size={!isMobile() && spaces.xl}>
            <Form.Item onChange={onChangeProductType} label={Locale['Application type']} name="type">
              <Radio.Group defaultValue={'Sell'} buttonStyle="solid" size="large">
                {enums?.product?.type?.map((item) => (
                  <Radio.Button value={item}>{Locale[item]}</Radio.Button>
                ))}
              </Radio.Group>
            </Form.Item>
            <Form.Item label={Locale['condition of the item']} name="condition">
              <Radio.Group defaultValue={'New'} buttonStyle="solid" size="large">
                {enums?.product?.condition?.map((item) => (
                  <Radio.Button value={item}>{Locale[item]}</Radio.Button>
                ))}
              </Radio.Group>
            </Form.Item>
          </Space>
          {!loading && (
            <Form.Item style={{ marginBottom: 0 }} label={Locale['category']} name="categories" rules={[{ required: true, message: Locale['Please select a category!'] }]}>
              <Cascader
                allowClear
                showSearch
                // onChange={(val) => console.log(val)}
                // displayRender={(label) => label}
                options={categories.map((category, key) => ({
                  value: category.id.toString(),
                  label: category.trans?.title,
                  children: category?.children?.map((children, key) => ({
                    value: children.id.toString(),
                    label: children.trans?.title,
                    children: children?.children?.map((child, key) => ({
                      value: child.id.toString(),
                      label: child.trans?.title,
                    })),
                  })),
                }))}
                size={'large'}
                placeholder={Locale['Select a category']}
              />
              {/* <Select placeholder={Locale['Select a category']} showSearch={false} size={'large'} allowClear>
              {categories.map((category, key) => (
                <Option key={key} value={category.id}>
                  {category.trans?.title}
                </Option>
              ))}
            </Select> */}
            </Form.Item>
          )}
        </>
      ),
    },
    {
      title: Locale['Step 2'],
      description: Locale['photographs'],
      content: (
        <Space size={spaces.md} direction={'vertical'}>
          {!loading && (
            <>
              <DndProvider
                backend={isMobile() ? TouchBackend : HTML5Backend}
                // options={{
                //   scrollAngleRanges: [{ start: 300 }, { end: 60 }, { start: 120, end: 240 }],
                // }}
              >
                <div className="gallery-upload-list">
                  <Upload
                    name="img"
                    listType="picture-card"
                    accept={'image/png, image/jpeg'}
                    // showUploadList={{
                    //   showRemoveIcon: false,
                    // }}
                    beforeUpload={validate_img_size}
                    headers={{
                      Accept: 'application/json',
                      Authorization: `Bearer ${token}`,
                    }}
                    fileList={imgList}
                    onChange={onChangeImgList}
                    defaultFileList={[...imgList]}
                    action={`${API_URL}/user/products/gallery/upload/${id}`}
                    multiple
                    maxCount={10}
                    itemRender={(originNode, file, currFileList) => <DragableUploadListItem originNode={originNode} file={file} fileList={currFileList} moveRow={moveRow} />}
                    onRemove={(file) => {
                      let uid = !isNaN(file.uid) ? file.uid : file.response.id;
                      api
                        .deleteUserProductGalleryItem(id, uid)
                        .then((response) => {
                          let imgListTemp = imgList;
                          imgListTemp = imgListTemp.filter((el) => el.uid !== uid);
                          setImgList(imgListTemp);

                          message.success(response.data?.message);
                        })
                        .catch((err) => console.log(err));
                    }}
                  >
                    <UploadIcon size={18} />
                  </Upload>
                </div>
              </DndProvider>
            </>
          )}
          <Text type={'secondary'}>თითოეული ფოტოს მაქსიმალური ზომა არ უნდა აღემატებოდეს 2MB-ს.</Text>
        </Space>
      ),
    },
    {
      title: Locale['Step 3'],
      description: Locale['Features'],
      content: (
        <>
          <Form.Item label={Locale['title']} name="title" rules={[{ required: true, message: Locale['Please fill in the title!'] }]}>
            <Input size={'large'} showCount maxLength={75} />
          </Form.Item>
          <Form.Item label={'SKU'} name="sku" rules={[{ required: false, message: Locale['Please fill in the SKU!'] }]}>
            <Input size={'large'} showCount />
          </Form.Item>
          <Form.Item label={Locale['description']} name="description">
            <TextArea showCount maxLength={500} style={{ height: 120 }} />
            {/* <TextEditor /> */}
          </Form.Item>
          <Form.Item initialValue={0} label={Locale['Stock']} name="stock">
            <InputNumber min={0} max={1000} size="large" style={{ width: '100%' }} />
          </Form.Item>
          <Form.Item label={'Youtube ' + Locale['video'] + ' URL'} name="video" rules={[{ required: false, message: Locale['Please fill in the video!'] }]}>
            <Input size={'large'} showCount maxLength={75} />
          </Form.Item>
          <Space>
            <Form.Item
              label={productType != ProductTypeEnum.Gift.key ? Locale['the price'] : Locale['How many coins are they worth?']}
              name="price"
              style={{
                marginBottom: spaces.sm,
              }}
              rules={[{ required: true, message: Locale['Please fill in the price!'] }]}
            >
              <InputNumber min={0} size="large" style={{ width: '100%' }} prefix={sell_currency(productType)} />
            </Form.Item>
            {productType != ProductTypeEnum.Gift.key && (
              <Form.Item
                label={Locale['discounted price']}
                name="price_sale"
                style={{
                  marginBottom: spaces.sm,
                }}
              >
                <InputNumber min={0} max={formValues.price} size="large" style={{ width: '100%' }} prefix="₾" />
              </Form.Item>
            )}
          </Space>
          <Text type={'secondary'}>განცხადების ღირებულებას დაემატება საიტის საკომისიო.</Text>
          <br />
          <Space size={spaces.sm}>
            <Text type={'secondary'}>განცხადების ჯამური ღირებულებას იქნება</Text>
            <Text strong>{loadingPriceExpanse ? <Spin size="small" /> : <ProductPriceHelper values={formValues} />}</Text>
          </Space>
        </>
      ),
    },
    {
      title: Locale['Step 5'],
      description: Locale['Attributes'],
      content: (
        <>
          <Form.List name="attributes">
            {(fields, { add, remove }) => (
              <>
                {fields.map((field) => (
                  <Space key={field.key} align={'center'}>
                    <Form.Item noStyle shouldUpdate={(prevValues, curValues) => prevValues.attributes !== curValues.attributes}>
                      <Form.Item
                        {...field}
                        label="ატრიბუტი"
                        name={[field.name, 'id']}
                        rules={[
                          {
                            required: true,
                            message: 'Missing attribute',
                          },
                        ]}
                      >
                        <Select
                          // disabled={!form.getFieldValue('area')}
                          style={{
                            width: 250,
                          }}
                        >
                          {attributes?.map((item, key) => (
                            <Option key={key} value={item.id}>
                              {item.trans?.title}
                            </Option>
                          ))}
                        </Select>
                      </Form.Item>
                    </Form.Item>
                    <Form.Item
                      {...field}
                      label="მნიშვნელობა"
                      name={[field.name, 'value']}
                      rules={[
                        {
                          required: true,
                          message: 'Missing value',
                        },
                      ]}
                    >
                      <Input />
                    </Form.Item>
                    <Button type={'link'}>
                      <MinusCircleIcon onClick={() => remove(field.name)} />
                    </Button>
                  </Space>
                ))}
                <Button type="dashed" onClick={() => add()} block>
                  ატრიბუტის დამატება (ზომა, ფერი მატერია და ა.შ.)
                </Button>
              </>
            )}
          </Form.List>
        </>
      ),
    },
    // {
    //   title: Locale['Step 4'],
    //   description: Locale['Accelerate sales'],
    //   content: (
    //     <>
    //       <Form.Item label={Locale['Accelerate sales']} name="grade">
    //         <Row id="Step4" gutter={[spaces.xl, spaces.md]}>
    //           <Col xs={24} md={12}>
    //             <Space>
    //               <Tag style={{ height: 40, width: 40 }} color={GradeEnum.Popular.color}>
    //                 {GradeEnum.Popular.img}
    //               </Tag>
    //               <Space size={spaces.xxxl} style={{ display: 'flex', justifyContent: 'space-between' }}>
    //                 <Title level={5} style={{ marginBottom: 0 }}>
    //                   {Locale[GradeEnum.Popular.key]}
    //                 </Title>
    //                 <Title level={5} style={{ marginBottom: 0 }}>
    //                   {GradeEnum.Popular.price} {CURRENCY}
    //                 </Title>
    //                 {/* <Space size={0} direction={'vertical'}>
    //                   <Text type={'secondary'}>{Locale['day']}</Text>
    //                   <Text>
    //                     {GradeEnum.Popular.price} {CURRENCY}
    //                   </Text>
    //                 </Space> */}
    //               </Space>
    //             </Space>
    //           </Col>
    //           <Col xs={24} md={12}>
    //             {!loading && (
    //               <Form.Item name="days" rules={[{ required: false, message: Locale['Please select the number of days!'] }]}>
    //                 <Slider
    //                   onChange={onChangeGradeDay}
    //                   defaultValue={0}
    //                   step={1}
    //                   min={0}
    //                   max={30}
    //                   marks={{ 0: '0 დღე', 30: '30 დღე' }}
    //                   tooltip={{
    //                     open: true,
    //                     placement: isMobile() && 'bottom',
    //                   }}
    //                 />
    //               </Form.Item>
    //             )}
    //           </Col>
    //         </Row>
    //       </Form.Item>
    //       <Space size={0} direction={'vertical'} style={{ display: 'flex', justifyContent: 'end', textAlign: 'end' }}>
    //         <Title level={4} style={{ marginBottom: 0 }}>
    //           {gradeValue.price ? gradeValue.price * gradeDay : 0} {CURRENCY}
    //         </Title>
    //         <Text type={'secondary'}>{Locale['Total price']}</Text>
    //       </Space>
    //       <Divider />
    //     </>
    //   ),
    // },
    {
      title: Locale['Step 5'],
      // description: Locale['publication'],
      content: (
        <>
          <Space style={{ justifyContent: 'flex-end' }}>
            <Button type="primary" size={'large'} htmlType="submit">
              {Locale['publication']}
            </Button>
          </Space>
        </>
      ),
    },
  ];

  return (
    <>
      <Container xs={22} spaces={spaces.xl} style={{ backgroundColor: colors.light }}>
        <Row gutter={[spaces.xxl, spaces.md]}>
          <Col xs={24} md={24} lg={7} xl={7} xxl={6}>
            <Space direction="vertical" size={spaces.lg} style={{ display: 'flex', backgroundColor: colors.white, boxShadow: shadows.sm, padding: spaces.lg, borderRadius: borders.sm }}>
              {isMobile() ? <UserHeader /> : <UserMenu />}
            </Space>
            {/* <Anchor targetOffset={window.innerHeight / 2} affix={false}>
              <Link href="#Step4" title="Basic demo" />
            </Anchor> */}
          </Col>
          <Col xs={24} md={24} lg={17} xl={17} xxl={18}>
            <Spin tip={Locale['loading']} spinning={loading}>
              {/* <PageHeader onBack={() => window.history.back()} title="განცხადების დამატება" subTitle={`პროდუცტი #${product.id}`} /> */}
              <Form layout={'vertical'} form={form} onFinish={onFinish} onFinishFailed={onFinishFailed} onValuesChange={onValuesChange}>
                {() => (
                  <>
                    <Row gutter={[spaces.xxl, 0]}>
                      <Col xs={24} md={24} lg={17} xl={17} xxl={17}>
                        <Space size={spaces.lg} direction={'vertical'}>
                          {steps.map((item, key) => (
                            <Card title={item.description} key={key} bordered={false} style={{ position: 'relative' }}>
                              {item.content}
                            </Card>
                          ))}
                        </Space>
                      </Col>
                      {!isMobile() && (
                        <Col xs={24} md={7}>
                          <Affix offsetTop={100}>
                            <Card bordered={false}>
                              <Card
                                style={{ background: 'transparent' }}
                                bodyStyle={{ paddingTop: 12, paddingRight: 0, paddingLeft: 0, paddingBottom: 0 }}
                                bordered={false}
                                cover={
                                  imgUrl ? (
                                    <img style={{ objectFit: 'cover' }} height={200} src={imgUrl.replace(CDN_URL, '') != 'null' ? imgUrl : empty_img} />
                                  ) : (
                                    <img style={{ objectFit: 'cover' }} height={200} src={formValues.img != null ? CDN_URL + formValues.img : empty_img} />
                                  )
                                }
                              >
                                <Meta
                                  style={{ marginBottom: 0 }}
                                  title={formValues.title}
                                  description={
                                    loadingPriceExpanse ? <Spin size="small" /> : <ProductPriceHelper values={formValues} />
                                    // <Space>
                                    //   <Text delete={formValues.price_sale != '0.00' ? true : false}>{formValues.price + ' ' + sell_currency(productType)}</Text>
                                    //   {formValues.price_sale != '0.00' && <Text type="danger">{formValues.price_sale + ' ' + CURRENCY}</Text>}
                                    // </Space>
                                  }
                                />
                              </Card>
                            </Card>
                          </Affix>
                        </Col>
                      )}
                    </Row>
                  </>
                )}
              </Form>
            </Spin>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default UserProducts;
