import React, { useCallback, useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Api } from '../services';

const useHome = () => {
  const app = useSelector((state) => state.app);

  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [popularSearchs, setPopularSearchs] = useState([]);

  useEffect(() => {
    Api.build()
      .getMain()
      .then((response) => {
        setData(response.data);
        setLoading(false);
      })
      .catch((err) => console.log(err));
  }, []);

  useEffect(() => {
    setPopularSearchs(app.data?.category_menu);
  }, []);

  return {
    app,
    data,
    loading,
    popularSearchs,
  };
};

export default useHome;
