import { Link } from 'react-router-dom';
import { Row, Col, Typography, Space, Button, Spin, Card, Drawer, Tabs, Tag } from 'antd';
import { Search as SearchIcon } from 'react-feather';
import { Helmet } from 'react-helmet-async';

import { Container, SliderDefault, Banner, ProductCard, Slider, Category, CatalogCard, Carousel, TopNavigation, SectionTitle } from '../components';
import { Api, Locale } from '../services';
import { spaces, sizes, colors } from '../style';
import { isMobile } from '../utils';
import { services_data } from '../helpers';
import { HighlightEnum } from '../enums';
import { GradeEnum } from '../enums/GradeEnum';
import { useHome } from '../hooks';

const { Title } = Typography;

const Home = () => {
  const { app, data, loading, popularSearchs } = useHome();

  return (
    <>
      <Helmet>
        <title>ონლაინ სავაჭრო ცენტრი | Happy Shop</title>
        <meta name="description" content="ონლაინ სავაჭრო ცენტრი" />
        <meta property="og:title" content="ონლაინ სავაჭრო ცენტრი" />
        <meta property="og:description" content="" />
      </Helmet>

      <TopNavigation />

      <Space direction="vertical" size={isMobile() ? sizes.md : sizes.xxl} style={{ display: 'flex', }}>
        {/* <Container xxl={24}> */}
          <Spin tip={Locale['loading']} height={400} spinning={loading}>
            <Carousel data={app.data?.slider}  />
          </Spin>
        {/* </Container> */}

        <Container>
          <Row justify={'center'} gutter={[0, sizes.xxxl]}>
            <Col xs={22} sm={24} md={24} lg={24} xl={24}>
              <Space direction="vertical" size={spaces.lg}>
                <SectionTitle>{Locale['Frequently searched']}</SectionTitle>
                <SliderDefault
                  navigation={true}
                  breakpoints={{
                    xs: 2,
                    sm: 3,
                    md: 4,
                    lg: 7,
                    xl: 7,
                  }}
                // className={'slider slider-max-width'}
                >
                  {popularSearchs?.map((item, key) => (
                    <CatalogCard key={key} item={item} />
                  ))}
                </SliderDefault>
              </Space>
            </Col>
            {!!data.products?.featured?.length && (
              <Col xs={22} sm={24} md={24} lg={24} xl={24}>
                <Space direction="vertical" size={spaces.lg}>
                  <SectionTitle link={`/products?highlight=${HighlightEnum.Featured.key}`}>{Locale['Recommended for myself']}</SectionTitle>
                  <Slider data={data.products?.featured} type="product" />
                </Space>
              </Col>
            )}
            {!!app.data?.banners?.Home_First?.length && (
              <Col xs={22} sm={24} md={24} lg={24} xl={24}>
                <Banner banner_data={app.data?.banners?.Home_First[Math.floor(Math.random() * app.data?.banners?.Home_First?.length)]} />
              </Col>
            )}
            {(!!data.users?.popular?.length || !!data.users?.featured?.length) && (
              <Col xs={22} sm={24} md={24} lg={24} xl={24}>
                <Space direction="vertical" size={spaces.lg}>
                  <SectionTitle link={`/profiles?highlight=${HighlightEnum.Featured.key}`}>{Locale['Discover popular brands']}</SectionTitle>
                  {/* <Slider data={data.users?.featured} type="profile" style={'circle'} /> */}
                  <Slider data={data.users?.popular} type="profile" style={'circle'} />
                </Space>
              </Col>
            )}
            <Col xs={22} sm={24} md={24} lg={24} xl={24}>
              <Space direction="vertical" size={spaces.md}>
                {/* <Title level={4}>{Locale['Supper VIP products']}</Title> */}
                {!!data.products?.popular?.length && (
                  <SectionTitle link={`/products?grade=${GradeEnum.Popular.key}`}>
                    <Space>
                      <Tag style={{ height: 40, width: 40 }} color={GradeEnum.Popular.color}>
                        {GradeEnum.Popular.img}
                      </Tag>
                      {Locale['Popular products']}
                    </Space>
                  </SectionTitle>
                )}
                <Row gutter={[spaces.xl, spaces.xl]}>
                  {!!app.data?.banners?.Home_Second?.length && (
                    <Col xs={24} md={24} lg={6} xl={6} xxl={6}>
                      <Banner banner_data={app.data?.banners?.Home_Second[Math.floor(Math.random() * app.data?.banners?.Home_Second?.length)]} height={'100%'} />
                    </Col>
                  )}
                  <Col xs={24} md={24} lg={18} xl={18} xxl={18}>
                    <Row
                      gutter={[
                        {
                          xs: 12,
                          sm: 12,
                          md: 24,
                          lg: 30,
                        },
                        spaces.xl,
                      ]}
                    >
                      {/* {data.products?.super_vip?.map((item, key) => ( */}
                      {data.products?.popular?.map((item, key) => (
                        <Col key={key} xs={12} md={12} lg={6} xl={6} xxl={6}>
                          <ProductCard item={item} height={isMobile() ? 160 : 300} />
                        </Col>
                      ))}
                    </Row>
                  </Col>
                </Row>
              </Space>
            </Col>
            {services_data && (
              <Col xs={22} sm={24} md={24} lg={24} xl={24}>
                <SliderDefault breakpoints={{ xs: 1, sm: 2, md: 3, lg: 3, xl: 3 }}>
                  {services_data?.map((item, key) => (
                    <Card key={key} style={{ paddingTop: spaces.lg }} hoverable={false} bordered={true} cover={<img height={160} style={{ objectFit: 'contain' }} src={item.img} />}>
                      <Card.Meta title={item.title} description={item.description} style={{ textAlign: 'center' }} />
                    </Card>
                  ))}
                </SliderDefault>
              </Col>
            )}
            {/* <Col xs={22} sm={24} md={24} lg={24} xl={24}>
              <Space direction="vertical" size={spaces.lg}>
                <Title level={4}>{Locale['VIP Pluse products']}</Title>
                <Slider data={data.products?.vip_pluse} type="product" />
              </Space>
            </Col> */}
            {/* <Col xs={22} sm={24} md={24} lg={24} xl={24}>
              <Space direction="vertical" size={spaces.lg}>
                <Title level={4}>{Locale['Popular products']}</Title>
                <Slider data={data.products?.popular} type="product" />
              </Space>
            </Col> */}
            {!!app.data?.banners?.Home_Third?.length && (
              <Col xs={22} sm={24} md={24} lg={24} xl={24}>
                <Banner banner_data={app.data?.banners?.Home_Third[Math.floor(Math.random() * app.data?.banners?.Home_Third.length)]} />
              </Col>
            )}
            {!!data.products?.new?.length && (
              <Col xs={22} sm={24} md={24} lg={24} xl={24}>
                <Space direction="vertical" size={spaces.lg}>
                  <SectionTitle link={`/products?highlight=${HighlightEnum.New.key}`}>{Locale['New products']}</SectionTitle>
                  <Slider data={data.products?.new} type="product" />
                </Space>
              </Col>
            )}
            {!!data.categories?.length && (
              <Col xs={22} sm={24} md={24} lg={24} xl={24}>
                <Space direction="vertical" size={spaces.lg}>
                  <SectionTitle>{Locale['Popular Categories']}</SectionTitle>
                  <Slider data={data.categories} type={'product-group'} />
                </Space>
              </Col>
            )}
            {!!data.products?.recently?.length && (
              <Col xs={22} sm={24} md={24} lg={24} xl={24}>
                <Space direction="vertical" size={spaces.lg}>
                  <SectionTitle>{Locale['Recently viewed products']}</SectionTitle>
                  <Slider data={data.products?.recently} type="product" />
                </Space>
              </Col>
            )}
          </Row>
        </Container>
      </Space>
    </>
  );
};

export default Home;
