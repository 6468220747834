const About = () => {
  return (
    <div>
      About
      <a href="/products">Home</a>
    </div>
  );
};

export default About;
