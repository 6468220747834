import { useState, useEffect } from 'react';
import { Link, useNavigate, useSearchParams } from 'react-router-dom';
import { Button, Result } from 'antd';

import { Locale } from '../../services';

const CheckoutResult = () => {
  const [search] = useSearchParams();
  const [status, setStatus] = useState();

  useEffect(() => {
    setStatus(Object.fromEntries(search)?.status);
  }, [search]);

  const buttons = [
    <Link to={`/user/orders`}>
      <Button type="primary">{Locale['Go to the orders page']}</Button>
    </Link>,
    <Link to={`/`}>
      <Button type="default">{Locale['Back to main page']}</Button>
    </Link>,
  ];

  return status == 'success' ? (
    <Result status="success" title={Locale['Payment completed successfully!']} subTitle={Locale['Thank you for using our service.']} extra={buttons} />
  ) : (
    <Result status="error" title={Locale['An error occurred during payment!']} subTitle={Locale['Please try again.']} extra={buttons} />
  );
};

export default CheckoutResult;
