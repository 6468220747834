import { Space, Typography } from 'antd';
import { CURRENCY, sell_currency } from '../helpers';

const { Text } = Typography;

export const ProductPriceHelper = ({ values }) => {
  let { price, price_expanse, price_sale, price_sale_expanse, type } = values || {};

  return (
    <Space>
      <Text delete={price_sale != '0.00' ? true : false}>{price_expanse + ' ' + sell_currency(type)}</Text>
      {price_sale != '0.00' && <Text type="danger">{price_sale_expanse + ' ' + CURRENCY}</Text>}
    </Space>
  );
};
