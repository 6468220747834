import React from 'react';
import { Link, createSearchParams } from 'react-router-dom';
import { Card, Badge, Typography } from 'antd';
import { GradeEnum } from '../../enums/GradeEnum';
import { spaces, sizes, colors } from '../../style';
import { CDN_URL } from '../../helpers';

const { Meta } = Card;
const { Text, Title } = Typography;

const CatalogCard = ({ item }) => {
  return (
    <>
      <Link
        to={`/products/?${createSearchParams({
          category: item.id,
        })}`}
      >
        <Card
          hoverable
          style={{ background: 'transparent', paddingTop: spaces.md }}
          bodyStyle={{ padding: 0, textAlign: 'center', justifyContent: 'center', display: 'flex' }}
          // bordered={false}
          cover={<img style={{ objectFit: 'cover', borderRadius: '50%', width: 100, margin: 'auto' }} height={100} src={CDN_URL + item?.img_cover} />}
        >
          <div style={{ width: 120, height: 50, marginTop: spaces.md, marginBottom: spaces.md }}>
            <Title level={5} style={{ marginBottom: 0 }}>
              {item.trans?.title}
            </Title>
          </div>
        </Card>
      </Link>
    </>
  );
};

export default CatalogCard;

{
  /* <Space key={key}>
      <Link to={`/products?category=${item.id}`}>
        <Button shape="round" size="large">
          <Space size={5}>
            <SearchIcon size={18} style={{ display: 'flex' }} />
            {item.trans?.title}
          </Space>
        </Button>
      </Link>
    </Space> */
}
