import React from 'react';
import { Link } from 'react-router-dom';
import { Card, Badge, Typography } from 'antd';

import { ProductTypeEnum } from '../../enums/ProductTypeEnum';
import { GradeEnum } from '../../enums/GradeEnum';
import { CDN_URL } from '../../helpers';

const { Meta } = Card;
const { Text } = Typography;

const ProductCardCircle = ({ item }) => {
  const BadgeRibbon = (props) =>
    item.grade == GradeEnum.Standart.key ? (
      props.children
    ) : (
      <Badge.Ribbon text={GradeEnum[item.grade]?.title} color={GradeEnum[item.grade]?.color}>
        {props.children}
      </Badge.Ribbon>
    );

  return (
    <>
      <Link to={`/products/details/${item.id}`}>
        <BadgeRibbon>
          <Card style={{ background: 'transparent' }} bodyStyle={{ paddingTop: 12, paddingRight: 0, paddingLeft: 0, paddingBottom: 0, textAlign: 'center' }} bordered={false} cover={<img style={{ objectFit: 'cover', borderRadius: '50%' }} height={212} src={CDN_URL + item?.img} />}>
            <Meta
              title={item.trans.company_name}
              description={
                <Text style={{ maxWidth: '100%' }} ellipsis={4}>
                  {item.trans.description_min}
                </Text>
              }
              style={{ marginBottom: 0 }}
            />
          </Card>
        </BadgeRibbon>
      </Link>
    </>
  );
};

export default ProductCardCircle;
