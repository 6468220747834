import { Button, Result, Typography } from 'antd';

import { Locale } from '../../services';

const { Title } = Typography;

const NotFoundPage = () => {
  return (
    <Result
      status="404"
      title="404"
      subTitle={
        <Title level={5} type="secondary">
          {Locale['Sorry, the page you are visiting does not exist.']}
        </Title>
      }
      // extra={<Button type="primary">Back Home</Button>}
    />
  );
};

export default NotFoundPage;
