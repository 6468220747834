import React, { useState, useEffect } from 'react';
import { Link, useNavigate, useLocation, createSearchParams } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
// import FacebookAuth from 'react-facebook-login';
import FacebookLogin from 'react-facebook-login/dist/facebook-login-render-props';

import { Form, Input, Modal, Steps, Button, Space, Typography, Divider, message } from 'antd';
import { Phone as PhoneIcon, Mail as MailIcon, Hash as HashIcon } from 'react-feather';

import { Api, Locale } from '../../services';
import { colors, spaces } from '../../style';
import { FB_ID, FB_SECRET } from '../../helpers';

import { setToken, setInfo, setLoginModal } from '../../redux/reducers/userSlice';
import { set as setTotalWishlist } from '../../redux/reducers/wishlistSlice';

import logo_icon from '../../assets/images/logo-icon.svg';

const { Text, Title } = Typography;

const LoginModal = () => {
  const api = new Api();

  const dispatch = useDispatch();

  const [loginForm] = Form.useForm();
  const [verifyForm] = Form.useForm();
  const [updateForm] = Form.useForm();

  const [loginButtonVisible, setLoginButtonVisible] = useState(false);
  const [currentLoginStep, setCurrentLoginStep] = useState(0);

  const { login_modal } = useSelector((state) => state.user);

  const signIn = (values) => {
    setLoginButtonVisible(true);

    api
      .authSignIn({ ...values })
      .then((response) => {
        const response_data = response.data;

        dispatch(setToken({ token: response_data?.token }));
        dispatch(setInfo({ info: response_data?.user }));
        dispatch(setTotalWishlist({ total: response_data?.user?.wishlists_count }));

        setLoginButtonVisible(false);

        if (response_data?.user?.trans?.title == '') {
          setCurrentLoginStep(4);
        } else {
          dispatch(setLoginModal({ visible: false }));
          window.location.reload();
        }
      })
      .catch((err) => message.error(Locale['The verification code is invalid! Please try again.']));

    setLoginButtonVisible(false);
    loginForm.resetFields();
  };

  const sendVerifyCode = (values) => {
    setLoginButtonVisible(true);

    api
      .authVerifyCode({ ...values })
      .then((response) => {
        message.success(response.data?.message);
        setCurrentLoginStep(1);
      })
      .catch((err) => message.error(err.response?.data?.message));

    setLoginButtonVisible(false);
    // verifyForm.resetFields();
  };

  // Sign in By Email
  const signInByEmail = (values) => {
    setLoginButtonVisible(true);

    api
      .authSignInByEmail({ ...values })
      .then((response) => {
        const response_data = response.data;

        dispatch(setToken({ token: response_data?.token }));
        dispatch(setInfo({ info: response_data?.user }));
        dispatch(setTotalWishlist({ total: response_data?.user?.wishlists_count }));

        setLoginButtonVisible(false);

        if (response_data?.user?.trans?.title == '') {
          setCurrentLoginStep(4);
        } else {
          dispatch(setLoginModal({ visible: false }));
          window.location.reload();
        }
      })
      .catch((err) => message.error(Locale['The verification code is invalid! Please try again.']));

    setLoginButtonVisible(false);
    loginForm.resetFields();
  };

  // Send Verify Code By Email
  const sendVerifyEmailCode = (values) => {
    setLoginButtonVisible(true);

    api
      .authVerifyEmailCode({ ...values })
      .then((response) => {
        message.success(response.data?.message);
        setCurrentLoginStep(3);
      })
      .catch((err) => message.error(err.response?.data?.message));

    setLoginButtonVisible(false);
    // verifyForm.resetFields();
  };

  const updateUserData = (values) => {
    api
      .updateUser({ ...values })
      .then((response) => {
        const response_data = response.data;
        message.success(response_data.message);
        dispatch(setInfo({ info: response_data.user }));
        dispatch(setLoginModal({ visible: false }));
        window.location.reload();
      })
      .catch((err) => {
        message.error(err?.response?.data?.message);
      });
  };

  const signInByFb = (values) => {
    api
      .authSignInByFb({ ...values })
      .then((response) => {
        const response_data = response.data;

        dispatch(setToken({ token: response_data?.token }));
        dispatch(setInfo({ info: response_data?.user }));
        dispatch(setTotalWishlist({ total: response_data?.user?.wishlists_count }));

        setLoginButtonVisible(false);

        if (response_data?.user?.trans?.title == null) {
          setCurrentLoginStep(2);
        } else {
          dispatch(setLoginModal({ visible: false }));
          window.location.reload();
        }
      })
      .catch((err) => message.error(Locale['Something went wrong! Please try again.']));
  };

  const loginSteps = [
    {
      title: 'Phone',
      content: (
        <>
          <Form
            form={verifyForm}
            onFinish={sendVerifyCode}
            onFinishFailed={() => {
              setTimeout(() => {
                setLoginButtonVisible(false);
              }, 800);
            }}
          >
            <Form.Item name="phone" rules={[{ required: true, message: Locale['Please specify mobile!'] }]}>
              <Input size="large" prefix={<PhoneIcon color={colors.gray} size={16} />} placeholder={Locale['mobile']} />
            </Form.Item>
            <Form.Item style={{ marginBottom: 0 }}>
              <Button type="primary" size={'large'} block={true} loading={loginButtonVisible} htmlType="submit">
                {Locale['Authorization']}
              </Button>
            </Form.Item>
            {/* <Divider plain>{Locale['or']}</Divider> */}
            {/* <FacebookAuth
              appId={FB_ID}
              autoLoad={false}
              fields="name,email,picture"
              callback={(e) => signInFacebook(e)}
              customProps={{
                styles: {
                  backgroundColor: 'red',
                  border: 'none',
                  color: 'white',
                  padding: '15px 32px',
                  textAlign: 'center',
                  textDecoration: 'none',
                  display: 'inline-block',
                  fontSize: '16px',
                  margin: '4px 2px',
                  cursor: 'pointer',
                },
              }}
              component={FacebookButton}
            /> */}
            {/* <FacebookLogin
              appId={FB_ID}
              autoLoad
              callback={(e) => signInByFb(e)}
              // redirectUri={window.location.hostname}
              onFailure={() => message.error(Locale['Something went wrong! Please try again.'])}
              render={(renderProps) => (
                <Button onClick={renderProps.onClick} style={{ backgroundColor: '#1b74e4', color: colors.white }} size={'large'} block={true} htmlType="button">
                  Facebook
                </Button>
              )}
            /> */}
            {/* <FacebookLogin appId={FB_ID} autoLoad={false} fields="name,email,picture" callback={(e) => signInFacebook(e)} /> */}
          </Form>
        </>
      ),
    },
    {
      title: 'Verify',
      content: (
        <>
          <Form
            form={loginForm}
            onFinish={signIn}
            onFinishFailed={() => {
              setTimeout(() => {
                setLoginButtonVisible(false);
              }, 800);
            }}
          >
            <Form.Item name="token" rules={[{ required: true, message: Locale['Please enter the verification code!'] }]}>
              <Input size="large" prefix={<HashIcon color={colors.gray} size={16} />} placeholder={Locale['Verification code']} />
            </Form.Item>
            <Form.Item style={{ marginBottom: 0 }}>
              <Button type="primary" size={'large'} block={true} loading={loginButtonVisible} htmlType="submit">
                {Locale['verification']}
              </Button>
            </Form.Item>
          </Form>
        </>
      ),
    },
    {
      title: 'Email',
      content: (
        <>
          <Form
            form={verifyForm}
            onFinish={sendVerifyEmailCode}
            onFinishFailed={() => {
              setTimeout(() => {
                setLoginButtonVisible(false);
              }, 800);
            }}
          >
            <Form.Item name="email" type={'email'} rules={[{ required: true, message: Locale['Please specify email!'] }]}>
              <Input size="large" prefix={<MailIcon color={colors.gray} size={16} />} placeholder={Locale['Email']} />
            </Form.Item>
            <Form.Item style={{ marginBottom: 0 }}>
              <Button type="primary" size={'large'} block={true} loading={loginButtonVisible} htmlType="submit">
                {Locale['Authorization']}
              </Button>
            </Form.Item>
          </Form>
        </>
      ),
    },
    {
      title: 'Verify By Email',
      content: (
        <>
          <Form
            form={loginForm}
            onFinish={signInByEmail}
            onFinishFailed={() => {
              setTimeout(() => {
                setLoginButtonVisible(false);
              }, 800);
            }}
          >
            <Form.Item name="token" rules={[{ required: true, message: Locale['Please enter the verification code!'] }]}>
              <Input size="large" prefix={<HashIcon color={colors.gray} size={16} />} placeholder={Locale['Verification code']} />
            </Form.Item>
            <Form.Item style={{ marginBottom: 0 }}>
              <Button type="primary" size={'large'} block={true} loading={loginButtonVisible} htmlType="submit">
                {Locale['verification']}
              </Button>
            </Form.Item>
          </Form>
        </>
      ),
    },
    {
      title: 'Finish',
      content: (
        <>
          <Form
            layout={'vertical'}
            form={updateForm}
            onFinish={updateUserData}
            onFinishFailed={() => {
              setTimeout(() => {
                setLoginButtonVisible(false);
              }, 800);
            }}
          >
            <Form.Item label={Locale['name']} name="name" rules={[{ required: true, message: Locale['Please provide a name!'] }]}>
              <Input size={'large'} showCount maxLength={20} />
            </Form.Item>
            <Form.Item label={Locale['surname']} name="surname" rules={[{ required: true, message: Locale['Please enter your last name!'] }]}>
              <Input size={'large'} showCount maxLength={20} />
            </Form.Item>
            <Form.Item style={{ marginBottom: 0 }}>
              <Button type="primary" size={'large'} block={true} loading={loginButtonVisible} htmlType="submit">
                {Locale['verification']}
              </Button>
            </Form.Item>
          </Form>
        </>
      ),
    },
  ];

  const changeStep = (step) => {
    setCurrentLoginStep(step);
  };

  return (
    <>
      <Modal open={login_modal?.visible} onCancel={() => dispatch(setLoginModal({ visible: false }))} footer={null} bodyStyle={{ padding: spaces.xxl }} width={450}>
        <Space direction="vertical" size={spaces.lg}>
          <img src={logo_icon} />
          <div>
            <Title level={4}>
              {Locale['Enter to enter']} <br /> {Locale['Your phone number']}
            </Title>
            <Text>{Locale['The message will be sent to the specified mobile number.']}</Text>
          </div>
          <Space size={'small'} direction={'vertical'}>
            {/* <Text>{Locale['Please Choose sign up method:']}</Text> */}
            <Space>
              <Text>{Locale['Sign in']}</Text>
              <Button size={'small'} shape={'round'} onClick={() => changeStep(0)} type={loginSteps[currentLoginStep].title == 'Phone' ? 'primary' : 'default'}>
                {Locale['By Phone']}
              </Button>
              <Button size={'small'} shape={'round'} onClick={() => changeStep(2)} type={loginSteps[currentLoginStep].title == 'Email' ? 'primary' : 'default'}>
                {Locale['By Email']}
              </Button>
            </Space>
          </Space>
          {/* <Steps current={currentLoginStep} size={'small'}>
            {loginSteps.map((item) => (
              <Step key={item.title} />
            ))}
          </Steps> */}
          <div>{loginSteps[currentLoginStep].content}</div>
        </Space>
      </Modal>
    </>
  );
};

export default LoginModal;
