import { Row, Col } from 'antd';

export const Container = (props) => {
  return (
    <div style={{ backgroundColor: props.bg }}>
      <Row justify={props.justify} gutter={props.gutter} style={{ ...props.style, paddingTop: props.spaces, paddingBottom: props.spaces }}>
        <Col xs={props.xs} md={props.md} lg={props.lg} xl={props.xl} xxl={props.xxl}>
          {props.children}
        </Col>
      </Row>
    </div>
  );
};

Container.defaultProps = {
  bg: {},
  style: {},
  spaces: 0,
  gutter: [0, 0],
  justify: 'center',
  xs: 24,
  md: 24,
  lg: 22,
  xl: 22,
  xxl: 15,
};

export default Container;
