import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  wishlist_items: [],
  total: 0,
};

const wishlistSlice = createSlice({
  name: 'wishlist',
  initialState,
  reducers: {
    set: (state, { payload }) => {
      state.total = payload.total;
      state.wishlist_items = [];
    },

    clear: (state) => {
      state.total = 0;
      state.wishlist_items = [];
    },

    addItem: (state, { payload }) => {
      const item_id = payload.id;

      if (state.wishlist_items.includes(item_id)) {
        state.wishlist_items = state.wishlist_items.filter((item) => item !== item_id);
        return;
      }

      state.wishlist_items = [...state.wishlist_items, payload.id];
    },

    removeItem: (state, action) => {
      const item_id = action.payload;
      state.wishlist_items = state.wishlist_items.filter((item) => item.id !== item_id);
    },
  },
});

export const { set, clear, addItem, removeItem } = wishlistSlice.actions;

export default wishlistSlice.reducer;
