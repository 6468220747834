import React, { useState, useEffect } from 'react';
import { Link, createSearchParams } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';

import { Row, Col, Menu, Space, Drawer, Typography, Button, Dropdown, Tag } from 'antd';
import { Grid as GridIcon, List as ListIcon, Gift as GiftIcon } from 'react-feather';

import { Api, Locale } from '../../services';
import { CDN_URL, primary_menu } from '../../helpers';
import { LanguageDropdown } from '../../components';

import { colors, spaces, fonts, media } from '../../style';
import { Container } from '..';
import { isMobile } from '../../utils';

const { Text } = Typography;

const TopNavigation = () => {
  const { loading, data } = useSelector((state) => state.app);

  return (
    <>
      <Container xs={22} bg={colors.primary} spaces={isMobile() && spaces.sm}>
        <Row justify={'space-between'} gutter={[16, 16]} align={'middle'}>
          <Col flex="auto" style={{ display: 'flex', alignItems: 'center' }}>
            <Space style={{ height: !isMobile() && 50 }} size={spaces.md}>
              {primary_menu().map((item, key) =>
                item.color ? (
                  <Link key={key} to={item.url} className="system-font">
                    <Tag icon={item.icon} color={item.color} className={'ant-tag-large'}>
                      {item.title}
                    </Tag>
                  </Link>
                ) : (
                  <Link key={key} to={item.url} className="system-font" style={{ color: colors.white, fontWeight: 500 }}>
                    {item.title}
                  </Link>
                ),
              )}
            </Space>
          </Col>
          {!isMobile() && (
            <Col flex="none">
              <LanguageDropdown />
            </Col>
          )}
        </Row>
      </Container>
    </>
  );
};

export default TopNavigation;
