import React, { useState, useEffect, useCallback } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';

import { Space, Typography, Divider, Button, Modal, Form, Input, Select, InputNumber, message } from 'antd';
import {
  PhoneCall as PhoneCallIcon,
  Home as HomeIcon,
  List as ListIcon,
  PlusCircle as PlusCircleIcon,
  Mail as MailIcon,
  MessageSquare as MessageSquareIcon,
  Inbox as InboxIcon,
  Package as PackageIcon,
  Settings as SettingsIcon,
  LogOut as LogOutIcon,
  Briefcase as BriefcaseIcon,
  Database as DatabaseIcon,
} from 'react-feather';

import { Api, Locale } from '../../services';
import { store_profile_url, sell_currency } from '../../helpers';
import { BankEnum } from '../../enums';
import { isMobile } from '../../utils';
import { setToken, setInfo } from '../../redux/reducers/userSlice';
import { colors, spaces, borders, sizes, shadows } from '../../style';

import UserHeader from './UserHeader';
import UserCompanyEditModal from './UserCompanyEditModal';

const { Text } = Typography;
const { Option } = Select;
const { TextArea } = Input;

const api = new Api();

const UserMenu = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  // const [formCompany] = Form.useForm();
  // const [formValuesCompany, setFormValuesCompany] = useState([]);

  // const [loadingBussinesModal, setLoadingBussinesModal] = useState(false);
  // const [visibleBussinesModal, setVisibleBussinesModal] = useState(false);

  const user = useSelector((state) => state.user);

  const createProductAndRedirect = useCallback(() => {
    api
      .createUserProduct()
      .then((response) => {
        navigate(`/user/products/edit/${response.data?.product?.id}`, { replace: true });
      })
      .catch((err) => console.log(err));
  });

  const logout = () => {
    dispatch(
      setToken({
        token: null,
      }),
    );
  };

  // useEffect(() => {
  //   const user_company_values = {
  //     company_email: user?.info?.company_email,
  //     company_phone: user?.info?.company_phone,
  //     // company_type: user?.info?.company_type,
  //     company_name: user?.info?.trans?.company_name,
  //     company_address: user?.info?.trans?.company_address,
  //     company_working_hours: user?.info?.trans?.company_working_hours,

  //     bank: user?.info?.bank,
  //     bank_iban: user?.info?.bank_iban,
  //   };
  //   formCompany.setFieldsValue(user_company_values);
  //   setFormValuesCompany(user_company_values);
  // }, []);

  // const saveCompanyData = (formValues) => {
  //   api
  //     .updateCompany(formValues)
  //     .then((response) => {
  //       const response_data = response.data;
  //       message.success(response_data.message);

  //       dispatch(
  //         setInfo({
  //           info: response_data.user,
  //         }),
  //       );

  //       setVisibleBussinesModal(false);
  //     })
  //     .catch((err) => {
  //       message.error(err?.response?.data?.message);
  //       // notification['error']({
  //       //   message: err?.response?.data?.message,
  //       //   description: Object.values(err?.response?.data?.errors).map((item) => (`${item} \n`)),
  //       // });
  //     });
  //   // setVisibleBussinesModal(false);
  // };

  // useEffect(() => {
  //   const url = new URL(window.location.href);
  //   const modal_value = url.searchParams.get('modal');

  //   if (modal_value == 'OpenShop') {
  //     setVisibleBussinesModal(true);
  //   }
  //   console.log(url);
  // }, []);

  // const onValuesChange = (_, values) => {
  //   setFormValuesCompany(prevState => ({ ...prevState, ...values }))
  // };

  return (
    <>
      <Space direction="vertical" size={spaces.lg} style={{ display: 'flex' }}>
        <UserHeader />

        {isMobile() && (
          <Button onClick={() => navigate(store_profile_url)} style={{ width: '100%' }}>
            {Locale['Happy Coins']}{' '}
            <Text
              style={{
                color: colors.secondary,
              }}
              strong
            >
              {user?.info?.store_coins?.coins} {sell_currency('coin')}
            </Text>
          </Button>
        )}

        <Space direction="vertical" size={spaces.sm}>
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <MailIcon size={sizes.sm} style={{ marginRight: spaces.sm }} />
            <Text style={{ fontWeight: 500 }}>{user?.info?.public?.email}</Text>
          </div>
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <PhoneCallIcon size={sizes.sm} style={{ marginRight: spaces.sm }} />
            <Text style={{ fontWeight: 500 }}>{user?.info?.public?.phone}</Text>
          </div>
        </Space>
        {/* <Button style={{ width: '100%' }} type={'primary'} size={'large'} onClick={() => setVisibleBussinesModal(true)}>
          <BriefcaseIcon size={sizes.sm} style={{ marginRight: spaces.sm }} />
          {user?.info?.type == 'Company' ? Locale['my company'] : Locale['Open a shop']}
        </Button> */}
        <UserCompanyEditModal />
        <Divider style={{ margin: 0 }} />
        <Space direction="vertical" size={spaces.md}>
          <Text type="secondary" strong={true}>
            <Link to={'/user'} style={{ display: 'flex', alignItems: 'center' }}>
              <HomeIcon size={18} style={{ marginRight: spaces.md }} />
              {Locale['my profile']}
            </Link>
          </Text>
          <Text strong={true}>
            <Link to={'/user/products'} style={{ display: 'flex', alignItems: 'center' }}>
              <ListIcon size={18} style={{ marginRight: spaces.md }} />
              {Locale['statements']}
            </Link>
          </Text>
          <Text strong={true}>
            <Link
              to={() => {
                return;
              }}
              onClick={createProductAndRedirect}
              style={{ display: 'flex', alignItems: 'center' }}
            >
              <PlusCircleIcon size={18} style={{ marginRight: spaces.md }} />
              {Locale['Add a statement']}
            </Link>
          </Text>
          <Text strong={true}>
            <Link to={'/user/orders'} style={{ display: 'flex', alignItems: 'center' }}>
              <InboxIcon size={18} style={{ marginRight: spaces.md }} />
              {Locale['Online orders']}
            </Link>
          </Text>
          <Text strong={true}>
            <Link to={'/user/sales'} style={{ display: 'flex', alignItems: 'center' }}>
              <PackageIcon size={18} style={{ marginRight: spaces.md }} />
              {Locale['Online sales']}
            </Link>
          </Text>
          <Text strong={true}>
            <Link to={'/user/coins'} style={{ display: 'flex', alignItems: 'center' }}>
              <DatabaseIcon size={18} style={{ marginRight: spaces.md }} />
              {Locale['accumulated goods']}
            </Link>
          </Text>
          {/* <Text strong={true}>
            <Link to={'/user/products'} style={{ display: 'flex', 'alignItems': 'center' }}>
              <MessageSquareIcon size={18} style={{ marginRight: spaces.md }} />
              {Locale['messages']}
            </Link>
          </Text> */}
        </Space>
        <Divider style={{ margin: 0 }} />
        <Space direction="vertical" size={spaces.md}>
          <Text strong={true}>
            <Link to={'/user-edit'} style={{ display: 'flex', alignItems: 'center' }}>
              <SettingsIcon size={18} style={{ marginRight: spaces.md }} />
              {Locale['Edit profile']}
            </Link>
          </Text>
          <Text strong={true}>
            <Link onClick={logout} to={'/'} style={{ display: 'flex', alignItems: 'center' }}>
              <LogOutIcon size={18} style={{ marginRight: spaces.md }} />
              {Locale['speech']}
            </Link>
          </Text>
        </Space>
      </Space>
      {/* <Modal
        open={visibleBussinesModal}
        title={Locale['Open a shop']}
        okText={Locale['to remember']}
        cancelText={Locale['Cancellation']}
        onCancel={() => setVisibleBussinesModal(false)}
        onOk={() => {
          formCompany
            .validateFields()
            .then((values) => {
              // formCompany.resetFields();
              saveCompanyData(values);
            })
            .catch((err) => {
              message.error(err?.response?.data?.message);
            });
        }}
      >
        <Form form={formCompany} layout="vertical">
          <Form.Item
            name="company_name"
            label={Locale['Company name']}
            rules={[
              {
                required: true,
                message: Locale['Please specify the company name!'],
              },
            ]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            name="company_email"
            label={Locale['Company e-mail mail']}
            // style={{ marginBottom: sizes.xxl }}
            rules={[
              {
                required: false,
                message: Locale['Please indicate the company e-mail address. Mail!'],
              },
            ]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            label={Locale['Company mobile']}
            name="company_phone"
            rules={[
              {
                required: true,
                message: Locale['Please indicate the company mobile!'],
              },
            ]}
          >
            <InputNumber min={0} style={{ width: '100%' }} />
          </Form.Item>
          <Form.Item
            name="company_address"
            label={Locale['Company address']}
            rules={[
              {
                required: true,
                message: Locale['Please specify the address of the company!'],
              },
            ]}
          >
            <TextArea
              autoSize={{
                minRows: 2,
                maxRows: 6,
              }}
            />
          </Form.Item>
          <Form.Item
            name="company_working_hours"
            label={Locale['Company wokring hours']}
            rules={[
              {
                required: true,
                message: Locale['Please enter Company wokring hours!'],
              },
            ]}
          >
            <Input />
          </Form.Item>
          <Divider />
          <Form.Item
            name="bank"
            label={Locale['Choose a bank']}
            hasFeedback
            rules={[
              {
                required: true,
                message: Locale['Please choose a bank!'],
              },
            ]}
          >
            <Select placeholder={Locale['Please select a bank']}>
              {Object.values(BankEnum).map((item, key) => (
                <Option key={key} value={item.key}>
                  {item.title}
                </Option>
              ))}
            </Select>
          </Form.Item>
          <Form.Item
            name="bank_iban"
            label={Locale['Bank account number']}
            rules={[
              {
                required: true,
                message: Locale['Please indicate the bank account number!'],
              },
            ]}
          >
            <Input type="textarea" />
          </Form.Item>
        </Form>
      </Modal> */}
    </>
  );
};

export default UserMenu;
