export const ProductTypeEnum = Object.freeze({
  Sell: {
    key: 'Sell',
    title: 'Sell',
    color: '',
  },
  // "Buy": {
  //   "key": "Buy",
  //   "title": "Buy",
  //   "color": "",
  // },
  Gift: {
    key: 'Gift',
    title: 'Gift',
    color: 'green',
  },
  // "Exchange": {
  //   "key": "Exchange",
  //   "title": "Exchange",
  //   "color": "blue",
  // },
});
