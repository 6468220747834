import React, { useState, useEffect } from 'react';
import { Link, createSearchParams } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';

import { Row, Col, Menu, Space, Drawer, Typography, Button, Dropdown, Tag } from 'antd';
import { Grid as GridIcon, List as ListIcon, Gift as GiftIcon } from 'react-feather';

import { Api, Locale } from '../../services';
import { CDN_URL, primary_menu } from '../../helpers';

import { colors, spaces, fonts, media } from '../../style';
import { Container } from '../../components';
import { isMobile } from '../../utils';

const { Text } = Typography;

const Category = () => {
  const { loading, data } = useSelector((state) => state.app);
  const [dropdownVisible, setDropdownVisible] = useState(false);

  useEffect(() => {
    !isMobile() && setDropdownVisible(true);
  }, []);

  return (
    <>
      <Container xs={22} bg={colors.light} spaces={isMobile() && spaces.sm}>
        <Row justify={isMobile() && 'space-between'} gutter={[16, 16]}>
          <Col span={isMobile() ? null : 5}>
            <Dropdown
              open={dropdownVisible}
              trigger={['click']}
              overlayStyle={{ zIndex: 9 }}
              overlay={
                <Menu
                  style={{
                    marginTop: -4,
                    borderTopLeftRadius: 0,
                    borderTopRightRadius: 0,
                    padding: spaces.sm,
                  }}
                >
                  {data?.category_menu?.map((item, key) => (
                    <Menu.Item key={key}>
                      <Link
                        to={`/products/?${createSearchParams({
                          category: item.id,
                        })}`}
                      >
                        <Space>
                          <img width="18" height="18" src={CDN_URL + item.img} />
                          <Text strong={true}>{item.trans?.title}</Text>
                        </Space>
                      </Link>
                    </Menu.Item>
                  ))}
                </Menu>
              }
            >
              <Button
                // className="uppercase"
                onClick={() => setDropdownVisible(!dropdownVisible)}
                style={isMobile() ? { justifyContent: 'start', padding: 0 } : { justifyContent: 'start', borderRadius: 0, border: 0 }}
                type={isMobile() ? 'link' : 'primary'}
                block={true}
                size={'large'}
              >
                <GridIcon size={18} style={{ marginRight: spaces.xs }} />
                {Locale['categories']}
              </Button>
            </Dropdown>
          </Col>
          {!isMobile() && (
            <Col span={isMobile() ? null : 15} style={{ display: 'flex', alignItems: 'center' }}>
              <Space style={{ height: 50 }} size={spaces.md}>
                {primary_menu().map((item, key) =>
                  item.color ? (
                    <Link key={key} to={item.url}>
                      <Tag icon={item.icon} color={item.color} className={'ant-tag-large'}>
                        {item.title}
                      </Tag>
                    </Link>
                  ) : (
                    <Link key={key} to={item.url} style={{ color: item.color, fontWeight: 500 }}>
                      {item.title}
                    </Link>
                  ),
                )}
              </Space>
            </Col>
          )}
        </Row>
      </Container>
    </>
  );
};

export default Category;
