import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation } from 'swiper';

import ProductCard, { ProductCardCircle, ProductCardGroup } from '../product-card';
import ProfileCard, { ProfileCardCircle } from '../profile-card';

const Slider = ({ data, type = '', style = '' }) => {
  const SwiperBody = (item) => {
    switch (type) {
      case 'profile':
        return style == 'circle' ? <ProfileCardCircle item={item} /> : <ProfileCard item={item} />;
      case 'product-group':
        return <ProductCardGroup item={item} />;
      case 'product':
        return style == 'circle' ? <ProductCardCircle item={item} /> : <ProductCard item={item} />;
      default:
        return <ProductCard item={item} />;
    }
  };

  const SwiperBreakpoints = () => {
    switch (type) {
      // case 'circle': return {};
      case 'product-group':
        return {
          0: {
            slidesPerView: 1,
          },
          768: {
            slidesPerView: 1,
          },
          992: {
            slidesPerView: 2,
          },
          1200: {
            slidesPerView: 3,
            spaceBetween: 20,
          },
          1600: {
            slidesPerView: 4,
            spaceBetween: 30,
          },
        };
      default:
        return {
          0: {
            slidesPerView: 2,
            spaceBetween: 12,
          },
          768: {
            slidesPerView: 3,
            spaceBetween: 16,
          },
          992: {
            slidesPerView: 4,
            spaceBetween: 16,
          },
          1200: {
            slidesPerView: 5,
            spaceBetween: 24,
          },
          1600: {
            slidesPerView: 6,
            spaceBetween: 30,
          },
        };
    }
  };

  // type?: 'horizontal' | 'vertical'

  return (
    <>
      <div className="slider">
        <Swiper
          style={{ marginLeft: -9, marginRight: -9, paddingLeft: 9, paddingRight: 9 }}
          modules={[Navigation]}
          navigation
          // spaceBetween={30}
          slidesPerView={8}
          breakpoints={{ ...SwiperBreakpoints() }}
        >
          {data?.map((item, key) => (
            <SwiperSlide key={key}>{SwiperBody(item)}</SwiperSlide>
          ))}
        </Swiper>
      </div>
    </>
  );
};

export default Slider;
