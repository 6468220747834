import React, { useState, useEffect, useCallback } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';

import { Space, Typography, Divider, Button, Modal, Form, Input, Select, InputNumber, message } from 'antd';

import { Api, Locale } from '../../services';
import { store_profile_url, sell_currency } from '../../helpers';
import { BankEnum } from '../../enums';
import { isMobile } from '../../utils';
import { setToken, setInfo } from '../../redux/reducers/userSlice';
import { colors, spaces, borders, sizes, shadows } from '../../style';
import { Briefcase as BriefcaseIcon } from 'react-feather';

const { Text } = Typography;
const { Option } = Select;
const { TextArea } = Input;

const api = new Api();

const UserCompanyEditModal = (props) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [formCompany] = Form.useForm();
  const [formValuesCompany, setFormValuesCompany] = useState([]);

  const [loadingBussinesModal, setLoadingBussinesModal] = useState(false);
  const [visibleBussinesModal, setVisibleBussinesModal] = useState(false);

  const user = useSelector((state) => state.user);

  useEffect(() => {
    const user_company_values = {
      company_email: user?.info?.company_email,
      company_phone: user?.info?.company_phone,
      // company_type: user?.info?.company_type,
      company_name: user?.info?.trans?.company_name,
      company_address: user?.info?.trans?.company_address,
      company_working_hours: user?.info?.trans?.company_working_hours,

      bank: user?.info?.bank,
      bank_iban: user?.info?.bank_iban,
    };
    formCompany.setFieldsValue(user_company_values);
    setFormValuesCompany(user_company_values);
  }, []);

  const saveCompanyData = (formValues) => {
    api
      .updateCompany(formValues)
      .then((response) => {
        const response_data = response.data;
        message.success(response_data.message);

        dispatch(
          setInfo({
            info: response_data.user,
          }),
        );

        setVisibleBussinesModal(false);
      })
      .catch((err) => {
        message.error(err?.response?.data?.message);
        // notification['error']({
        //   message: err?.response?.data?.message,
        //   description: Object.values(err?.response?.data?.errors).map((item) => (`${item} \n`)),
        // });
      });
    // setVisibleBussinesModal(false);
  };

  useEffect(() => {
    const url = new URL(window.location.href);
    const modal_value = url.searchParams.get('modal');
    if (modal_value == 'OpenShop') {
      setVisibleBussinesModal(true);
    }
  }, []);

  return (
    <>
      <Button style={{ width: '100%' }} type={'primary'} size={props.button_size} onClick={() => setVisibleBussinesModal(true)}>
        <BriefcaseIcon size={sizes.sm} />
        {props.button_size == 'large' && (user?.info?.type == 'Company' ? Locale['my company'] : Locale['Open a shop'])}
      </Button>
      <Modal
        style={
          isMobile() && {
            top: 20,
          }
        }
        open={visibleBussinesModal}
        title={Locale['Open a shop']}
        okText={Locale['to remember']}
        cancelText={Locale['Cancellation']}
        onCancel={() => setVisibleBussinesModal(false)}
        onOk={() => {
          formCompany
            .validateFields()
            .then((values) => {
              // formCompany.resetFields();
              saveCompanyData(values);
            })
            .catch((err) => {
              message.error(err?.response?.data?.message);
            });
        }}
      >
        <Form form={formCompany} layout="vertical">
          <Form.Item
            name="company_name"
            label={Locale['Company name']}
            rules={[
              {
                required: true,
                message: Locale['Please specify the company name!'],
              },
            ]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            name="company_email"
            label={Locale['Company e-mail mail']}
            // style={{ marginBottom: sizes.xxl }}
            rules={[
              {
                required: false,
                message: Locale['Please indicate the company e-mail address. Mail!'],
              },
            ]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            label={Locale['Company mobile']}
            name="company_phone"
            rules={[
              {
                required: true,
                message: Locale['Please indicate the company mobile!'],
              },
            ]}
          >
            <InputNumber min={0} style={{ width: '100%' }} />
          </Form.Item>
          <Form.Item
            name="company_address"
            label={Locale['Company address']}
            rules={[
              {
                required: true,
                message: Locale['Please specify the address of the company!'],
              },
            ]}
          >
            <TextArea
              autoSize={{
                minRows: 2,
                maxRows: 6,
              }}
            />
          </Form.Item>
          <Form.Item
            name="company_working_hours"
            label={Locale['Company wokring hours']}
            rules={[
              {
                required: true,
                message: Locale['Please enter Company wokring hours!'],
              },
            ]}
          >
            <Input />
          </Form.Item>
          <Divider />
          <Form.Item
            name="bank"
            label={Locale['Choose a bank']}
            hasFeedback
            rules={[
              {
                required: true,
                message: Locale['Please choose a bank!'],
              },
            ]}
          >
            <Select placeholder={Locale['Please select a bank']}>
              {Object.values(BankEnum).map((item, key) => (
                <Option key={key} value={item.key}>
                  {item.title}
                </Option>
              ))}
            </Select>
          </Form.Item>
          <Form.Item
            name="bank_iban"
            label={Locale['Bank account number']}
            rules={[
              {
                required: true,
                message: Locale['Please indicate the bank account number!'],
              },
            ]}
          >
            <Input type="textarea" />
          </Form.Item>
        </Form>
      </Modal>
    </>
  );
};

UserCompanyEditModal.defaultProps = {
  button_size: 'large', // small, large
};

export default UserCompanyEditModal;
