import { Link } from 'react-router-dom';
import { Typography, Space, Button } from 'antd';
import { ArrowRight as ArrowRightIcon } from 'react-feather';
import { colors } from '../../style';
import { Locale } from '../../services';
import { isMobile } from '../../utils';

const { Title } = Typography;

const SectionTitle = (props) => {
  return (
    <>
      <Space style={{ justifyContent: 'space-between' }}>
        <Title className="uppercase" level={props.level} style={{ marginBottom: 0 }}>
          {props.children}
        </Title>
        {props.link && (
          <Link style={{ color: colors.gray }} to={props.link}>
            <Button type={'link'}>
              {Locale['see more']}
              <ArrowRightIcon size={16} />
            </Button>
          </Link>
        )}
      </Space>
    </>
  );
};

SectionTitle.defaultProps = {
  level: isMobile() ? 5 : 4,
  link: null,
};

export default SectionTitle;
