import { React, useEffect, useContext } from 'react';
import { Provider } from 'react-redux';

import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/free-mode';
import 'swiper/css/thumbs';

import store from '../redux/store';
import '../assets/style/app.less';
import App from './App';

function Core() {
  return (
    <Provider store={store}>
      <App />
    </Provider>
  );
}

export default Core;
