import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { message } from 'antd';

import { Locale } from '../services';
import { addItem, removeItem } from '../redux/reducers/basketSlice';

const useBasket = (id = 0) => {
  const dispatch = useDispatch();
  const { basket } = useSelector((state) => state);
  const [hasItem, setHasItem] = useState(false);

  const check = () => {
    setHasItem(id != 0 && basket?.data?.length > 0 && basket?.data?.some((item) => item.id == id));
  };

  const add = (qty = 1, company_id) => {
    dispatch(addItem({ id, qty, company_id }));
    check();
    message.success(Locale['Cart successfully updated.']);
  };

  const remove = () => {
    dispatch(removeItem({ id }));
  };

  useEffect(() => {
    check();
  }, [basket]);

  return {
    data: basket,
    hasItem,
    add,
    remove,
  };
};

export default useBasket;
