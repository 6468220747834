import React, { useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { Row, Col, Space, Avatar, Typography, Divider, Statistic, Modal, Spin, List, Table, Tag, Button, Tooltip, Popconfirm } from 'antd';
import { Navigation as NavigationIcon, PhoneCall as PhoneCallIcon, Home as HomeIcon, Star as StarIcon, RefreshCcw as RefreshCcwIcon, Trash2 as Trash2Icon, Edit2 as Edit2Icon } from 'react-feather';

import { Api, Locale } from '../../services';
import { CDN_URL, CURRENCY, sell_currency } from '../../helpers';
import { Container, UserMenu, UserHeader } from '../../components';
import { colors, spaces, borders, shadows } from '../../style';
import { isMobile } from '../../utils';

const { Title, Text } = Typography;

const UserOrders = () => {
  const navigate = useNavigate();

  const [orders, setOrders] = useState([]);
  const [loading, setLoading] = useState(true);

  const api = new Api();

  useEffect(() => {
    setLoading(true);

    api
      .getUserOrders()
      .then((response) => {
        setOrders(response.data?.orders);
      })
      .catch((err) => console.log(err));

    setLoading(false);
  }, []);

  const columns = [
    Table.EXPAND_COLUMN,
    {
      title: '#',
      dataIndex: 'id',
      key: 'id',
    },
    {
      title: Locale['address'],
      dataIndex: 'delivery_address',
      render: (delivery_address) => <Text strong={true}>{delivery_address}</Text>,
    },
    {
      title: Locale['the price'],
      dataIndex: 'total',
      // width: 100,
      sorter: (a, b) => a.price - b.price,
      render: (_, item) => (
        <Text style={{ color: colors.secondary }} strong={true}>
          {item?.total} {sell_currency(item.target)}
        </Text>
      ),
    },
    {
      title: Locale['payment date'],
      dataIndex: 'date',
      render: (date) => <Text strong={true}>{date}</Text>,
    },
    {
      title: Locale['Status'],
      dataIndex: 'status',
      key: 'status',
      render: (_, { status }) => {
        return (
          <Tag color={status == 'Success' ? 'green' : status == 'Refunded' ? 'blue' : status == 'Error' ? 'red' : 'gray'} key={status}>
            {Locale[status]}
          </Tag>
        );
      },
    },
    // {
    //   // title: 'მოქმედება',
    //   key: 'action',
    //   render: (_, item) => (
    //     <Space size="middle">
    //       <Button onClick={() => { return navigate(`/user/products/edit/${item.id}`, { replace: true }) }} type={'primary'} shape={'circle'} icon={(<Edit2Icon size={18} />)}></Button>
    //       <Popconfirm title="ნამდვილად გსურთ წაშლა？" okText="წაშლა" showCancel={false} cancelText="გაუქმება" onConfirm={() => deleteProduct(item.id)}>
    //         <Button size='small' type={'link'} shape={'circle'} icon={(<Trash2Icon size={18} />)} danger></Button>
    //       </Popconfirm>
    //     </Space>
    //   ),
    // },
  ];

  return (
    <>
      <Container xs={22} spaces={spaces.xl} style={{ backgroundColor: colors.light }}>
        <Row gutter={[spaces.lg, spaces.lg]}>
          <Col xs={24} md={24} lg={7} xl={7} xxl={6}>
            <Space direction="vertical" size={spaces.lg} style={{ display: 'flex', backgroundColor: colors.white, boxShadow: shadows.sm, padding: spaces.lg, borderRadius: borders.sm }}>
              {isMobile() ? <UserHeader /> : <UserMenu />}
            </Space>
          </Col>
          <Col xs={24} md={24} lg={17} xl={17} xxl={18}>
            <Spin tip={Locale['loading']} spinning={loading}>
              <Table
                rowKey={(record) => record.id}
                columns={columns}
                expandable={{
                  expandedRowRender: (record) =>
                    record?.order_data?.map((record_item, record_key) => {
                      let item = record_item?.data;
                      return (
                        // record_item?.data?.map((item, key) => (
                        <Row justify={'space-between'} align={'middle'} key={record_key} gutter={[spaces.lg, spaces.lg]}>
                          <Col>
                            <img style={{ objectFit: 'cover' }} height={'60'} src={CDN_URL + item?.img} />
                          </Col>
                          <Col span={8}>
                            <Text strong>{item?.trans?.title}</Text>
                          </Col>
                          <Col>
                            <Text>{Locale['quantity']}: </Text>
                            <Text strong>{record_item.qty}</Text>
                          </Col>
                          <Col>
                            <Text>{Locale['Unit price']}: </Text>
                            <Text style={{ color: colors.secondary }} strong>
                              {record_item.unit_price} {sell_currency(record_item.target)}
                            </Text>
                          </Col>
                          <Col>
                            <Text>{Locale['Total price']}: </Text>
                            <Text style={{ color: colors.secondary }} strong>
                              {record_item.line_total} {sell_currency(record_item.target)}
                            </Text>
                          </Col>
                        </Row>
                      )
                    }
                      // )),
                    ),
                  rowExpandable: (record) => record?.order_data?.length > 0,
                  expandRowByClick: true,
                }}
                dataSource={orders?.data}
              />
            </Spin>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default UserOrders;
