import { configureStore, compose, applyMiddleware } from '@reduxjs/toolkit';
import thunkMiddleware from 'redux-thunk';

import appReducer from './reducers/appSlice';
import basketReducer from './reducers/basketSlice';
import userReducer from './reducers/userSlice';
import wishlistReducer from './reducers/wishlistSlice';

let appliedMiddleware = applyMiddleware(thunkMiddleware);

// save state to local storage
function saveToLocalStorage(state) {
  try {
    const serializedState = JSON.stringify(state);
    localStorage.setItem('state', serializedState);
  } catch (e) {
    console.log(e);
  }
}

// load state from local storage
function loadFromLocalStorage() {
  try {
    const serializedState = localStorage.getItem('state');
    if (serializedState === null) return undefined;
    return JSON.parse(serializedState);
  } catch (e) {
    console.log(e);
    return undefined;
  }
}

const preloadedState = loadFromLocalStorage();

const store = configureStore(
  {
    reducer: {
      app: appReducer,
      basket: basketReducer,
      user: userReducer,
      wishlist: wishlistReducer,
    },
    preloadedState: preloadedState,
  },
  compose(appliedMiddleware),
);

store.subscribe(() => {
  const state = store.getState();
  saveToLocalStorage(state);
});

export default store;
