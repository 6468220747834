import { createSlice, current } from '@reduxjs/toolkit';
// import { arrayGroupBy } from '../../utils';

const initialState = {
  data: [],
  total: 0,
};

const basketSlice = createSlice({
  name: 'basket',
  initialState,
  reducers: {
    addItem: (state, { payload }) => {
      let item = state.data?.find((item) => item.id == payload.id);
      if (!item) {
        state.data = [...state.data, payload];
      }
    },

    removeItem: (state, { payload }) => {
      state.data = current(state.data).filter((item) => item.id !== payload.id);
    },

    updateItem: (state, { payload }) => {
      // let item = state.data?.find((item) => item.id == payload.id);
      state.data = state.data?.map((item) => (item.id == payload.id ? { ...item, qty: payload.qty } : item));
    },

    clear: (state) => {
      state.data = [];
    },

    // calculateTotals: (state) => {
    //   let total = 0;

    //   state.data.forEach((item) => {
    //     total += item.amount * item.price;
    //   });

    //   state.total = total;
    // },
  },
});

export const { clear, addItem, removeItem, updateItem } = basketSlice.actions;

export default basketSlice.reducer;
