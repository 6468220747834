import { useState, useEffect } from 'react';
import { useParams, useLocation, useSearchParams, createSearchParams, Link } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { Row, Col, Select, Typography, Radio, Empty, Button, Space, Spin, Drawer, Card, Divider } from 'antd';
import { Filter as FilterIcon } from 'react-feather';

import { Api, Locale } from '../../services';
import { ProductCard, Container } from '../../components';
import { colors, sizes, spaces } from '../../style';
import { isMobile } from '../../utils';

const { Title, Text } = Typography;
const { Option } = Select;

const ProductsPremium = () => {
  const { id } = useParams();
  const [search] = useSearchParams();

  const [loading, setLoading] = useState(true);
  const [hasMore, setHasMore] = useState(true);
  const [filterIsOpen, setFilterIsOpen] = useState(false);

  const [data, setData] = useState([]);
  const [products, setProducts] = useState([]);

  const [params, setParams] = useState();
  const [page, setPage] = useState(1);

  const app = useSelector((state) => state.app);

  const api = new Api();

  useEffect(() => {
    setParams((prevState) => ({ ...prevState, ...Object.fromEntries(search) }));
  }, [search]);

  useEffect(() => {
    setLoading(true);
    setPage(1);

    api
      .getProductsPremium({ ...params, page: page, ...Object.fromEntries(search) })
      .then((response) => {
        setData(response.data);

        const response_products = response.data?.products;

        setProducts(response_products.data);
        setHasMore(response_products.last_page > response_products.current_page);
        setLoading(false);
      })
      .catch((err) => console.log(err));
  }, [params]);

  const loadMore = async () => {
    setLoading(true);

    await api.getProductsPremium({ ...params, page: page }).then((response) => {
      const response_products = response.data?.products;
      const temp_products = products.concat(...response_products.data);

      setProducts(temp_products);
      setLoading(false);

      setHasMore(response_products.last_page > response_products.current_page);
    });
  };

  const ProductFilter = () => (
    <Card bordered={false} bodyStyle={{ padding: isMobile() ? 0 : spaces.lg }}>
      <Space direction={'vertical'} size={spaces.md} style={{ display: 'flex' }}>
        <Space size={spaces.sm}>
          <FilterIcon size={18} style={{ display: 'flex' }} />
          <Title level={5} style={{ marginBottom: 0 }}>
            {Locale['filters']}
          </Title>
        </Space>

        <Divider style={{ margin: 0 }} />

        <Space direction={'vertical'} size={spaces.sm} style={{ display: 'flex' }}>
          {data?.category?.children?.map((item, key) => (
            <Link
              key={key}
              type="secondary"
              to={`/products/?${createSearchParams({
                category: item.id,
              })}`}
            >
              • {item.trans?.title}
            </Link>
          ))}
        </Space>

        <Space direction={'vertical'} size={spaces.sm} style={{ display: 'flex' }}>
          <Text strong>{Locale['Application type']}</Text>
          <Radio.Group
            onChange={(e) => {
              setParams((prevState) => ({ ...prevState, type: e.target?.value }));
            }}
            value={params?.type}
          >
            <Space direction="vertical">
              <Radio value={null}>{Locale['condition']}</Radio>
              {app.data?.enums?.product?.type?.map((item, key) => (
                <Radio key={key} value={item}>
                  {Locale[item]}
                </Radio>
              ))}
            </Space>
          </Radio.Group>
        </Space>
        <Space direction={'vertical'} size={spaces.sm} style={{ display: 'flex' }}>
          <Text strong>{Locale['Application type']}</Text>
          <Radio.Group
            onChange={(e) => {
              setParams((prevState) => ({ ...prevState, condition: e.target?.value }));
            }}
            value={params?.condition}
          >
            <Space direction="vertical">
              <Radio value={null}>{Locale['All']}</Radio>
              {app.data?.enums?.product?.condition?.map((item, key) => (
                <Radio key={key} value={item}>
                  {Locale[item]}
                </Radio>
              ))}
            </Space>
          </Radio.Group>
        </Space>
        <Space direction={'vertical'} size={spaces.sm} style={{ display: 'flex' }}>
          <Text strong>{Locale['Promotion']}</Text>
          <Radio.Group
            onChange={(e) => {
              setParams((prevState) => ({ ...prevState, grade: e.target?.value }));
            }}
            value={params?.grade}
          >
            <Space direction="vertical">
              <Radio value={null}>{Locale['All']}</Radio>
              {app.data?.enums?.grade?.map((item, key) => (
                <Radio key={key} value={item}>
                  {Locale[item]}
                </Radio>
              ))}
            </Space>
          </Radio.Group>
        </Space>
      </Space>
    </Card>
  );

  return (
    <Container xs={22} spaces={spaces.xl} style={{ backgroundColor: colors.light }}>
      <Drawer title="" placement={'left'} closable={true} width={280} onClose={() => setFilterIsOpen(false)} visible={filterIsOpen} key={'left'}>
        <ProductFilter />
      </Drawer>

      <Row gutter={[spaces.lg, spaces.lg]}>
        <Col xs={24} md={24} lg={6} xl={6} xxl={5}>
          {!isMobile() && <ProductFilter />}
        </Col>

        <Col xs={24} md={24} lg={18} xl={18} xxl={19}>
          <Card bordered={false}>
            <Space direction={'vertical'} size={spaces.xl} style={{ display: 'flex' }}>
              <Row justify={'space-between'} align={'middle'} gutter={[0, spaces.md]}>
                <Col xs={24} md={12}>
                  <Title level={4} style={{ marginBottom: 0 }}>
                    {data.category?.trans?.title ? data.category?.trans?.title : Locale['statements']}
                  </Title>
                  <Text type="secondary">{data.category?.trans?.description_min ? data.category?.trans?.description_min : Locale['List of announcements']}</Text>
                </Col>
                {isMobile() && (
                  <Col xs={12}>
                    <Button type={'link'} style={{ padding: 0 }} onClick={() => setFilterIsOpen(true)}>
                      <FilterIcon size={18} style={{ marginRight: spaces.xs }} />
                      {Locale['filters']}
                    </Button>
                  </Col>
                )}
                <Col xs={12} md={12}>
                  <Row justify={'end'}>
                    <Col>
                      <Select
                        defaultValue="new_date"
                        onChange={(e) => {
                          setParams((prevState) => ({ ...prevState, sort: e }));
                        }}
                      >
                        <Option value="new_date">{Locale['the newest']}</Option>
                        <Option value="old_date">{Locale['the oldest']}</Option>
                        <Option value="low_price">{Locale['decreasing in price']}</Option>
                        <Option value="high_price">{Locale['As the price increases']}</Option>
                      </Select>
                    </Col>
                  </Row>
                </Col>
              </Row>
              <Spin spinning={loading}>
                <Row gutter={[spaces.xl, spaces.xl]}>
                  {products?.length !== 0 ? (
                    products?.map((item, key) => (
                      <Col key={key} xs={12} md={12} lg={12} xl={6} xxl={6}>
                        <ProductCard item={item} />
                      </Col>
                    ))
                  ) : (
                    <Col span={24}>
                      <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description={Locale['No data found']} />
                    </Col>
                  )}
                  <Col xs={24}>
                    {hasMore ? (
                      <Row justify={'center'}>
                        <Col span={4}>
                          <Button
                            onClick={() => {
                              setPage((prevState) => prevState + 1);
                              loadMore();
                            }}
                            loading={loading}
                          >
                            {Locale['see more']}
                          </Button>
                        </Col>
                      </Row>
                    ) : (
                      <></>
                    )}
                  </Col>
                </Row>
              </Spin>
            </Space>
          </Card>
        </Col>
      </Row>
    </Container>
  );
};

export default ProductsPremium;
