export const HighlightEnum = Object.freeze({
  Normal: {
    key: 'Normal',
    title: 'Normal',
    color: '',
  },
  Popular: {
    key: 'Popular',
    title: 'Popular',
    color: '',
  },
  Featured: {
    key: 'Featured',
    title: 'Featured',
    color: '',
  },
  New: {
    key: 'New',
    title: 'New',
    color: '',
  },
});
