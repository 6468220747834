import { Zap as ZapIcon, Loader as LoaderIcon } from 'react-feather';
import { colors } from '../style';

export const GradeEnum = Object.freeze({
  // Super_VIP: {
  //   key: 'Super_VIP',
  //   title: 'Super VIP',
  //   color: 'red',
  //   id: 1,
  //   name: 'Super VIP',
  //   img: <ZapIcon size={18} />,
  //   price: '5.00',
  //   price_sale: '0.00',
  //   sort: 0,
  //   type: 'Super_VIP',
  //   status: 'Published',
  // },
  // VIP_Pluse: {
  //   key: 'VIP_Pluse',
  //   title: 'VIP Pluse',
  //   color: 'volcano',
  //   id: 2,
  //   name: 'VIP Pluse',
  //   img: <LoaderIcon size={18} />,
  //   price: '3.00',
  //   price_sale: '0.00',
  //   sort: 0,
  //   type: 'VIP_Pluse',
  //   status: 'Published',
  // },
  // VIP: {
  //   key: 'VIP',
  //   title: 'VIP',
  //   color: 'orange',
  //   id: 3,
  //   name: 'VIP',
  //   img: <LoaderIcon size={18} />,
  //   price: '1.00',
  //   price_sale: '0.00',
  //   sort: 0,
  //   type: 'VIP',
  //   status: 'Published',
  // },
  Popular: {
    key: 'Popular',
    title: 'Popular',
    color: colors.secondary,
    id: 4,
    name: 'Popular',
    img: <ZapIcon size={18} />,
    price: '1.00',
    price_sale: '0.00',
    sort: 0,
    type: 'Popular',
    status: 'Published',
  },
  Standart: {
    key: 'Standart',
    title: 'Standart',
    color: '',
    id: 5,
    name: 'Standart',
    img: <LoaderIcon size={18} />,
    price: '0.00',
    price_sale: '0.00',
    sort: 0,
    type: 'Standart',
    status: 'Published',
  },
});
