import { useState, useEffect } from 'react';
import { useParams, Link, useSearchParams, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { Collapse, Typography, Card, Space, List, Row, Col, Spin, Select, Form, Input, InputNumber, Radio, notification, Button, Divider } from 'antd';
import { Truck as TruckIcon, Zap as ZapIcon, Trash as TrashIcon, Send as SendIcon } from 'react-feather';

import { Api, Locale } from '../../services';
import { Container, Icon } from '../../components';
import { colors, spaces, fonts, borders } from '../../style';
import { ProductTypeEnum } from '../../enums/ProductTypeEnum';
import { sell_currency, CDN_URL, ProductPriceHelper } from '../../helpers';
import { addItem as addBasketItem, removeItem as removeBasketItem, updateItem as updateBasketItem } from '../../redux/reducers/basketSlice';
// import { arrayRange } from '../../utils';
import { setToken, setLoginModal } from '../../redux/reducers/userSlice';

const { Panel } = Collapse;
const { Title, Text } = Typography;
const { TextArea } = Input;

const api = new Api();

const openNotification = (type, title, description) => {
  notification[type]({
    message: title,
    description: description,
  });
};

const Checkout = ({ type }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [form] = Form.useForm();
  const [searchParams] = useSearchParams();

  const { basket } = useSelector((state) => state);

  const [loading, setLoading] = useState(true);
  const [data, setData] = useState([]);
  const [deliveryMethod, setDeliveryMethod] = useState('Normal'); // Fast
  const [formValues, setFormValues] = useState([]);

  const getBasketItems = () => {
    let company_id = searchParams.get('company_id');
    let basket_items = basket?.data?.filter((item) => item.company_id == company_id && item);

    return { basket: { data: basket_items } };
  };

  const updateBasketItemQty = (id, qty) => {
    console.log(qty);
    dispatch(updateBasketItem({ id: id, qty: qty }));
  };

  const fetchOrderData = () => {
    if (type === ProductTypeEnum.Gift.key) {
      api
        .checkoutGifts({ ...formValues, ...Object.fromEntries(searchParams) })
        .then((response) => {
          const response_data = response.data;
          const response_user = response_data.user;

          const form_values = {
            title: response_user.trans?.title,
            customer_comment: '',
            delivery_address: response_data?.delivery?.address ?? response_user.trans?.address,
            delivery_method: response_data?.delivery?.method,
            city: Locale['Tbilisi'],
          };

          form.setFieldsValue(form_values);
          setFormValues(form_values);

          setData(response_data);
          setLoading(false);
        })
        .catch((err) => console.log(err));
    } else {
      const basket_items = getBasketItems();
      api
        .checkoutProducts({ ...formValues, ...basket_items, ...Object.fromEntries(searchParams) })
        .then((response) => {
          const response_data = response.data;
          const response_user = response_data.user;

          const form_values = {
            title: response_data.user?.trans?.title,
            customer_comment: '',
            delivery_address: response_data?.delivery?.address ?? response_user.trans?.address,
            delivery_method: response_data?.delivery?.method,
            // city: Locale['Tbilisi'],
            city: '',
          };

          form.setFieldsValue(form_values);
          setFormValues(form_values);

          setData(response_data);
        })
        .catch((err) => {
          if (err.response.status == 401) {
            navigate('/');
            dispatch(setToken({ token: null }));
            dispatch(setLoginModal({ visible: true }));
          }
        })
        .finally(() => setLoading(false));
    }
  };

  useEffect(() => {
    fetchOrderData();
  }, [deliveryMethod, searchParams, basket]);

  const onValuesChange = (_, values) => {
    setFormValues((prevState) => ({ ...prevState, ...values }));
  };

  const checkoutRedirect = () => {
    if (type === ProductTypeEnum.Gift.key) {
      api
        .checkoutGiftsRedirect({ ...formValues, ...Object.fromEntries(searchParams) })
        .then((response) => {
          window.location.href = response.data?.redirect;
        })
        .catch((err) => openNotification('error', Locale['Payment error'], err?.response?.data?.message));
    } else {
      const basket_items = getBasketItems();
      api
        .checkoutProductsRedirect({ ...formValues, ...basket_items, ...Object.fromEntries(searchParams) })
        .then((response) => {
          window.location.href = response.data?.redirect;
        })
        .catch((err) => openNotification('error', Locale['Payment error'], err?.response?.data?.message));
    }
  };

  return (
    <>
      <Container spaces={spaces.xl} style={{ backgroundColor: colors.light }}>
        <Space direction="vertical" size={spaces.xxl} style={{ display: 'flex' }}>
          <Title level={3} style={{ marginBottom: 0 }}>
            {Locale['Order details']}
          </Title>
          <Row gutter={[spaces.xxl, 0]}>
            <Col xs={24} md={24} lg={16} xl={16} xxl={16}>
              <Form size={'large'} layout={'vertical'} form={form} onValuesChange={onValuesChange}>
                <Space direction={'vertical'} size={spaces.xl}>
                  <Card>
                    <Title level={5}>პირადი მონაცემები</Title>
                    <Row>
                      <Col xs={24} md={24} lg={24} xl={24} xxl={24}>
                        <Form.Item label={Locale['name and surname']} name="title" defaultValue={data.user?.trans?.title} rules={[{ required: false, message: Locale['Please provide a name!'] }]}>
                          <Input size={'large'} showCount maxLength={40} disabled />
                        </Form.Item>
                        {/* <Form.Item label={Locale['City']} name="city" defaultValue={Locale['Tbilisi']} rules={[{ required: true, message: Locale['Please provide a city!'] }]}>
                          <Input size={'large'} showCount maxLength={40} disabled />
                        </Form.Item> */}
                        <Form.Item label={Locale['address']} name="delivery_address" rules={[{ required: true, message: Locale['Please specify the address!'] }]}>
                          <TextArea showCount maxLength={500} />
                        </Form.Item>
                      </Col>
                    </Row>
                  </Card>
                  <Card>
                    <Title level={5}>მიწოდება</Title>
                    <Row>
                      <Col xs={24} md={24} lg={24} xl={24} xxl={24}>
                        <Form.Item label={'აირჩიეთ თქვენთვის სასურველი მეთოდი'} name="delivery_method" rules={[{ required: true, message: Locale['Please provide a delivery method!'] }]}>
                          <Radio.Group defaultValue={'Normal'} onChange={(e) => setDeliveryMethod(e)} buttonStyle={'outline'}>
                            <Radio.Button value="Normal">
                              <Icon>
                                <TruckIcon size={16} /> სტანდარტული მიწოდება
                              </Icon>
                            </Radio.Button>
                            <Radio.Button value="Fast" disabled={type == ProductTypeEnum.Gift.key}>
                              <Icon>
                                <ZapIcon size={16} /> სწრაფი მიწოდება
                              </Icon>
                            </Radio.Button>
                            <Radio.Button value="Village" disabled={type == ProductTypeEnum.Gift.key}>
                              <Icon>
                                <SendIcon size={16} />
                                ქალაქგარეთ მიწოდება
                              </Icon>
                            </Radio.Button>
                          </Radio.Group>
                        </Form.Item>
                        <Text type={'secondary'}>
                          სტანდარტული მიწოდება ხორციელდება 2-3 დღის განმავლობაში. <br /> სწრაფი მიწოდება ხდება იმავე დღეს.
                        </Text>
                      </Col>
                    </Row>
                  </Card>
                  <Card>
                    <Title level={5}>დამატებითი მონაცემები</Title>
                    <Row>
                      <Col xs={24} md={24} lg={24} xl={24} xxl={24}>
                        <Form.Item label={Locale['Comment']} name="customer_comment" rules={[{ required: false, message: Locale['Please provide a comment!'] }]}>
                          <TextArea showCount maxLength={500} style={{ height: 80 }} />
                        </Form.Item>
                      </Col>
                    </Row>
                  </Card>
                  <Card>
                    <Title level={5} style={{ marginBottom: spaces.lg }}>
                      კალათა
                    </Title>
                    {data.products?.map((item, key) => (
                      <div key={key}>
                        <Row gutter={[spaces.md]} justify={'space-between'}>
                          <Col xs={24} md={24} lg={4} xl={4} xxl={4}>
                            <img style={{ objectFit: 'cover', width: '100%', height: 140 }} src={CDN_URL + item?.product?.img} />
                          </Col>
                          <Col xs={24} md={24} lg={18} xl={18} xxl={18}>
                            <Space direction={'vertical'} size={spaces.md}>
                              <Space direction={'vertical'} size={spaces.xs}>
                                <Space direction={'vertical'} size={0}>
                                  <Title level={4} style={{ marginBottom: 0 }}>
                                    {item.description}
                                  </Title>
                                  <Text type={'secondary'} style={{ marginBottom: 0 }}>
                                    {item.product?.attributes?.map((item) => `${item?.trans?.title}: ${item?.pivot?.value}`).join(', ')}
                                  </Text>
                                </Space>
                                <Title level={4} style={{ marginTop: 0 }}>
                                  {item?.price + ' ' + sell_currency(type)}
                                </Title>
                              </Space>
                              <Form.Item name={`qty_${item.product?.id}`}>
                                <InputNumber onChange={(e) => updateBasketItemQty(item?.product?.id, e)} min={1} max={item?.product?.stock} defaultValue={item?.quantity} size={'default'} />
                              </Form.Item>
                            </Space>
                          </Col>
                          <Col>
                            <Button
                              danger
                              size="small"
                              type="link"
                              onClick={() => {
                                dispatch(removeBasketItem({ id: item.product_id }));
                              }}
                            >
                              <TrashIcon size={16} />
                            </Button>
                          </Col>
                        </Row>
                        <Divider />
                      </div>
                    ))}
                  </Card>
                </Space>
              </Form>
            </Col>
            <Col xs={24} md={24} lg={8} xl={8} xxl={8}>
              <div style={{ position: 'sticky', top: 100 }}>
                <Space size={spaces.md} direction={'vertical'} style={{ display: 'flex' }}>
                  <Card bordered={true}>
                    <Title level={4}>{Locale['your order']}</Title>
                    <Divider />
                    {/* {data.products?.map((item, key) => (
                    <Space key={key} style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'flex-start' }}>
                      <Text type={'secondary'}>{item.description}</Text>
                      <Title level={5}>{item?.price + ' ' + sell_currency(type)}</Title>
                    </Space>
                  ))}
                  <Divider /> */}
                    {/* <Space style={{ display: 'flex', justifyContent: 'space-between' }}>
                    <Text type={'secondary'}> ჯამური ღირებულება:</Text>
                    <Title level={5} style={{ marginBottom: 0 }}>{data.amounts?.sub_total}</Title>
                  </Space> */}
                    <Space style={{ display: 'flex', justifyContent: 'space-between' }}>
                      <Text type={'secondary'}>{Locale['discount']}</Text>
                      <Title level={5} style={{ marginBottom: 0, color: colors.danger }}>
                        -{data.amounts?.discount}
                      </Title>
                    </Space>
                    {type != ProductTypeEnum.Gift.key && (
                      <>
                        <Space style={{ display: 'flex', justifyContent: 'space-between' }}>
                          <Text type={'secondary'}>{Locale['Delivery service:']}</Text>
                          <Title level={5} style={{ marginBottom: 0 }}>
                            {data.amounts?.delivery_cost}
                          </Title>
                        </Space>
                        {/* <Space style={{ display: 'flex', justifyContent: 'space-between' }}>
                    <Text type={'secondary'}>{Locale['Service Fee:']}</Text>
                    <Title level={5} style={{ marginBottom: 0 }}>
                      {data.amounts?.service_fee}
                    </Title>
                  </Space> */}
                        <Divider />
                        <Space style={{ display: 'flex', justifyContent: 'space-between' }}>
                          <Title level={5}>
                            <Text type={'secondary'}>{Locale['In total:']}</Text>
                          </Title>
                          <Title level={5}> {data.amounts?.total}</Title>
                        </Space>
                      </>
                    )}
                  </Card>
                  {/* <Text>
                  თქვენი პერსონალური მონაცემები გამოყენებული იქნება თქვენი შეკვეთის დასამუშავებლად, ვებსაიტზე და სხვა მიზნებისთვის, რომლებიც აღწერილია ჩვენს კონფიდენციალურობის პოლიტიკაში.
                </Text> */}
                  <Button onClick={() => checkoutRedirect()} type={'primary'} size={'large'} block>
                    {Locale['purchase']}
                  </Button>
                </Space>
              </div>
            </Col>
          </Row>
        </Space>
      </Container>
    </>
  );
};

export default Checkout;
