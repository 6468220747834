import React, { useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { Row, Col, Space, Avatar, Typography, Divider, Statistic, Modal, Spin, List, Table, Tag, Button, Tooltip, Popconfirm } from 'antd';
import { Eye as EyeIcon } from 'react-feather';

import { Api, Locale } from '../../services';
import { CDN_URL, CURRENCY, sell_currency } from '../../helpers';
import { Container, UserMenu, UserHeader } from '../../components';
import { colors, spaces, sizes, borders, shadows } from '../../style';
import { isMobile } from '../../utils';

const { Title, Text } = Typography;

const UserCoins = () => {
  const navigate = useNavigate();

  const [coins, setCoins] = useState([]);
  const [loading, setLoading] = useState(true);

  const api = new Api();

  useEffect(() => {
    setLoading(true);

    api
      .getUserCoins()
      .then((response) => {
        setCoins(response.data?.user?.coins);
      })
      .catch((err) => console.log(err));

    setLoading(false);
  }, []);

  const columns = [
    Table.EXPAND_COLUMN,
    {
      title: Locale['Company gifts'],
      key: 'action',
      render: (_, item) => (
        <Link to={`/profiles/view/${item?.company?.uuid}?tab=3`}>
          <Space style={{ display: 'flex', alignItems: 'center' }}>
            <Avatar size={sizes.xl} src={CDN_URL + item?.company?.img} />
            <Title level={5}>{item?.company?.trans?.title}</Title>
          </Space>
        </Link>
      ),
    },
    {
      title: Locale['Quantity of goods'],
      dataIndex: 'coins',
      render: (coins) => (
        <Text strong={true}>
          {coins} {sell_currency('coin')}
        </Text>
      ),
    },
  ];

  return (
    <>
      <Container xs={22} spaces={spaces.xl} style={{ backgroundColor: colors.light }}>
        <Row gutter={[spaces.lg, spaces.lg]}>
          <Col xs={24} md={24} lg={7} xl={7} xxl={6}>
            <Space direction="vertical" size={spaces.lg} style={{ display: 'flex', backgroundColor: colors.white, boxShadow: shadows.sm, padding: spaces.lg, borderRadius: borders.sm }}>
              {isMobile() ? <UserHeader /> : <UserMenu />}
            </Space>
          </Col>
          <Col xs={24} md={24} lg={17} xl={17} xxl={18}>
            <Spin tip={Locale['loading']} spinning={loading}>
              <Table rowKey={(record) => record.id} columns={columns} dataSource={coins} />
            </Spin>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default UserCoins;
