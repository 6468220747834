import React, { useState, useEffect, useCallback } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';

import { Space, Avatar, Typography } from 'antd';
import { ArrowUpRight as ArrowUpRightIcon } from 'react-feather';

import { Locale } from '../../services';
import { CDN_URL } from '../../helpers';
import { isMobile } from '../../utils';
import { colors, spaces, sizes } from '../../style';

const { Title, Text } = Typography;

const UserHeader = () => {
  const user = useSelector((state) => state.user);
  const navigate = useNavigate();
  return (
    <>
      <Space onClick={() => navigate(`/profiles/view/${user?.info?.uuid}`)}>
        <Avatar size={sizes.xl} src={CDN_URL + user?.info?.img} />
        <div>
          <Title level={5} style={{ marginBottom: 0 }}>
            {user?.info?.trans?.full_name}
          </Title>
          <span style={{ display: 'flex', alignItems: 'center', color: colors.gray }}>
            {Locale['public profile']}
            <ArrowUpRightIcon size={18} style={{ marginLeft: spaces.xs }} />
          </span>
        </div>
      </Space>
    </>
  );
};

export default UserHeader;
