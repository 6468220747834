import { useState, useEffect } from 'react';
import { Typography, Row, Col, Space } from 'antd';
import { ShoppingCart as ShoppingCartIcon, PhoneCall as PhoneCallIcon } from 'react-feather';

import { Api, Locale } from '../services';
import { Container } from '../components';
import { colors, spaces, fonts, borders } from '../style';

const { Title, Text } = Typography;

const Contact = () => {
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState([]);

  const api = new Api();

  useEffect(() => {
    api
      .getSettings()
      .then((response) => {
        setData(response.data);
        setLoading(false);
      })
      .catch((err) => console.log(err));
  }, []);

  return (
    <>
      <Container xs={22} md={22} lg={12} xl={12} xxl={12} spaces={spaces.xl}>
        <Space direction={'vertical'} size={spaces.xxl} style={{ display: 'flex' }}>
          <Title level={2}>{Locale['Contact']}</Title>

          <Row gutter={[spaces.md, spaces.xl]}>
            <Col xs={24} md={24} lg={18} xl={8} xxl={8}>
              <Space direction={'vertical'} size={0}>
                <ShoppingCartIcon style={{ width: 40, height: 40 }} />
                <Title level={4}>{Locale['Visit our office']}</Title>
                <Title level={5} style={{ marginBottom: 0 }} type={'secondary'}>
                  {data.settings?.trans?.address}
                </Title>
              </Space>
            </Col>
            <Col xs={24} md={24} lg={18} xl={8} xxl={8}>
              <Space direction={'vertical'} size={0}>
                <PhoneCallIcon style={{ width: 40, height: 40 }} />
                <Title level={4}>{Locale['Contact us']}</Title>
                <Title level={5} style={{ marginBottom: 0 }} type={'secondary'}>
                  {Locale['Tel:']} {data.settings?.phone}
                </Title>
                <Title level={5} style={{ marginBottom: 0 }} type={'secondary'}>
                  {Locale['e-mail mail:']} {data.settings?.email}
                </Title>
              </Space>
            </Col>
          </Row>
          
        </Space>
      </Container>
    </>
  );
};

export default Contact;
