import React from 'react';
import { Link, useNavigate, useLocation } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';

import { Space, Button, Badge, Typography } from 'antd';
import { Home as HomeIcon, Menu as MenuIcon, Search as SearchIcon, Heart as HeartIcon, ShoppingBag as ShoppingBagIcon, User as UserIcon, Facebook, Youtube, Instagram } from 'react-feather';

import { setLoginModal } from '../../redux/reducers/userSlice';
import { setSearch, setDrawer } from '../../redux/reducers/appSlice';

// import { bar_menu } from '../../helpers';
import { colors, sizes, borders, spaces } from '../../style';

const { Text, Title, Paragraph } = Typography;

const MenuBar = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { token } = useSelector((state) => state.user);
  const { basket, wishlist } = useSelector((state) => state);

  return (
    <>
      <div style={{ position: 'fixed', bottom: 8, zIndex: 999, width: '100%', display: 'flex', justifyContent: 'center' }}>
        <Space className="menu-bar" size={spaces.lg}>
          <Button className="menu-bar-item" onClick={() => navigate('/')} type="link" shape="circle" size={'small'} style={{ flexDirection: 'column' }}>
            <Space direction="vertical" size={sizes.xs}>
              <Badge count={0} size={'small'}>
                <HomeIcon size={18} />
              </Badge>
              <Text style={{ fontSize: 10 }}>მთავარი</Text>
            </Space>
          </Button>
          <Button className="menu-bar-item" onClick={() => dispatch(setSearch({ visible: true }))} type="link" shape="circle" size={'small'} style={{ flexDirection: 'column' }}>
            <Space direction="vertical" size={6}>
              <Badge count={0} size={'small'}>
                <SearchIcon size={18} />
              </Badge>
              <Text style={{ fontSize: 10 }}>ძიება</Text>
            </Space>
          </Button>
          <Button className="menu-bar-item" onClick={() => navigate('/basket')} type="link" shape="circle" size={'large'} style={{ flexDirection: 'column' }}>
            <Space direction="vertical" size={6}>
              <Badge count={basket?.data?.reduce((acc, item) => acc + item.qty, 0)} size={'small'}>
                <ShoppingBagIcon size={18} />
              </Badge>
              <Text style={{ fontSize: 10 }}>კალათა</Text>
            </Space>
          </Button>
          <Button className="menu-bar-item" onClick={() => (token ? navigate('/user/wishlist') : dispatch(setLoginModal({ visible: true })))} type="link" shape="circle" size={'large'} style={{ flexDirection: 'column' }}>
            <Space direction="vertical" size={6}>
              <Badge count={wishlist?.wishlist_items?.length} size={'small'}>
                <HeartIcon size={18} />
              </Badge>
              <Text style={{ fontSize: 10 }}>სურვილები</Text>
            </Space>
          </Button>
          <Button className="menu-bar-item" onClick={() => (token ? dispatch(setDrawer({ visible: true })) : dispatch(setLoginModal({ visible: true })))} type="link" shape="circle" size={'large'} style={{ flexDirection: 'column' }}>
            <Space direction="vertical" size={6}>
              <Badge count={0} size={'small'}>
                <UserIcon size={18} />
              </Badge>
              <Text style={{ fontSize: 10 }}>პროფილი</Text>
            </Space>
          </Button>
        </Space>
      </div>
    </>
  );
};

export default MenuBar;
