import { media } from '../style';

export const getWidth = () => {
  return window.innerWidth;
};

export const getHeight = (key) => {
  return window.innerHeight;
};

export const isMobile = () => getWidth() <= media.sm;
export const isTablet = () => getWidth() <= media.md;
