export const share = async (title, url, file = '') => {
  // const file = new File(data, file, { type: "image/png" });

  try {
    await navigator.share({
      title: title,
      url: url.replace(/^http:/, 'https:'),
      // files: [file]
    });
  } catch (err) {
    console.error('Share failed:', err.message);
  }
};
