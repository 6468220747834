import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { Row, Col, Space, Spin, Statistic, Card, Typography, Divider } from 'antd';
import { Layers as LayersIcon, ShoppingBag as ShoppingBagIcon, Users as UsersIcon, UserCheck as UserCheckIcon, Heart as HeartIcon, Gift as GiftIcon } from 'react-feather';

import { useUser } from '../../hooks';
import { Container, UserMenu, UserHeader } from '../../components';
import { colors, spaces, borders, shadows } from '../../style';
import { isMobile } from '../../utils';
import { Locale } from '../../services';

const { Title } = Typography;

const User = () => {
  const { userData, userLoading } = useUser();

  return (
    <>
      <Container xs={22} spaces={spaces.xl} style={{ backgroundColor: colors.light }}>
        <Row gutter={[spaces.lg, spaces.lg]}>
          <Col xs={24} md={24} lg={7} xl={7} xxl={6}>
            <Space direction="vertical" size={spaces.lg} style={{ display: 'flex', backgroundColor: colors.white, boxShadow: shadows.sm, padding: spaces.lg, borderRadius: borders.sm }}>
              {isMobile() ? <UserHeader /> : <UserMenu />}
            </Space>
          </Col>
          <Col xs={24} md={24} lg={17} xl={17} xxl={18}>
            <Spin spinning={userLoading}>
              <Row gutter={[spaces.md, spaces.md]}>
                <Col xs={24} lg={24}>
                  <Card
                    bordered={false}
                    style={{
                      background: '#ead9fc',
                    }}
                  >
                    <Title level={5} style={{ color: colors.primary, marginBottom: 0 }}>
                      {Locale['Hello']} <br /> {userData?.user?.trans?.full_name}
                    </Title>
                  </Card>
                </Col>
                <Col xs={12} lg={6}>
                  <Link to={'/user/products'}>
                    <Statistic title={Locale['active announcements']} value={userData?.user?.products_count} style={{ backgroundColor: '#fff', padding: spaces.md, borderRadius: borders.sm }} suffix={<LayersIcon size={24} color={'#5f5ff6'} />} />
                  </Link>
                </Col>
                <Col xs={12} lg={6}>
                  <Link to={'/user/wishlist'}>
                    <Statistic title={Locale['wishes']} value={userData?.user?.wishlists_count} style={{ backgroundColor: '#fff', padding: spaces.md, borderRadius: borders.sm }} suffix={<HeartIcon size={24} color={'#f44336'} />} />
                  </Link>
                </Col>
                <Col xs={12} lg={6}>
                  <Link to={'/user/orders'}>
                    <Statistic
                      title={Locale['Online orders']}
                      value={userData?.user?.orders_count}
                      style={{ backgroundColor: '#fff', padding: spaces.md, borderRadius: borders.sm }}
                      valueStyle={{
                        color: '#001a34',
                      }}
                      suffix={<ShoppingBagIcon size={24} color={'#009d73'} />}
                    />
                  </Link>
                </Col>
                <Col xs={12} lg={6}>
                  <Link to={`/profiles/view/${userData?.user?.uuid}?tab=3`}>
                    <Statistic title={Locale['gifts']} value={userData?.user?.gifts_count} style={{ backgroundColor: '#fff', padding: spaces.md, borderRadius: borders.sm }} suffix={<GiftIcon size={24} color={'#ff0057'} />} />
                  </Link>
                </Col>
              </Row>
              <Divider
                style={{
                  marginTop: spaces.md,
                  marginBottom: spaces.md,
                }}
              />
              <Row gutter={[spaces.md, spaces.md]}>
                <Col xs={12} lg={6}>
                  <Link to={`/profiles/view/${userData?.user?.uuid}?tab=4`}>
                    <Statistic title={Locale['Subscribers']} value={userData?.user?.followers_count} style={{ backgroundColor: '#fff', padding: spaces.md, borderRadius: borders.sm }} suffix={<UsersIcon size={24} color={'#2c8cf4'} />} />
                  </Link>
                </Col>
                <Col xs={12} lg={6}>
                  <Link to={`/profiles/view/${userData?.user?.uuid}?tab=5`}>
                    <Statistic title={Locale['My subscriptions']} value={userData?.user?.following_count} style={{ backgroundColor: '#fff', padding: spaces.md, borderRadius: borders.sm }} suffix={<UserCheckIcon size={24} color={'#03a9f4'} />} />
                  </Link>
                </Col>
              </Row>
            </Spin>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default User;
