import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { Row, Col, Space, Avatar, Typography, Empty, Spin } from 'antd';
import { Navigation as NavigationIcon, PhoneCall as PhoneCallIcon, Home as HomeIcon } from 'react-feather';

import { Api, Locale } from '../../services';
import { Container, ProductCard, UserMenu, UserHeader } from '../../components';
import { colors, spaces, borders, shadows } from '../../style';
import { isMobile } from '../../utils';
import { setToken, setLoginModal } from '../../redux/reducers/userSlice';

const { Title, Text } = Typography;

const Wishlist = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [data, setData] = useState([]);
  const [wishlists, setWishlists] = useState([]);
  const [loading, setLoading] = useState(true);
  const [startAddProductModal, setStartAddProductModal] = useState(false);

  const api = new Api();

  useEffect(() => {
    setLoading(true);

    api
      .getUserWishlists()
      .then((response) => {
        setData(response.data);
        setWishlists(response.data?.wishlists?.data);
        setLoading(false);
      })
      .catch((err) => {
        if (err.response.status == 401) {
          navigate('/');
          dispatch(setToken({ token: null }));
          dispatch(setLoginModal({ visible: true }));
        }
      });
  }, []);

  return (
    <>
      <Container xs={22} spaces={spaces.xl} style={{ backgroundColor: colors.light }}>
        <Row gutter={[spaces.lg, spaces.lg]}>
          <Col xs={24} md={24} lg={7} xl={7} xxl={6}>
            <Space direction="vertical" size={spaces.lg} style={{ display: 'flex', backgroundColor: colors.white, boxShadow: shadows.sm, padding: spaces.lg, borderRadius: borders.sm }}>
              {isMobile() ? <UserHeader /> : <UserMenu />}
            </Space>
          </Col>
          <Col xs={24} md={24} lg={17} xl={17} xxl={18}>
            <Space size={spaces.lg} direction={'vertical'} style={{ display: 'flex', backgroundColor: colors.white, borderRadius: borders.sm, paddingLeft: spaces.xl, paddingRight: spaces.xl, paddingTop: spaces.lg, paddingBottom: spaces.lg }}>
              <Row justify="space-between">
                <Col>
                  <Title level={5} style={{ marginBottom: 0 }}>
                    {Locale['my wish list']}
                  </Title>
                  <Text type="secondary"></Text>
                </Col>
                {/* <Col>
                  <Select defaultValue="1" onChange={sortBy}>
                    <Option value="1">დალაგება თარიღით</Option>
                    <Option value="2">ფასის კლებადობით</Option>
                    <Option value="3">ფასის მატებით</Option>
                  </Select>
                </Col> */}
              </Row>
              <Spin tip={Locale['loading']} spinning={loading}>
                <Row gutter={[32, 32]}>
                  {wishlists?.length !== 0 ? (
                    wishlists?.map((item, key) => (
                      <Col key={key} xs={12} md={12} lg={12} xl={6} xxl={6}>
                        <ProductCard item={item} />
                      </Col>
                    ))
                  ) : (
                    <Col span={24}>
                      <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description={Locale['No data found']} />
                    </Col>
                  )}
                </Row>
              </Spin>
            </Space>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default Wishlist;
