import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';

import { Api } from '../../services';

const initialState = {
  data: [],
  utils: {
    search: {
      modal: {
        visible: false,
      },
    },
    drawer: {
      visible: false,
    },
    catalog: {
      visible: false,
    },
  },
  loading: true,
  version: '1.2.4',
};

export const getAppData = createAsyncThunk('app/getAppData', async (thunkAPI) => {
  try {
    const api = new Api();
    const response_data = await api.getApp();

    return response_data;
  } catch (error) {
    return thunkAPI.rejectWithValue('something went wrong');
  }
});

const appSlice = createSlice({
  name: 'app',
  initialState,
  reducers: {
    refresh: (state) => {
      state = [];
    },
    setSearch: (state, { payload }) => {
      state.utils.search.modal.visible = payload?.visible;
    },
    setDrawer: (state, { payload }) => {
      state.utils.drawer.visible = payload.visible;
    },
    setCatalog: (state, { payload }) => {
      state.utils.catalog.visible = payload.visible;
    },
  },
  extraReducers: {
    [getAppData.pending]: (state) => {
      state.loading = true;
    },
    [getAppData.fulfilled]: (state, action) => {
      state.loading = false;
      state.data = action.payload?.data;
    },
    [getAppData.rejected]: (state, action) => {
      state.loading = false;
    },
  },
});

export const { refresh, setSearch, setDrawer, setCatalog } = appSlice.actions;

export default appSlice.reducer;
