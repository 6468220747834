import { useState, useEffect } from 'react';
import { useParams, useNavigate, Link } from 'react-router-dom';
import { Collapse, Typography, Skeleton, List } from 'antd';

import { Api, Locale } from '../services';
import { Container } from '../components';
import { colors, spaces, fonts, borders } from '../style';

const { Panel } = Collapse;
const { Title, Text } = Typography;

const Page = () => {
  const { id } = useParams();

  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    Api.build()
      .getPage(id)
      .then((response) => {
        setData(response.data);
        setLoading(false);
      })
      .catch((err) => console.log(err));
  }, []);

  return (
    <>
      <Container xs={22} md={22} lg={12} xl={12} xxl={12} spaces={spaces.xl}>
        <Title level={2}>{data.page?.trans?.title}</Title>
        <Text>
          <div dangerouslySetInnerHTML={{ __html: data.page?.trans?.description }} />
        </Text>
      </Container>
    </>
  );
};

export default Page;
