import React from 'react';

import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation, Pagination } from 'swiper';
import { spaces, sizes, colors } from '../../style';

const SliderDefault = (props) => {
  const SwiperBreakpoints = () => {
    return {
      0: {
        slidesPerView: props.breakpoints?.xs,
        spaceBetween: spaces.sm,
      },
      768: {
        slidesPerView: props.breakpoints?.sm,
        spaceBetween: spaces.sm,
      },
      992: {
        slidesPerView: props.breakpoints?.md,
        spaceBetween: spaces.md,
      },
      1200: {
        slidesPerView: props.breakpoints?.lg,
        spaceBetween: spaces.lg,
      },
      1600: {
        slidesPerView: props.breakpoints?.xl,
        spaceBetween: spaces.lg,
      },
    };
  };

  return (
    <>
      <div className={props.className}>
        <Swiper
          style={{ margin: -20, padding: 20 }}
          modules={[Navigation, Pagination]}
          navigation={props.navigation}
          // spaceBetween={30}
          slidesPerView={props.slidesPerView}
          breakpoints={{ ...SwiperBreakpoints() }}
        >
          {props.children?.map((child, key) => (
            <SwiperSlide key={key}>{child}</SwiperSlide>
          ))}
        </Swiper>
      </div>
    </>
  );
};

SliderDefault.defaultProps = {
  navigation: true,
  slidesPerView: 'default',
  breakpoints: {
    xs: 2,
    sm: 3,
    md: 4,
    lg: 5,
    xl: 5,
  },
};

export default SliderDefault;
