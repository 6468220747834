import { CDN_URL } from '../../helpers';
import { borders } from '../../style';
import { isMobile } from '../../utils';

const Banner = ({ banner_data, url, height }) => {
  return (
    <>
      <a href={banner_data.trans?.url ?? '/'}>
        <img width="100%" height={'100%'} src={CDN_URL + (isMobile() ? banner_data.img_cover : banner_data.img)} style={{ objectFit: 'cover', borderRadius: borders.sm }} />
      </a>
    </>
  );
};

export default Banner;
