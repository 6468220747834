import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation, Pagination } from 'swiper';

import { isMobile } from '../../utils';
import { CDN_URL } from '../../helpers';
import { media, spaces } from '../../style';
import { getWidth } from '../../utils';

const Carousel = (props) => {
  const SwiperBreakpoints = () => {
    return {
      0: {
        slidesPerView: props.breakpoints?.xs,
        spaceBetween: spaces.sm,
      },
      768: {
        slidesPerView: props.breakpoints?.sm,
        spaceBetween: spaces.sm,
      },
      992: {
        slidesPerView: props.breakpoints?.md,
        spaceBetween: spaces.md,
      },
      1200: {
        slidesPerView: props.breakpoints?.lg,
        spaceBetween: spaces.lg,
      },
      1600: {
        slidesPerView: props.breakpoints?.xl,
        spaceBetween: spaces.lg,
      },
    };
  };
  return (
    <>
      <Swiper
        style={{ height: getWidth() > media.sm ? 'auto' : 260, maxHeight: 400 }}
        modules={[Navigation, Pagination]}
        spaceBetween={spaces.md}
        navigation
        loop={true}
        pagination={{ clickable: true }}
        slidesPerView={props.slidesPerView}
        breakpoints={{ ...SwiperBreakpoints() }}
      >
        {props?.data?.map((item, key) => (
          <SwiperSlide key={key}>
            <img
              height={'auto'}
              width={'100%'}
              style={{
                objectFit: 'cover',
                // borderBottomLeftRadius: 32,
                // borderBottomRightRadius: 32
              }}
              src={CDN_URL + (isMobile() ? item.img_cover : item.img)}
            />
          </SwiperSlide>
        ))}
      </Swiper>
    </>
  );
};

Carousel.defaultProps = {
  data: [],
  navigation: true,
  slidesPerView: 1,
  breakpoints: {
    xs: 1,
    sm: 1,
    md: 1,
    lg: 1,
    xl: 1,
  },
};

export default Carousel;
