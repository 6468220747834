export { default as Home } from './Home';
export { default as About } from './About';
export { default as Faq } from './Faq';
export { default as Contact } from './Contact';
export { default as Page } from './Page';

export { default as Products } from './product/Products';
export { default as ProductsPremium } from './product/ProductsPremium';
export { default as ProductView } from './product/ProductView';

export { default as Profiles } from './profile/Profiles';
export { default as ProfileView } from './profile/ProfileView';
// export { default as ShopProducts } from './profile/ShopProducts';
// export { default as ShopReview } from './profile/ShopReview';
// export { default as ShopDetails } from './profile/ShopDetails';

export { default as Basket } from './Basket';

export { default as User } from './user/User';
export { default as UserEdit } from './user/UserEdit';
export { default as UserProducts } from './user/UserProducts';
export { default as UserProductEdit } from './user/UserProductEdit';
export { default as UserOrders } from './user/UserOrders';
export { default as UserSales } from './user/UserSales';
export { default as UserCoins } from './user/UserCoins';
export { default as Wishlist } from './user/Wishlist';

export { default as Checkout } from './checkout/Checkout';
export { default as CheckoutResult } from './checkout/CheckoutResult';

export { default as NotFoundPage } from './errors/NotFoundPage';
