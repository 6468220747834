import { Typography } from 'antd';
import { spaces } from '../../style';

const { Text } = Typography;

export const Icon = (props) => {
  return (
    <span
      style={{
        display: 'flex',
        alignItems: 'center',
        gap: spaces.xs + 1,
    
      }}
    >
      {props.children}
    </span>
  );
};

export default Icon;
