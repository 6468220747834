export const BankEnum = Object.freeze({
  TBC: {
    key: 'tbc',
    title: 'თი ბი სი ბანკი',
  },
  BOG: {
    key: 'bog',
    title: 'საქართველოს ბანკი',
  },
});
