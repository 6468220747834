export const colors = {
  primary: '#9D4AF0',
  secondary: '#ff6d03',
  success: '#32b643',
  danger: '#ff4d4f',
  warning: '#faad14',
  info: '#faad14',
  heading: '#001a34',
  text: '#001a34',
  white: '#ffffff',
  light: '#f8f9fb',
  gray: '#9399ae',
  dark: '#0a0a0a',
};

export const shadows = {
  sm: '0px 0px 35px 0px rgb(154 161 171 / 15%)',
};

export const media = {
  sm: 576,
  md: 768,
  lg: 992,
  xl: 1200,
  xxl: 1400,
};

export const sizes = {
  xs: 8,
  sm: 16,
  md: 34,
  lg: 32,
  xl: 40,
  xxl: 48,
  xxxl: 80,
};

export const spaces = {
  xs: 4,
  sm: 8,
  md: 16,
  lg: 24,
  xl: 32,
  xxl: 40,
  xxxl: 64,
};

export const borders = {
  xxxs: 1,
  xxs: 2,
  xs: 4,
  sm: 8,
  md: 16,
  lg: 24,
  xl: 32,
  xxl: 40,
};

export const fonts = {
  xxs: 12,
  xs: 14,
  sm: 16,
  md: 18,
  lg: 24,
  xl: 32,
  xxl: 36,
};

export const empty_img = require('../assets/images/simple.png');
