import React, { useState, useEffect } from 'react';
import { Menu, Dropdown, Space, Typography } from 'antd';
import { ChevronDown as ChevronDownIcon } from 'react-feather';

import { SetLocale, GetLocale } from '../../services';
import { colors, spaces, fonts, media } from '../../style';

import united_kingdom_flag from '../../assets/images/icons/united-kingdom.png';
import georgia_flag from '../../assets/images/icons/georgia.png';
import russia_flag from '../../assets/images/icons/russia.png';
import { isMobile } from '../../utils';

const { Text } = Typography;

const LanguageDropdown = () => {
  const [dropdownVisible, setDropdownVisible] = useState(false);

  const flag = (lang_key) => {
    switch (lang_key) {
      case 'ka':
        return <img width="20" height="20" src={georgia_flag} />;
      case 'en':
        return <img width="20" height="20" src={united_kingdom_flag} />;
      // case 'ru':
      //   return <img width="20" height="20" src={russia_flag} />;
      default:
        <img width="20" height="20" src={georgia_flag} />;
        break;
    }
  };

  const langs = [
    {
      key: 'ka',
      lang: 'GEO',
      icon: flag('ka'),
    },
    {
      key: 'en',
      lang: 'ENG',
      icon: flag('en'),
    },
    // {
    //   key: 'ru',
    //   lang: 'RUS',
    //   icon: flag('ru'),
    // },
  ];

  const locale = () => {
    return langs.find((e) => e.key == GetLocale());
  };

  return (
    <Dropdown
      placement="bottomRight"
      overlay={
        <Menu>
          {langs.map((item, key) => (
            <Menu.Item key={key} onClick={() => SetLocale(item.key)}>
              <Space>
                {item.icon}
                <Text>{item.lang}</Text>
              </Space>
            </Menu.Item>
          ))}
        </Menu>
      }
      onOpenChange={() => setDropdownVisible(!dropdownVisible)}
      open={dropdownVisible}
      arrow={{ pointAtCenter: true }}
    >
      <a onClick={(e) => e.preventDefault()}>
        <Text type="secondary">
          <Space
            spaces={0}
            style={{
              background: isMobile() ? '#9d4af0' : '#00000021',
              padding: '8px 10px',
              borderRadius: 30,
              lineHeight: '17px',
            }}
          >
            {locale()?.icon}
            <Text style={{ color: colors.white }}>{locale()?.lang}</Text>
            {/* <ChevronDownIcon color={colors.white} size={12} /> */}
          </Space>
        </Text>
      </a>
    </Dropdown>
  );
};

export default LanguageDropdown;
