import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  info: null,
  token: null,
  loading: false,
  error: null,
  success: false,
  login_modal: {
    visible: false,
  },
};

const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    logout: (state) => {
      state.info = [];
    },

    setInfo: (state, { payload }) => {
      state.info = payload.info;
    },

    setImg: (state, { payload }) => {
      state.info.img = payload.img;
    },

    setToken: (state, { payload }) => {
      state.token = payload.token;
    },

    setLoginModal: (state, { payload }) => {
      state.login_modal.visible = payload.visible;
    },
  },
  extraReducers: {},
});

export const { logout, setInfo, setToken, setImg, setLoginModal } = userSlice.actions;

export default userSlice.reducer;
