import { useState, useEffect, useRef } from 'react';
import { useParams, useLocation, useSearchParams, createSearchParams, Link } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { Row, Col, Select, Space, Typography, Empty, Drawer, Button, Divider, Radio, Switch, Form, Card } from 'antd';
import { Filter as FilterIcon } from 'react-feather';

import { Api, Locale } from '../../services';
import { colors, spaces, borders, sizes } from '../../style';
import { Container, ProfileCardCircle } from '../../components';
import { isMobile } from '../../utils';

const { Option } = Select;
const { Title, Text } = Typography;

const api = new Api();

const Profiles = () => {
  const pageRef = useRef();
  const [search] = useSearchParams();

  const [loading, setLoading] = useState(true);
  const [hasMore, setHasMore] = useState(true);
  const [filterIsOpen, setFilterIsOpen] = useState(false);

  const [data, setData] = useState([]);
  const [profiles, setProfiles] = useState([]);

  const [page, setPage] = useState(1);
  const [params, setParams] = useState([]);

  const app = useSelector((state) => state.app);

  useEffect(() => {
    setLoading(true);
    pageRef.current = 1;

    api
      .getProfiles({ ...params, page: page, ...Object.fromEntries(search) })
      .then((response) => {
        setData(response.data);
        const response_profiles = response.data?.users;
        setProfiles(response_profiles.data);
        setHasMore(response_profiles.last_page > response_profiles.current_page);
        setLoading(false);
      })
      .catch((err) => console.log(err));
  }, [params, search]);

  const loadMore = async (pg) => {
    setLoading(true);
    await api.getProfiles({ ...params, page: pg }).then((response) => {
      const response_profiles = response.data?.users;
      const temp_profiles = profiles.concat(...response_profiles.data);
      setProfiles(temp_profiles);
      setLoading(false);
      setHasMore(response_profiles.last_page > response_profiles.current_page);
    });
  };

  const Filter = () => (
    <Card bordered={false} bodyStyle={{ padding: isMobile() ? 0 : spaces.lg }}>
      <Space direction={'vertical'} size={spaces.md} style={{ display: 'flex' }}>
        <Space size={spaces.sm}>
          <FilterIcon size={18} style={{ display: 'flex' }} />
          <Title level={5} style={{ marginBottom: 0 }}>
            {Locale['filters']}
          </Title>
        </Space>

        <Divider style={{ margin: 0 }} />

        <Space direction={'vertical'} size={spaces.sm}>
          <Text strong>{Locale['Shop Status']}</Text>
          <Form.Item label={Locale['verified']}>
            <Switch
              onChange={(val) => {
                setParams((prevState) => ({ ...prevState, verified: val ? 'True' : 'False' }));
              }}
              checked={params?.verified == 'True'}
              loading={loading}
            />
          </Form.Item>
        </Space>
      </Space>
    </Card>
  );

  return (
    <>
      <Container xs={24} spaces={spaces.xl} style={{ backgroundColor: colors.light }}>
        <Drawer title="" placement={'left'} closable={true} width={280} onClose={() => setFilterIsOpen(false)} open={filterIsOpen} key={'left'}>
          <Filter />
        </Drawer>

        <Row gutter={[spaces.lg, spaces.lg]}>
          <Col xs={24} md={24} lg={6} xl={6} xxl={5}>
            {!isMobile() && <Filter />}
          </Col>
          <Col xs={24} md={24} lg={18} xl={18} xxl={19}>
            <Card bordered={false}>
              <Space direction="vertical" size={32} style={{ display: 'flex' }}>
                <Row justify="space-between">
                  <Col>
                    <Title level={4} style={{ marginBottom: 0 }}>
                      {Locale['shops']}
                    </Title>
                    <Text type="secondary"></Text>
                  </Col>
                  <Col>
                    <Select
                      defaultValue="new_date"
                      onChange={(e) => {
                        setParams((prevState) => ({ ...prevState, sort: e }));
                      }}
                    >
                      <Option value="new_date">{Locale['the newest']}</Option>
                      <Option value="old_date">{Locale['the oldest']}</Option>
                    </Select>
                  </Col>
                </Row>

                <Row gutter={[32, 32]}>
                  {profiles?.length !== 0 ? (
                    profiles?.map((item, key) => (
                      <>
                        <Col key={key} xs={12} md={12} lg={12} xl={6} xxl={6}>
                          <ProfileCardCircle item={item} />
                        </Col>
                      </>
                    ))
                  ) : (
                    <Col span={24}>
                      <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description={Locale['No data found']} />
                    </Col>
                  )}
                </Row>

                {hasMore ? (
                  <Row justify={'center'}>
                    <Col span={4}>
                      <Button
                        onClick={() => {
                          pageRef.current = pageRef.current + 1;
                          loadMore(pageRef.current);
                        }}
                        loading={loading}
                      >
                        {Locale['No data found']}
                      </Button>
                    </Col>
                  </Row>
                ) : (
                  <></>
                )}
              </Space>
            </Card>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default Profiles;
