import { useState, useEffect, useRef } from 'react';
import { useParams, useLocation, useSearchParams, createSearchParams, Link } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { Row, Col, Select, Typography, Radio, Empty, Button, Space, Spin, Drawer, Card, Divider } from 'antd';
import { Filter as FilterIcon } from 'react-feather';

import { Api, Locale } from '../../services';
import { ProductCard, Container } from '../../components';
import { borders, colors, sizes, spaces } from '../../style';
import { isMobile } from '../../utils';

const { Title, Text } = Typography;
const { Option } = Select;

const api = new Api();

const Products = () => {
  const pageRef = useRef();
  const [search] = useSearchParams();
  const app = useSelector((state) => state.app);

  const [loading, setLoading] = useState(true);
  const [hasMore, setHasMore] = useState(true);
  const [filterIsOpen, setFilterIsOpen] = useState(false);

  const [products, setProducts] = useState([]);

  const [loadingCategory, setLoadingCategory] = useState(true);
  const [category, setCategory] = useState([]);

  const [data, setData] = useState([]);
  const [params, setParams] = useState();

  useEffect(() => {
    setLoading(true);
    pageRef.current = 1;

    api
      .getProducts({ page: 1, ...Object.fromEntries(search), ...params })
      .then((response) => {
        setData(response.data);
        const response_products = response.data?.products;

        setProducts(response_products.data);
        setHasMore(response_products.last_page > response_products.current_page);
        setLoading(false);
      })
      .catch((err) => console.log(err));
  }, [params, search]);

  useEffect(() => {
    const category_id = Object.fromEntries(search)?.category;
    if (category_id) {
      setLoadingCategory(true);
      api
        .getCategory(category_id)
        .then(({ data }) => {
          setCategory(data?.category);
        })
        .catch((err) => console.log(err))
        .finally(() => setLoadingCategory(false));
    }
  }, [search]);

  const loadMore = async (pg) => {
    setLoading(true);

    await api.getProducts({ ...params, page: pg }).then((response) => {
      const response_products = response.data?.products;
      const temp_products = products.concat(...response_products.data);

      setProducts(temp_products);
      setLoading(false);
      setHasMore(response_products.last_page > response_products.current_page);
    });
  };

  const ProductCategoryFilter = () => (
    <Space size={spaces.xs} wrap>
      {category?.children?.length > 0 && (
        <>
          <Button
            className="system-font"
            // type={params?.category == item.id ? 'primary' : 'default'}
            // ghost={params?.category == category.id}
            shape="round"
            // size="middle"
            onClick={() => setParams((prevState) => ({ ...prevState, category: category.id }))}
          >
            {Locale['All']}
          </Button>
          {category?.children?.map((item, key) => (
            <Button
              key={key}
              className="system-font"
              type={params?.category == item.id ? 'primary' : 'default'}
              ghost={params?.category == item.id}
              shape="round"
              // size="large"
              onClick={() => setParams((prevState) => ({ ...prevState, category: item.id }))}
            >
              {item.trans?.title}
            </Button>
          ))}
        </>
      )}
    </Space>
  )

  const ProductFilter = () => (
    <Card bordered={false} bodyStyle={{ padding: isMobile() ? 0 : spaces.lg }}>
      <Space direction={'vertical'} size={spaces.md} style={{ display: 'flex' }}>
        <Space size={spaces.sm}>
          <FilterIcon size={18} style={{ display: 'flex' }} />
          <Title level={4} style={{ marginBottom: 0 }}>
            {Locale['filters']}
          </Title>
        </Space>

        <Divider style={{ margin: 0 }} />
        <ProductCategoryFilter />
        {/* <Space direction={'vertical'} size={spaces.sm} style={{ display: 'flex' }}>
          {data?.category?.children?.map((item, key) => (
            <Link
              key={key}
              type="secondary"
              to={`/products/?${createSearchParams({
                category: item.id,
              })}`}
            >
              • {item.trans?.title}
            </Link>
          ))}
        </Space> */}
        <Space direction={'vertical'} size={spaces.sm} style={{ display: 'flex' }}>
          <Text strong>{Locale['Application type']}</Text>
          <Radio.Group
            onChange={(e) => {
              setParams((prevState) => ({ ...prevState, type: e.target?.value }));
            }}
            value={params?.type}
          >
            <Space direction="vertical">
              <Radio value={null}>{Locale['condition']}</Radio>
              {app.data?.enums?.product?.type?.map((item, key) => (
                <Radio key={key} value={item}>
                  {Locale[item]}
                </Radio>
              ))}
            </Space>
          </Radio.Group>
        </Space>
        <Space direction={'vertical'} size={spaces.sm} style={{ display: 'flex' }}>
          <Text strong>{Locale['Application type']}</Text>
          <Radio.Group
            onChange={(e) => {
              setParams((prevState) => ({ ...prevState, condition: e.target?.value }));
            }}
            value={params?.condition}
          >
            <Space direction="vertical">
              <Radio value={null}>{Locale['All']}</Radio>
              {app.data?.enums?.product?.condition?.map((item, key) => (
                <Radio key={key} value={item}>
                  {Locale[item]}
                </Radio>
              ))}
            </Space>
          </Radio.Group>
        </Space>
        <Space direction={'vertical'} size={spaces.sm} style={{ display: 'flex' }}>
          <Text strong>{Locale['Promotion']}</Text>
          <Radio.Group
            onChange={(e) => {
              setParams((prevState) => ({ ...prevState, grade: e.target?.value }));
            }}
            value={params?.grade}
          >
            <Space direction="vertical">
              <Radio value={null}>{Locale['All']}</Radio>
              {app.data?.enums?.grade?.map((item, key) => (
                <Radio key={key} value={item}>
                  {Locale[item]}
                </Radio>
              ))}
            </Space>
          </Radio.Group>
        </Space>
      </Space>
    </Card>
  );



  return (
    <Container xs={24} spaces={spaces.xl} style={{ backgroundColor: colors.light }}>
      <Drawer title="" placement={'left'} closable={true} width={280} onClose={() => setFilterIsOpen(false)} open={filterIsOpen} key={'left'}>
        <ProductFilter />
      </Drawer>

      <Row gutter={[spaces.lg, spaces.lg]}>
        {/* <Col xs={24} md={24} lg={6} xl={6} xxl={5}>
          {!isMobile() && <ProductFilter />}
        </Col> */}

        <Col xs={24} md={24} lg={24} xl={24} xxl={24}>
          <Card bordered={false}>
            <Space direction={'vertical'} size={spaces.xl} style={{ display: 'flex' }}>
              <Row justify={'space-between'} align={'middle'} gutter={[0, spaces.md]}>
                <Col xs={24} md={12}>
                  <Title className="system-font" level={isMobile() ? 4 : 3} style={{ marginBottom: spaces.xs }}>
                    {data.category?.trans?.title ? data.category?.trans?.title : Locale['statements']}
                  </Title>
                  <Text className="system-font" type="secondary">
                    {data.category?.trans?.description_min ? data.category?.trans?.description_min : Locale['List of announcements']}
                  </Text>
                </Col>
                {/* {!isMobile() && (
                  <Col xs={12}>
                    <Button type={'link'} style={{ padding: 0 }} onClick={() => setFilterIsOpen(true)}>
                      <FilterIcon size={18} style={{ marginRight: spaces.xs }} />
                      {Locale['filters']}
                    </Button>
                  </Col>
                )} */}
                <Col xs={24} md={24}>
                  <Row gutter={[spaces.sm, spaces.sm]} justify="space-between" align={'top'} wrap>
                    <Col xl={21}>
                      <Space size={spaces.xs} split={<Divider type="vertical" />}>
                        <Button className="system-font" type="default" shape="round" size={isMobile() ? 'small' : 'large'} style={{ fontWeight: 'bold' }} onClick={() => setFilterIsOpen(true)}>
                          <FilterIcon size={isMobile() ? 14 : 18} />
                          {Locale['filters']}
                        </Button>
                        {!isMobile() && (<ProductCategoryFilter />)}
                      </Space>
                    </Col>
                    <Col xl={3}>
                      <Select
                        style={{ display: 'flex' }}
                        // size="large"
                        bordered={false}
                        className="system-font"
                        defaultValue="new_date"
                        onChange={(e) => {
                          setParams((prevState) => ({ ...prevState, sort: e }));
                        }}
                      >
                        <Option value="new_date">{Locale['the newest']}</Option>
                        <Option value="old_date">{Locale['the oldest']}</Option>
                        <Option value="low_price">{Locale['decreasing in price']}</Option>
                        <Option value="high_price">{Locale['As the price increases']}</Option>
                      </Select>
                    </Col>
                  </Row>
                </Col>
              </Row>
              <Spin spinning={loading}>
                <Row gutter={[spaces.xl, spaces.xl]}>
                  {products?.length !== 0 ? (
                    products?.map((item, key) => (
                      <Col key={key} xs={12} md={12} lg={6} xl={6} xxl={6}>
                        <ProductCard item={item} height={isMobile() ? 160 : 420} />
                      </Col>
                    ))
                  ) : (
                    <Col span={24}>
                      <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description={Locale['No data found']} />
                    </Col>
                  )}
                  <Col xs={24}>
                    {hasMore ? (
                      <Row justify={'center'}>
                        <Col span={4}>
                          <Button
                            onClick={() => {
                              pageRef.current = pageRef.current + 1;
                              loadMore(pageRef.current);
                            }}
                            loading={loading}
                          >
                            {Locale['see more']}
                          </Button>
                        </Col>
                      </Row>
                    ) : (
                      <></>
                    )}
                  </Col>
                </Row>
              </Spin>
            </Space>
          </Card>
        </Col>
      </Row>
    </Container>
  );
};

export default Products;
