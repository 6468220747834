import React, { useState, useEffect } from 'react';
import { Link, useNavigate, useSearchParams } from 'react-router-dom';
import { Row, Col, Space, Avatar, Typography, Divider, Statistic, Modal, Spin, Table, Tag, Button, Tooltip, Popconfirm, message } from 'antd';
import { Navigation as NavigationIcon, PhoneCall as PhoneCallIcon, Home as HomeIcon, Star as StarIcon, RefreshCcw as RefreshCcwIcon, Trash2 as Trash2Icon, Edit2 as Edit2Icon } from 'react-feather';

import { Api, Locale } from '../../services';
import { CDN_URL, CURRENCY, sell_currency, ProductPriceHelper } from '../../helpers';

import { GradeEnum } from '../../enums/GradeEnum';
import { Container, UserMenu, UserHeader } from '../../components';
import { colors, spaces, borders, shadows, empty_img } from '../../style';
import { isMobile } from '../../utils';

const { Title, Text } = Typography;

const api = new Api();

const paymentSuccessModal = () => {
  const modal = Modal.success({
    title: Locale['The payment was made successfully'],
    content: Locale['Thank you for using our service.'],
  });
};

const UserProducts = () => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();

  const [data, setData] = useState([]);
  const [products, setProducts] = useState([]);
  const [loading, setLoading] = useState(true);
  const [startAddProductModal, setStartAddProductModal] = useState(false);

  useEffect(() => {
    setLoading(true);

    api
      .getUserProducts()
      .then((response) => {
        setData(response.data);
        setProducts(response.data?.products?.data);
      })
      .catch((err) => console.log(err))
      .finally(() => setLoading(false));
  }, []);

  useEffect(() => {
    let payment_status = Object.fromEntries(searchParams).status;
    payment_status == 'success' && paymentSuccessModal();
  }, []);

  const deleteProduct = (id) => {
    setLoading(true);
    api
      .deleteUserProduct(id)
      .then((response) => {
        setProducts(response.data?.products?.data);
      })
      .catch((err) => console.log(err))
      .finally(() => setLoading(false));
  };

  const columns = [
    Table.EXPAND_COLUMN,
    // {
    //   title: '#',
    //   dataIndex: 'id',
    //   key: 'id',
    // },
    {
      title: Locale['Photo'],
      dataIndex: 'img',
      key: 'img',
      width: 80,
      render: (img, item) => (
        <Link to={`/products/details/${item.id}`}>
          <img style={{ objectFit: 'cover' }} height={70} width={70} src={img ? CDN_URL + img : empty_img} />
        </Link>
      ),
    },
    {
      title: Locale['title'],
      dataIndex: 'trans',
      key: 'trans.title',
      render: (trans) => (
        <Link to={`/products/details/${trans.product_id}`}>
          <Text strong={true}>{trans.title}</Text>
        </Link>
      ),
    },
    {
      title: Locale['the price'],
      dataIndex: 'price',
      width: 160,
      // defaultSortOrder: 'descend',
      // sorter: (a, b) => a.price - b.price,
      render: (_, item) => (
        // <Text style={{ color: colors.secondary }} strong={true}>
        //   {item.price} {sell_currency(item.type)}
        // </Text>
        <ProductPriceHelper values={item} />
        // <Space>
        //   <Text delete={item.price_sale != '0.00' ? true : false}>{item.price + ' ' + sell_currency(item.type)}</Text>
        //   {item.price_sale != '0.00' && <Text type="danger">{item.price_sale + ' ' + CURRENCY}</Text>}
        // </Space>
      ),
    },
    // {
    //   title: Locale['discounted price'],
    //   dataIndex: 'price_sale',
    //   width: 100,
    //   // defaultSortOrder: 'descend',
    //   sorter: (a, b) => a.price_sale - b.price_sale,
    //   render: (_, item) => (
    //     <Text style={{ color: colors.secondary }} strong={true}>
    //       {item.price_sale} {sell_currency(item.type)}
    //     </Text>
    //   ),
    // },
    // {
    //   title: 'გამოქვეყნდა',
    //   dataIndex: 'date',
    //   render: (date) => <Text style={{ color: colors.secondary }} strong={true}>{date}</Text>,
    // },
    {
      title: Locale['sell fast'],
      dataIndex: 'promote',
      key: 'promote',
      width: 150,
      render: (_, item) => {
        return item?.grade == GradeEnum.Standart.key ? (
          <>
            <Button
              type={'dashed'}
              shape={'round'}
              block
              onClick={() => {
                // return navigate(`/user/products/edit/${item.id}?step=3`, { replace: true });
                return navigate(`/user/products/edit/${item.id}?#Step4`, { replace: true });
              }}
            >
              {Locale['Select a status']}
            </Button>
            {/* <Space>
              {Object.values(GradeEnum).map((grade, key) => (
                grade.key !== GradeEnum.Standart.key ? (
                  <Tooltip key={key} title={grade.title}>
                    <Button type={'dashed'} shape={'round'} icon={(grade.img)}> - {grade.price} {CURRENCY}</Button>
                  </Tooltip>
                ) : (<></>)
              ))}
            </Space> */}
          </>
        ) : (
          <Button type={'default'} shape={'round'} block onClick={() => message.info(Locale['The product status is already on']` ${Locale[item.grade]}`)}>
            {Locale[item.grade]}
          </Button>
        );
      },
    },
    {
      title: Locale['condition'],
      key: 'condition',
      dataIndex: 'condition',
      render: (_, item) => (
        <Space size={spaces.xs} split={<Divider type="vertical" />} style={{ display: 'flex' }}>
          {item.condition == 'New' ? (
            <Tag color="success" style={{ display: 'flex', alignItems: 'center' }}>
              <StarIcon size={12} style={{ marginRight: spaces.xs }} />
              {Locale['new']}
            </Tag>
          ) : (
            <Tag color="default" style={{ display: 'flex', alignItems: 'center' }}>
              <RefreshCcwIcon size={12} style={{ marginRight: spaces.xs }} />
              {Locale['secondary']}
            </Tag>
          )}
        </Space>
      ),
    },
    {
      // title: 'მოქმედება',
      key: 'action',
      render: (_, item) => (
        <Space size="middle">
          <Button
            onClick={() => {
              return navigate(`/user/products/edit/${item.id}`, { replace: true });
            }}
            type={'primary'}
            shape={'circle'}
            icon={<Edit2Icon size={18} />}
          ></Button>
          <Popconfirm title={Locale['Are you sure you want to delete?']} okText={Locale['Delete']} showCancel={false} cancelText={Locale['Cancellation']} onConfirm={() => deleteProduct(item.id)}>
            <Button size="small" type={'link'} shape={'circle'} icon={<Trash2Icon size={18} />} danger></Button>
          </Popconfirm>
        </Space>
      ),
    },
  ];

  return (
    <>
      <Container xs={22} spaces={spaces.xl} style={{ backgroundColor: colors.light }}>
        <Row gutter={[spaces.lg, spaces.lg]}>
          <Col xs={24} md={24} lg={7} xl={7} xxl={6}>
            <Space direction="vertical" size={spaces.lg} style={{ display: 'flex', backgroundColor: colors.white, boxShadow: shadows.sm, padding: spaces.lg, borderRadius: borders.sm }}>
              {isMobile() ? <UserHeader /> : <UserMenu />}
            </Space>
          </Col>
          <Col xs={24} md={24} lg={17} xl={17} xxl={18}>
            <Spin tip={Locale['loading']} spinning={loading}>
              <Table
                columns={columns}
                dataSource={products}
                expandable={{
                  expandedRowRender: (record) => {
                    return (
                      <Row align={'middle'} gutter={[spaces.lg, spaces.lg]}>
                        <Col>
                          <Text>{Locale['Product Status:']} </Text>
                          <Text strong>{Locale[record?.grade_status?.grade]}</Text>
                        </Col>
                        <Col>
                          <Text>{Locale['Number of days left:']} </Text>
                          <Text strong>{record?.grade_status?.days}</Text>
                        </Col>
                        <Col>
                          <Text>{Locale['Price:']} </Text>
                          <Text style={{ color: colors.secondary }} strong>
                            {record?.grade_status?.price} {sell_currency(record?.type)}
                          </Text>
                        </Col>
                      </Row>
                    );
                  },
                  rowExpandable: (record) => record?.grade_status != null,
                  expandRowByClick: true,
                }}
              />
            </Spin>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default UserProducts;
