import { useState, useEffect } from 'react';
import { useParams, useSearchParams, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { Helmet } from 'react-helmet-async';
import { Row, Col, Space, List, Typography, Spin, Tabs, Empty, Divider, Avatar, Input, Comment, Rate, Form, Button, message } from 'antd';
import {
  Navigation as NavigationIcon,
  PhoneCall as PhoneCallIcon,
  Home as HomeIcon,
  MessageSquare as MessageSquareIcon,
  Layers as LayersIcon,
  Box as BoxIcon,
  Gift as GiftIcon,
  Users as UsersIcon,
  UserCheck as UserCheckIcon,
  Info as InfoIcon,
  Star as StarIcon,
} from 'react-feather';

import { Api, Locale } from '../../services';
import { Slider, Banner, ProfileHeader, Container, ProductCard, ProfileCardCircle } from '../../components';
import { CDN_URL } from '../../helpers';
import { colors, spaces, borders, sizes } from '../../style';
import { isMobile } from '../../utils';

const { Title, Text } = Typography;
const { TextArea } = Input;
const { TabPane } = Tabs;

const api = new Api();

const ProfileView = () => {
  const navigate = useNavigate();

  const { uuid } = useParams();
  const [search] = useSearchParams();
  const [reviewForm] = Form.useForm();

  const [data, setData] = useState([]);
  const [user, setUser] = useState([]);
  const [products, setProducts] = useState([]);
  const [loading, setLoading] = useState(true);
  const [currentTab, setCurrentTab] = useState('2');

  const { app } = useSelector((state) => state);

  useEffect(() => {
    setLoading(true);
    api
      .getProfileView(uuid)
      .then((response) => {
        setData(response.data);
        setUser(response.data?.user);

        setLoading(false);
      })
      .catch((err) => console.log(err));
  }, [uuid]);

  useEffect(() => {
    let new_tab = Object.fromEntries(search)?.tab;
    new_tab == undefined ? setCurrentTab('2') : setCurrentTab(new_tab);
  }, [search, navigate]);

  return (
    <>
      <Helmet>
        <title>{`${data.user?.trans?.public?.name}`} | Happy Shop</title>
        <meta name="description" content={data.user?.trans?.description} />
        <meta property="og:title" content={data.user?.trans?.public?.name} />
        <meta property="og:description" content={data.user?.trans?.description} />
        <meta property="og:image" content={CDN_URL + data.user?.img} />
      </Helmet>

      <Spin tip={Locale['loading']} spinning={loading}>
        <Space direction="vertical" size={sizes.xxl} style={{ display: 'flex' }}>
          <ProfileHeader data={user} />
          <Container xs={22}>
            <Tabs activeKey={currentTab} size={'small'} tabPosition={'top'} type={'line'} style={{ overflow: 'hidden' }} centered={isMobile()} onTabClick={(key) => setCurrentTab(key)}>
              <TabPane
                tab={
                  <>
                    {isMobile() && <LayersIcon size={18} />}
                    {(!isMobile() || currentTab == 1) && Locale['shop']}
                  </>
                }
                key="1"
              >
                <Row justify={'center'} gutter={[0, sizes.xxl]}>
                  <Col span={24}>
                    <Space direction="vertical" size={spaces.sm} style={{ display: 'flex' }}>
                      <Title level={4}>{Locale['Popular products']}</Title>
                      <Slider data={data.products?.popular} type="product" />
                    </Space>
                  </Col>
                  <Col span={24}>
                    <Space direction="vertical" size={spaces.sm} style={{ display: 'flex' }}>
                      <Title level={4}>{Locale['New products"']}</Title>
                      <Slider data={data.products?.new} type="product" />
                    </Space>
                  </Col>
                  <Col span={24}>{!isMobile() && app.data?.banners?.Profile_First?.length && <Banner banner_data={app.data?.banners?.Profile_First[Math.floor(Math.random() * app.data?.banners?.Profile_First.length)]} />}</Col>
                  <Col span={24}>
                    <Space direction="vertical" size={spaces.sm} style={{ display: 'flex' }}>
                      <Title level={4}>{Locale['Popular categories"']}</Title>
                      <Slider data={data.categories} type={'product-group'} />
                    </Space>
                  </Col>
                </Row>
              </TabPane>
              <TabPane tab={isMobile() ? <BoxIcon size={18} /> : Locale['products']} key="2">
                <Space direction="vertical" size={spaces.lg}>
                  <Title level={3}>{Locale['products']}</Title>
                  <Row gutter={[32, 32]}>
                    {data?.products?.latest?.total !== 0 ? (
                      data?.products?.latest?.data?.map((item, key) => (
                        <Col key={key} xs={12} md={12} lg={12} xl={6} xxl={6}>
                          <ProductCard item={item} />
                        </Col>
                      ))
                    ) : (
                      <Col span={24}>
                        <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description={Locale['No data found']} />
                      </Col>
                    )}
                  </Row>
                </Space>
              </TabPane>
              <TabPane tab={isMobile() ? <GiftIcon size={18} /> : Locale['gifts']} key="3">
                <Space direction="vertical" size={spaces.sm} style={{ display: 'flex' }}>
                  <Title level={4}>{Locale['gifts']}</Title>
                  <Row gutter={[32, 32]}>
                    {data?.products?.gift?.total !== 0 ? (
                      data?.products?.gift?.data?.map((item, key) => (
                        <Col key={key} xs={12} md={12} lg={12} xl={4} xxl={4}>
                          <ProductCard item={item} />
                        </Col>
                      ))
                    ) : (
                      <Col span={24}>
                        <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description={Locale['No data found']} />
                      </Col>
                    )}
                  </Row>
                </Space>
              </TabPane>
              <TabPane tab={isMobile() ? <UsersIcon size={18} /> : Locale['Subscribers']} key="4">
                <Space direction="vertical" size={8} style={{ display: 'flex' }}>
                  <Title level={4}>{Locale['Subscribers']}</Title>
                  <Row gutter={[spaces.xl, spaces.xl]}>
                    {user?.followers?.map((item, key) => (
                      <Col key={key} xs={12} md={12} lg={12} xl={4} xxl={4}>
                        <ProfileCardCircle item={item} />
                      </Col>
                    ))}
                  </Row>
                </Space>
              </TabPane>
              <TabPane tab={isMobile() ? <UserCheckIcon size={18} /> : Locale['My subscriptions']} key="5">
                <Space direction="vertical" size={8} style={{ display: 'flex' }}>
                  <Title level={4}>{Locale['My subscriptions']}</Title>
                  <Row gutter={[spaces.xl, spaces.xl]}>
                    {user?.following?.map((item, key) => (
                      <Col key={key} xs={12} md={12} lg={12} xl={4} xxl={4}>
                        <ProfileCardCircle item={item} />
                      </Col>
                    ))}
                  </Row>
                </Space>
              </TabPane>
              <TabPane tab={isMobile() ? <InfoIcon size={18} /> : Locale['about']} key="6">
                <Row gutter={[40, 0]}>
                  <Col span={isMobile() ? 24 : 12}>
                    <Space direction="vertical" size={spaces.sm} style={{ display: 'flex' }}>
                      <Title level={4}>{Locale['Contact us']}</Title>
                      <Space direction="vertical" size={spaces.sm}>
                        <div style={{ display: 'flex', alignItems: 'center' }}>
                          <PhoneCallIcon size={sizes.sm} style={{ marginRight: spaces.sm }} />
                          <Text style={{ fontWeight: 500 }}>+995 {user?.public?.phone}</Text>
                        </div>
                        <div style={{ display: 'flex', alignItems: 'center' }}>
                          <MessageSquareIcon size={sizes.sm} style={{ marginRight: spaces.sm }} />
                          <Text style={{ fontWeight: 500 }}>{user?.public?.email}</Text>
                        </div>
                        <div style={{ display: 'flex', alignItems: 'center' }}>
                          <NavigationIcon size={sizes.sm} style={{ marginRight: spaces.sm }} />
                          <Text style={{ fontWeight: 500 }}>{user?.trans?.address}</Text>
                        </div>
                      </Space>
                      <Divider />
                    </Space>
                  </Col>
                  <Col span={isMobile() ? 24 : 12}>
                    <Title level={4}>{Locale['about us']}</Title>
                    <Text>{user?.trans?.description}</Text>
                  </Col>
                </Row>
              </TabPane>
              {/* <TabPane tab={(isMobile() ? <StarIcon size={18} /> : 'მიმოხილვები')} key="7">
                <Space direction="vertical" size={8} style={{ display: 'flex' }}>
                  <Title level={4}>მომხმარებელთა მიმოხილვები</Title>
                  <Row>
                    <Col xs={24} md={24} lg={17} xl={17} xxl={18}>
                      <Form form={reviewForm}>
                        <Comment
                          avatar={<Avatar src="https://joeschmoe.io/api/v1/random" alt="Han Solo" />}
                          content={
                            <>
                              <Form.Item name={'rate'} rules={[{ required: true }]}>
                                <Rate defaultValue={5} />
                              </Form.Item>
                              <Form.Item name={'review'} rules={[{ required: true }]}>
                                <TextArea rows={4} />
                              </Form.Item>
                              <Button loading={false} htmlType="submit" type="primary">
                                დაამატეთ შეფასება
                              </Button>
                            </>
                          }
                        />
                      </Form>
                      <List
                        header={<Text strong={true}>{user?.reviews?.length} შეფასება</Text>}
                        itemLayout="horizontal"
                        dataSource={user?.reviews}
                        renderItem={(item) => (
                          <li>
                            <Comment
                              author={<Title level={5} style={{ marginBottom: 0 }}>{item.name}</Title>}
                              avatar={<Avatar style={{ backgroundColor: colors.primary, }}>{item.name.charAt(0)}</Avatar>}
                              content={<Text type="secondary">{item.review}</Text>}
                              datetime={<Rate disabled defaultValue={item.rate} />}
                            />
                          </li>
                        )}
                      />

                    </Col>

                    <Col xs={24} md={24} lg={7} xl={7} xxl={6}>
                    </Col>
                  </Row>
                </Space>
              </TabPane> */}
            </Tabs>
          </Container>
        </Space>
      </Spin>
    </>
  );
};

export default ProfileView;
