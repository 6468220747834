import { Link } from 'react-router-dom';

import { Button, List, Space } from 'antd';
import { Search } from 'react-feather';

const RecentSearch = ({ data }) => {
  return (
    <List
      itemLayout="vertical"
      grid={{ gutter: 24 }}
      dataSource={data}
      renderItem={(item) => (
        <List.Item style={{ marginBottom: 0 }}>
          <Link to={`/products?title=${item.title}`}>
            <Button shape="round" size="large">
              <Space size={5}>
                <Search size={18} style={{ display: 'flex' }} />
                {item.title}
              </Space>
            </Button>
          </Link>
        </List.Item>
      )}
    />
  );
};

export default RecentSearch;
